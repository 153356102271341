/* eslint-disable no-extra-boolean-cast */
import { useEffect, useState, useMemo } from "react";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useParams, useLocation } from "react-router-dom";

import {
  AddAttachments,
  Loader,
  PersonalInfo,
  TableBidsComparison,
  TableBidsComparisonRanks,
  TableBidSubmitUpdate,
  WithdrawModal,
  AddBidInfo,
  AttachmentsList
} from "../../components";

import {
  PRICING_VISIBILITY,
  TENDER_STATUSES,
  PRICING_TYPE,
  APP_ROUTE
} from "../../utils/constants";
import { toFloat, uploadAttachments } from "../../utils/helpersFunctions";

import {
  useGetTenderSubmitBidsMutation,
  useUpdateTenderBidMutation,
  useDeleteTenderBidMutation,
  usePostTenderBidsMutation,
  usePostTenderBidsUnregisteredMutation,
  useAttachmentsPostContractorMutation,
  useAttachmentsDeleteContractorMutation,
  useGetTenderDetailBidsMutation,
  useGetTaxesMutation,
  useGetTenderByUnregisteredContractorMutation
} from "../../redux/services/tenderApi";
import {
  getTotalSummeryByBid,
  deleteTax,
  rateChange,
  cancelBid
} from "./helpers/bidSubmit";

export default function BidSubmit() {
  const { user, isLogin } = useSelector((state) => state.userStore);
  const location = useLocation();

  const { id } = useParams();
  const [getTenderSubmitBids] = useGetTenderSubmitBidsMutation();
  const [getTenderByGuid] = useGetTenderByUnregisteredContractorMutation();
  const [postTenderBids, { isLoading: isPostBidLoading }] =
    usePostTenderBidsMutation();
  const [
    postTenderBidsUnregistered,
    { isLoading: isPostBidUnregisteredLoading }
  ] = usePostTenderBidsUnregisteredMutation();
  const [updateTenderBid, { isLoading: isUpdateBidLoading }] =
    useUpdateTenderBidMutation();
  const [deleteTenderBid, { isLoading: isDeleteBidLoading }] =
    useDeleteTenderBidMutation();
  const [uploadAttachment, { isLoading: isUploadAttachmentLoading }] =
    useAttachmentsPostContractorMutation();
  const [deleteAttachment, { isLoading: isDeleteAttachmentLoading }] =
    useAttachmentsDeleteContractorMutation();
  const [getTenderDetailBids, { isLoading: isBidDetailsLoading }] =
    useGetTenderDetailBidsMutation();
  const [getTaxes, { isLoading: isTaxesLoading }] = useGetTaxesMutation();
  const [loader, setLoader] = useState(false);
  const isLoading =
    loader ||
    isPostBidLoading ||
    isPostBidUnregisteredLoading ||
    isUpdateBidLoading ||
    isDeleteBidLoading ||
    isUploadAttachmentLoading ||
    isDeleteAttachmentLoading ||
    isTaxesLoading;

  const [fullPageLoader, setFullPageLoader] = useState(false);
  const [tenderDetails, setTenderDetails] = useState({});
  const [bids, setBids] = useState([]);
  const [bid, setBid] = useState(null);
  const [isEditMode, setEditMode] = useState();
  const [isUpdateBidMode, setUpdateBidMode] = useState();
  const [attachmentsList, setAttachmentsList] = useState([]);
  const [attachmentsDeleteList, setAttachmentsDeleteList] = useState([]);
  const [errors, setErrors] = useState([]);
  const [taxes, setTaxes] = useState([]);
  const [unregisteredUserData, setUnregisteredUserData] = useState({
    name: "",
    email: "",
    phone_number: ""
  });
  const [isUnregisteredUserValid, setUnregisteredUserValid] = useState(false);
  const [isNewUser, setNewUser] = useState(false);
  const [unauthorizedBid, setUnauthorizedBid] = useState(null);
  const [tenderError, setTenderError] = useState("");

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const totalSummeryByBid = useMemo(
    () => getTotalSummeryByBid({ bids, tenderDetails }),
    [bids, tenderDetails]
  );

  const totalPrice = useMemo(
    () =>
      toFloat(
        totalSummeryByBid
          .map((t) => t.totalPrice)
          .reduce((prevValue, curValue) => prevValue + curValue, 0)
      ),
    [totalSummeryByBid]
  );

  const totalTaxCount = useMemo(
    () =>
      toFloat(
        totalSummeryByBid
          .map((t) => t.totalTaxCount)
          .reduce((prevValue, curValue) => prevValue + curValue, 0)
      ),
    [totalSummeryByBid]
  );

  const totalTaxPrice = useMemo(
    () =>
      toFloat(
        totalSummeryByBid
          .map((t) => t.totalTax)
          .reduce((prevValue, curValue) => prevValue + curValue, 0)
      ),
    [totalSummeryByBid]
  );

  const grandTotal = useMemo(
    () => toFloat(totalPrice + totalTaxPrice),
    [totalPrice, totalTaxPrice]
  );

  const getDetails = async () => {
    const { data, error } =
      isLogin && location.pathname.includes(APP_ROUTE.CONTRACTOR_BID_SUBMIT)
        ? await getTenderSubmitBids(id)
        : await getTenderByGuid(id);

    if (error) {
      return error;
    }

    const details = data.data;
    const myBid = isLogin
      ? details.bids.find((b) => b.is_bid_owner === true)
      : details.bids.find((b) => b.id === unauthorizedBid);

    if (myBid) {
      bidExist(details, myBid);
    } else {
      const tehBids = addTehBids(details.items);
      setBids(tehBids);
      setEditMode(true);
      setUpdateBidMode(false);
      setAttachmentsList([]);
    }
    setTenderDetails(details);
  };

  const bidExist = (details, myBid) => {
    let newItemsBids = [];
    if (details.items.length !== myBid.items_bids.length) {
      newItemsBids = addTehBids(
        details.items.filter(
          ({ id }) => !myBid.items_bids.some(({ item }) => id === item)
        )
      );
    }
    setBids([...myBid.items_bids, ...newItemsBids]);
    setBid(myBid);
    myBid?.attachments.length > 0 && setAttachmentsList(myBid?.attachments);
    setEditMode(false);
    setUpdateBidMode(true);
  };

  const addTehBids = (list) =>
    list.map((item) => ({
      id: Date.now(),
      item: item.id,
      details: "",
      price: null,
      taxes: [],
      total_price: null
    }));

  useEffect(() => {
    async function fetchData() {
      setFullPageLoader(true);
      const response = await Promise.all([getDetails(), getTaxes()]);

      if (response[0]?.data?.errors.length > 0) {
        return setTenderError(response[0].data.errors[0].detail);
      }

      setTaxes(response[1].data.data);
      setFullPageLoader(false);
    }
    fetchData();
  }, []);

  const tehItems = tenderDetails?.items?.map((item) => {
    const findBids = bids?.find((bid) => bid.item === item.id);
    return findBids ?? item;
  });

  const noNullBids = tehItems?.map((bid) => {
    if (bid.price === null) {
      return { ...bid, price: 0, total_price: 0 };
    }
    return bid;
  });

  const createBidHandler = async () => {
    const tehData = {
      items_bids: noNullBids.map((b) => ({
        ...b,
        taxes: b.taxes.map((t) => ({ ...t, tax: t.tax.id }))
      })),
      grand_total: grandTotal,
      owner: user.id,
      tender: tenderDetails.id
    };

    const { data, error } = isLogin
      ? await postTenderBids({
          tenderId: tenderDetails.id,
          body: tehData
        })
      : await postTenderBidsUnregistered({
          tenderId: tenderDetails.id,
          body: {
            ...tehData,
            full_name: unregisteredUserData.name,
            email: unregisteredUserData.email,
            phone_number: unregisteredUserData.phone_number
          }
        });

    if (error && error.data.errors.length !== 0) {
      setErrors(error.data.errors[0]);
      return;
    }
    if (data.status === "success") {
      await uploadAttachments(uploadAttachment, attachmentsList, data.data.id);
      setLoader(true);
      if (!isLogin) {
        const bidDetails = data.data;
        setNewUser(bidDetails.is_new_user);
        setUnauthorizedBid(bidDetails.id);
      }
      await getDetails();

      setLoader(false);
    }
    setErrors([]);
  };

  const updateBidHandler = async () => {
    const tehData = {
      items_bids: noNullBids.map((b) => ({
        ...b,
        taxes: b.taxes.map((t) => ({ ...t, tax: t.tax.id }))
      })),
      grand_total: grandTotal
    };
    const { data, error } = await updateTenderBid({
      tenderId: tenderDetails.id,
      id: bid.id,
      body: tehData
    });

    if (error && error.data.errors.length !== 0) {
      setErrors(error.data.errors[0]);
      return;
    }

    if (data.status === "success") {
      const deletePromiseList = [];
      const newAttachmentsList = attachmentsList.filter((item) => !item.id);
      await uploadAttachments(uploadAttachment, newAttachmentsList, bid.id);

      if (attachmentsDeleteList) {
        for (let i = 0; i < attachmentsDeleteList.length; i++) {
          deletePromiseList.push(
            deleteAttachment({
              bidId: bid.id,
              attachmentId: attachmentsDeleteList[i].id
            })
          );
        }
        await Promise.all(deletePromiseList);
        setAttachmentsDeleteList([]);
      }

      setLoader(true);
      await getDetails();
      setLoader(false);
    }
    setErrors([]);
  };

  const cancelBidHandler = () =>
    cancelBid({
      tenderDetails,
      addTehBids,
      setBids,
      setEditMode
    });

  const updateFormHandler = (bid) => {
    const index = bids.findIndex((b) => b.item === bid.item);
    if (index >= 0) {
      const tehBids = [...bids];
      tehBids[index] = bid;
      return setBids(tehBids);
    }
    setBids([...bids, bid]);
  };

  const addTaxHandler = (obj) => {
    const bidIndex = bids.findIndex((b) => b.item === obj.itemId);
    const newBids = [...bids];
    newBids[bidIndex] = {
      ...newBids[bidIndex],
      taxes: [...newBids[bidIndex].taxes, obj.tax]
    };
    setBids([...newBids]);
  };

  const deleteTaxHandler = (obj) => {
    deleteTax({ obj, tenderDetails, bids, setBids });
  };

  const rateChangeHandler = (obj) => {
    rateChange({ obj, bids, tenderDetails, setBids });
  };

  const addFileHandler = (attachment) =>
    setAttachmentsList([
      ...attachmentsList.filter((item) => item !== ""),
      attachment
    ]);

  const deleteFileHandler = (attachment) => {
    if (attachment?.id) {
      setAttachmentsList(
        attachmentsList.filter((item) => item.id !== attachment.id)
      );
      setAttachmentsDeleteList([
        ...attachmentsDeleteList,
        attachmentsList.find((item) => item.id === attachment.id)
      ]);
    } else {
      setAttachmentsList(
        attachmentsList.filter((item) => item.name !== attachment.name)
      );
    }
  };

  const handlerWithdraw = async () => {
    await deleteTenderBid({
      tenderId: tenderDetails.id,
      id: bid.id
    });
    setLoader(true);
    await getDetails();
    setLoader(false);
  };

  const getBidDetails = async () => {
    if (bid?.id && isShowRanks) {
      const { data } = await getTenderDetailBids({
        tenderId: tenderDetails.id,
        id: bid.id
      });

      let newItemsBids = [];
      if (tenderDetails.items.length !== data.data.items_bids.length) {
        newItemsBids = addTehBids(
          tenderDetails.items.filter(
            ({ id }) => !data.data.items_bids.some(({ item }) => id === item)
          )
        );
      }
      setBids([...data.data.items_bids, ...newItemsBids]);
    }
  };

  const title = useMemo(() => {
    if (isLogin) {
      return bid?.id ? "Your Bid Has Been Submitted" : "Submit Bid";
    }
    if (!unauthorizedBid) {
      return "You Have Been Invited to Bid on the Following Tender";
    }
    if (unauthorizedBid && isNewUser) {
      return "Your Bid Has Been Submitted. We have sent a temporary password to your email and phone number that you can use to login into your account, modify your bid and browse other tenders.";
    } else {
      return "Your Bid Has Been Submitted. Please use the password associated with your email/phone number to access your account, modify your bid and browse other tenders.";
    }
  }, [bid, isLogin, isNewUser, unauthorizedBid]);

  const isOpenTender = useMemo(
    () => tenderDetails.pricing_visibility === PRICING_VISIBILITY.openView,
    [tenderDetails, PRICING_VISIBILITY]
  );

  const isShowRanks = useMemo(
    () => tenderDetails.pricing_type === PRICING_TYPE.showRanks,
    [tenderDetails, PRICING_TYPE]
  );
  const isShowQuotes = useMemo(
    () => tenderDetails.pricing_type === PRICING_TYPE.showQuotes,
    [tenderDetails, PRICING_TYPE]
  );
  const isActive = useMemo(
    () => tenderDetails.status === TENDER_STATUSES.active,
    [tenderDetails, TENDER_STATUSES]
  );

  if (tenderError) {
    return <h1 className="h1 fw-bolder mb-14">{tenderError}</h1>;
  }

  return (
    <>
      <WithdrawModal
        show={show}
        onHide={handleClose}
        entity="bid"
        onConfirm={handlerWithdraw}
      />
      <>
        <h1 className="h1 fw-bolder mb-14">{title}</h1>

        {fullPageLoader || isBidDetailsLoading ? (
          <Loader />
        ) : (
          <>
            <div className="form">
              <PersonalInfo value={tenderDetails} />
            </div>

            <div className="" hidden={isLogin || (!isLogin && unauthorizedBid)}>
              <AddBidInfo
                form={unregisteredUserData}
                setForm={setUnregisteredUserData}
                setValid={setUnregisteredUserValid}
                errors={errors}
              />
            </div>

            {tenderDetails.attachments?.length > 0 && (
              <AttachmentsList list={tenderDetails.attachments} />
            )}

            {isLogin || (!isLogin && !unauthorizedBid) ? (
              <>
                {isOpenTender && isShowQuotes && (
                  <TableBidsComparison
                    items={tenderDetails.items}
                    bids={tenderDetails?.bids || []}
                    role={user.role}
                    tenderId={tenderDetails.id}
                  />
                )}

                {isOpenTender && isShowRanks && (
                  <TableBidsComparisonRanks
                    items={tenderDetails.items}
                    bids={tenderDetails?.bids || []}
                    totalRank={bid?.rank}
                  />
                )}
              </>
            ) : null}

            {isLogin || (!isLogin && !unauthorizedBid) ? (
              <>
                {(tenderDetails.pricing_visibility !==
                  PRICING_VISIBILITY.openView ||
                  (isOpenTender && isEditMode)) && (
                  <TableBidSubmitUpdate
                    items={tenderDetails.items}
                    bids={bids}
                    grandTotal={grandTotal}
                    onUpdate={updateFormHandler}
                    isEditMode={isEditMode}
                    isUpdateBidMode={isUpdateBidMode}
                    errors={errors}
                    taxes={taxes}
                    onTaxAdd={addTaxHandler}
                    onTaxDelete={deleteTaxHandler}
                    onRateChange={rateChangeHandler}
                    totalSummeryByBid={totalSummeryByBid}
                    totalTaxPrice={totalTaxPrice}
                    totalPrice={totalPrice}
                    totalTaxCount={totalTaxCount}
                    allowTaxes={tenderDetails.allow_taxes}
                  />
                )}

                {((isLogin && isEditMode) ||
                  (!isLogin && !unauthorizedBid)) && (
                  <AddAttachments
                    attachmentsList={attachmentsList}
                    onAddAttachment={addFileHandler}
                    isEditMode={isEditMode}
                    onDeleteAttachment={deleteFileHandler}
                  />
                )}

                {!isEditMode && attachmentsList?.length > 0 && (
                  <AttachmentsList list={attachmentsList} />
                )}

                {isLogin && isActive && !isEditMode && isUpdateBidMode && (
                  <div className="row g-2">
                    <div className="col-auto">
                      <Button
                        type="submit"
                        variant="light"
                        className="px-lg-5"
                        onClick={async () => {
                          await getBidDetails();
                          setEditMode(true);
                        }}
                        disabled={isLoading}
                      >
                        Edit
                      </Button>
                    </div>
                    <div className="col-auto">
                      <Button
                        type="submit"
                        variant="primary"
                        className="px-lg-5"
                        onClick={handleShow}
                        disabled={isLoading}
                      >
                        Withdraw{isLoading && <Loader />}
                      </Button>
                    </div>
                  </div>
                )}
                {isLogin && isActive && isEditMode && isUpdateBidMode && (
                  <div className="row g-2">
                    <div className="col-auto">
                      <Button
                        type="submit"
                        variant="danger"
                        className="px-lg-5"
                        onClick={updateBidHandler}
                        disabled={isLoading || !bids.some((b) => b.price)}
                      >
                        Confirm{isLoading && <Loader />}
                      </Button>
                    </div>
                    <div className="col-auto">
                      <Button
                        type="submit"
                        variant="primary"
                        className="px-lg-5"
                        onClick={cancelBidHandler}
                        disabled={isLoading}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                )}
                {isActive && isEditMode && !isUpdateBidMode && (
                  <div className="text-end pt-10">
                    <Button
                      type="submit"
                      variant="primary"
                      className="px-lg-5"
                      onClick={createBidHandler}
                      disabled={
                        isLoading ||
                        !bids.some((b) => b.price) ||
                        (!isLogin && !isUnregisteredUserValid)
                      }
                    >
                      Bid{isLoading && <Loader />}
                    </Button>
                  </div>
                )}
              </>
            ) : null}
          </>
        )}
      </>
    </>
  );
}
