import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

import { VidyardPlayer } from "../components";
import About from "./About";
import { APP_ROUTE } from "../utils/constants";
import logo from "../assets/images/logo.svg";

export default function LayoutLogin({ children }) {
  return (
    <>
      <div className="project py-4 px-2 p-lg-6 justify-content-start">
        <main className="page-content">
          <Container>
            <Row className="justify-content-center align-items-center">
              <Col lg={11}>
                <div className="md bg-light shadow-lg rounded-3 mx-auto py-4 py-lg-6 px-2 p-lg-6 position-relative">
                  <Row>
                    <Col md={4} lg={6}>
                      <Link
                        to={APP_ROUTE.HOME}
                        className="logo logo-md d-block mb-5 mt-lg-36"
                      >
                        <img
                          src={logo}
                          alt="ZoomTender"
                          className="img-fluid d-block mx-auto"
                        />
                      </Link>

                      <VidyardPlayer uuid="vKEwnoReewzWtmCSng1C3y" />
                    </Col>

                    <Col md={8} lg={6}>
                      <div className="shadow rounded-3 p-4 p-md-4 px-lg-3 px-xl-6 pt-lg-5 pb-lg-6 mt-lg-10">
                        {children}
                      </div>
                    </Col>
                  </Row>

                  <About />
                </div>
              </Col>
            </Row>
          </Container>
        </main>
      </div>
    </>
  );
}
