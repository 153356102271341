import React, { useState } from "react";
import { Container, Row, Col, Offcanvas } from "react-bootstrap";

import Sidebar from "./Sidebar";
import Navbar from "./Navbar";

export default function Layout({ children }) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Menu</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="pt-0">
          <Sidebar />
        </Offcanvas.Body>
      </Offcanvas>

      <div className="project py-4 px-0 px-sm-2 p-xl-6 justify-content-start">
        <main className="page-content">
          <Container fluid className="px-lg-0">
            <Row className="justify-content-center align-items-center g-0">
              <Col lg={12}>
                <div className="lg bg-light shadow-lg rounded-3 mx-auto position-relative">
                  <Row className="g-0">
                    <Col lg={3} xxl={2}>
                      <div
                        className="offcanvas offcanvas-start"
                        id="offcanvasMenu"
                        aria-labelledby="offcanvasMenuLabel"
                      >
                        <div className="offcanvas-body pt-0">
                          <Sidebar />
                        </div>
                      </div>
                    </Col>

                    <Col lg={9} xxl={10}>
                      <div className="position-relative px-2 pb-2 pt-lg-6 pb-lg-30 px-lg-4 ps-xl-0 pe-xl-40">
                        <Navbar handleShow={handleShow} />

                        <div className="bg-white shadow rounded-3 pt-4 px-2 pb-36 px-lg-4">
                          {children}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </main>
      </div>
    </>
  );
}
