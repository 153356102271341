import LayoutLogin from "../../layout/LayoutLogin";
import LoginForm from "./LoginForm";

export default function Login() {
  return (
    <>
      <LayoutLogin>
        <LoginForm />
      </LayoutLogin>
    </>
  );
}
