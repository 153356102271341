import { useEffect, useState } from "react";
import { Form, Button } from "react-bootstrap";

import {
  useGetEmailNotificationsMutation,
  useUpdateEmailNotificationsMutation
} from "../../redux/services/authApi";
import { Loader } from "../../components";
import { NOTIFICATION_LABELS } from "../../utils/constants";

export default function EmailNotificationsSettings() {
  const [updateEmailNotifications, { isLoading: isUpdating }] =
    useUpdateEmailNotificationsMutation();
  const [getEmailNotifications, { isLoading }] =
    useGetEmailNotificationsMutation();

  const [notifications, setNotifications] = useState({});
  const [errors, setErrors] = useState({});

  useEffect(() => {
    async function fetchData() {
      const { data } = await getEmailNotifications();
      setNotifications(data.data);
    }
    fetchData();
  }, []);

  const updateNotifications = (obj) => {
    setNotifications({ ...notifications, ...obj });
  };

  const changeNotifications = async (e) => {
    e.preventDefault();

    try {
      const { error } = await updateEmailNotifications(notifications);
      if (error && error.data.errors.length !== 0) {
        setErrors(error.data.errors[0]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <h1 className="h1 fw-bolder mb-0">Change Email settings</h1>
      <h6 className="fsz-18 mb-4">
        Select type of notification you would like to receive on your Email
      </h6>
      {isLoading ? (
        <Loader />
      ) : (
        <Form className="form">
          <ul className="list-unstyled mb-4">
            {Object.keys(notifications).map((key) => (
              <li className="mb-10" key={key}>
                <Form.Check
                  inline
                  name={NOTIFICATION_LABELS[key]}
                  type="checkbox"
                  defaultChecked={notifications[key]}
                  id={key}
                  label={NOTIFICATION_LABELS[key]}
                  onChange={(e) =>
                    updateNotifications({ [key]: e.target.checked })
                  }
                />
                {errors[key] && (
                  <Form.Control.Feedback type="invalid">
                    {errors[key]}
                  </Form.Control.Feedback>
                )}
              </li>
            ))}
          </ul>

          <Button
            variant="primary"
            type="submit"
            className="px-lg-5"
            onClick={changeNotifications}
            disabled={isUpdating}
          >
            Change {isUpdating && <Loader />}
          </Button>
        </Form>
      )}
    </>
  );
}
