export const transformationTime = (time) => {
  const userLocale =
    navigator.languages && navigator.languages.length
      ? navigator.languages[0]
      : navigator.language;

  const y = new Date(time);

  return y.toLocaleString(userLocale, {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit"
  });
};

export const transformationDate = (time) => {
  const userLocale =
    navigator.languages && navigator.languages.length
      ? navigator.languages[0]
      : navigator.language;

  const y = new Date(time);

  return y.toLocaleString(userLocale, {
    year: "numeric",
    month: "numeric",
    day: "numeric"
  });
};

export const toFloat = (value) => {
  return parseFloat(value.toFixed(2));
};

export const reduceTotalPrice = (list) => {
  return toFloat(
    list
      ? list
          .map((i) => (i.total_price ? i.total_price : 0))
          .reduce((prevValue, curValue) => prevValue + curValue, 0)
      : 0
  );
};

export const reducePrice = (list) => {
  return toFloat(
    list
      ? list
          .map((i) => (i.price && i.quantity ? i.price * i.quantity : 0))
          .reduce((prevValue, curValue) => prevValue + curValue, 0)
      : 0
  );
};

export const getDateStr = (date) => {
  const day = new Date(date);
  const mmStr = day.toLocaleString("en-us", { month: "short" });
  const dd = String(new Date(day).getDate()).padStart(2, "0");
  const yyyy = String(new Date(day).getFullYear());

  return `${mmStr} ${dd}, ${yyyy}`;
};

export const uploadAttachments = (uploadFunction, list, id) => {
  const promiseList = [];
  for (let i = 0; i < list.length; i++) {
    promiseList.push(
      uploadFunction({
        id,
        format: list[i].name.split("/").pop(),
        body: list[i]
      })
    );
  }

  return Promise.all(promiseList);
};
