import { Table, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { transformationTime } from "../utils/helpersFunctions";
import { Loader, Pagination } from "../components";

import { APP_ROUTE } from "../utils/constants";

export default function TableBidSearch({
  tenderList,
  countPage,
  isLoading,
  setCurrentPage,
  sessionsPerPage,
  currentPage
}) {
  return (
    <>
      <h3 className="h3 fw-bolder mb-14 mt-4">Search Results</h3>
      {isLoading ? (
        <Loader />
      ) : tenderList.length === 0 ? (
        <h6 className="fsz-18 mb-10">No results found</h6>
      ) : (
        <div className="mb-3 mx-n2 mx-lg-n4">
          <Table striped responsive>
            <thead>
              <tr>
                <th className="ps-2 ps-lg-4 tender-column">
                  <div>
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip>Tooltip on</Tooltip>}
                    >
                      <div>Tender</div>
                    </OverlayTrigger>
                  </div>
                </th>
                <th className="visibility-column">
                  <div>Visibility</div>
                </th>
                <th className="view-column">
                  <div>View</div>
                </th>
                <th className="date-column">
                  <div>
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip>Tooltip on</Tooltip>}
                    >
                      <div>Posted</div>
                    </OverlayTrigger>
                  </div>
                </th>
                <th className="date-column">
                  <div>
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip>Tooltip on</Tooltip>}
                    >
                      <div>Deadline</div>
                    </OverlayTrigger>
                  </div>
                </th>
                <th className="bids-column">
                  <div>Bids</div>
                </th>
                <th className="pe-2 pe-lg-4 date-column">
                  <div>Owner</div>
                </th>
              </tr>
            </thead>
            <tbody>
              {tenderList?.map((tender) => (
                <BidRow key={tender.id} tender={tender} />
              ))}
            </tbody>
          </Table>
        </div>
      )}

      {(tenderList.length > 9 || currentPage !== 1) && (
        <Pagination
          itemsCount={countPage}
          itemsPerPage={sessionsPerPage}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      )}
    </>
  );
}

function BidRow({ tender }) {
  const navigate = useNavigate();
  return (
    <tr
      className="cursor-pointer"
      onClick={() =>
        navigate(`${APP_ROUTE.CONTRACTOR_BID_SUBMIT}/${tender.id}`)
      }
    >
      <td className="ps-2 ps-lg-4 tender-column">
        <div>{tender.title}</div>
      </td>
      <td className="visibility-column">
        <div>{tender.visibility}</div>
      </td>
      <td className="view-column">
        <div>{tender.pricing_visibility}</div>
      </td>
      <td className="date-column">
        <div>{transformationTime(tender.created_at)}</div>
      </td>
      <td className="date-column">
        <div>{transformationTime(tender.deadline)}</div>
      </td>
      <td className="bids-column">
        <div>{tender.bids_count}</div>
      </td>
      <td
        className="pe-2 pe-lg-4 date-column"
        onClick={(e) => {
          e.stopPropagation();
          navigate(APP_ROUTE.EMPLOYER_PROFILE, { state: tender?.owner });
        }}
      >
        <div>{tender.owner.full_name}</div>
      </td>
    </tr>
  );
}
