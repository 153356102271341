import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Logo from "../assets/images/logo.svg";
import { logout } from "../redux/slices/userSlice";
import { APP_ROUTE, CLIENT_TYPE } from "../utils/constants";
import IconItem from "../components/IconItem";

export default function Navbar({ handleShow }) {
  const dispatch = useDispatch();
  const [view, setView] = useState(CLIENT_TYPE.employer);
  const { user, statistics } = useSelector((state) => state.userStore);
  const navigate = useNavigate();

  useEffect(() => {
    user.role === CLIENT_TYPE.employer
      ? setView(CLIENT_TYPE.employer)
      : setView(CLIENT_TYPE.contractor);
  }, []);

  return (
    <>
      <div className="panel position-absolute top-0 start-0 w-100 ps-lg-30 pe-lg-40 mb-3">
        <div className="d-flex align-items-center justify-content-between me-lg-10 h-100">
          <Link
            to={
              view === CLIENT_TYPE.employer
                ? APP_ROUTE.EMPLOYER_TENDERS
                : APP_ROUTE.CONTRACTOR_BIDS
            }
            className="d-block logo logo-xs ms-0 me-3"
          >
            <img src={Logo} alt="ZoomTender" className="img-fluid d-block" />
          </Link>

          {view === CLIENT_TYPE.contractor && (
            <span className="badge badge-label badge-primary ms-4 me-auto">
              <IconItem icon="contractor" />

              <span>Contractor</span>
            </span>
          )}

          {view === CLIENT_TYPE.employer && (
            <span className="badge badge-label badge-primary d-inline-flex align-items-center ms-sm-4 me-auto px-2 px-sm-3">
              <IconItem icon="employer" />
              <span className="d-none d-sm-block">Employer</span>
            </span>
          )}

          <div className="d-flex align-items-center">
            <Button
              onClick={() =>
                navigate(APP_ROUTE.INBOX, {
                  state: { status: true }
                })
              }
              className="panel-link text-reset text-decoration-none d-inline-flex align-items-center position-relative border-0 p-0 bg-transparent"
            >
              <IconItem icon="email" />
              {statistics.inbox_messages_count > 0 && (
                <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-warning ms-0">
                  {statistics.inbox_messages_count}
                </span>
              )}
            </Button>
            <Link
              to={APP_ROUTE.NOTIFICATIONS}
              className="panel-link text-reset text-decoration-none d-inline-flex align-items-center position-relative"
            >
              <IconItem icon="alarm" />
              {statistics.notifications_count > 0 && (
                <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-warning ms-0">
                  {statistics.notifications_count}
                </span>
              )}
            </Link>
            <Link
              to={APP_ROUTE.HOME}
              className="panel-link text-reset text-decoration-none d-inline-flex align-items-center"
            >
              <div onClick={() => dispatch(logout())}>
                <IconItem icon="logout" className="me-sm-2"/>
                <span className="form-label fw-normal">Log Out</span>
              </div>
            </Link>
          </div>

          <Button
            size="sm"
            variant="primary"
            onClick={handleShow}
            className="d-lg-none ms-3 p-2"
          >
            <IconItem icon="menu" className="text-white"/>
          </Button>
        </div>
      </div>
    </>
  );
}
