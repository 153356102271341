import { Form } from "react-bootstrap";
import Flatpickr from "react-flatpickr";
import confirmDatePlugin from "flatpickr/dist/plugins/confirmDate/confirmDate";

import IconItem from "./IconItem";

export function Calendar({ onUpdate, date }) {
  return (
    <Form.Group className="calendar position-relative">
      <Flatpickr
        data-enable-time
        value={date}
        options={{
          mode: "single",
          allowInput: false,
          enableTime: true,
          dateFormat: "d/m/Y H:i",
          monthSelectorType: "static",
          yearSelectorType: "static",
          closeOnSelect: false,
          minDate: `${new Date().toISOString()}`,
          nextArrow:
            "<svg width='7' height='13' viewBox='0 0 7 13' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M1 11.814L5.2 6.657L1 1.5' stroke='#213A5C' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/></svg>",
          prevArrow:
            "<svg width='7' height='13' viewBox='0 0 7 13' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M5.2 11.814L1 6.657L5.2 1.5' stroke='#213A5C' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/></svg>",
          plugins: [
            new confirmDatePlugin({
              confirmText: "Done",
              showAlways: true
            })
          ]
        }}
        onChange={(date) => {
          onUpdate({
            deadline: new Date(date).toISOString("en-US", {
              timeZone: "Asia/Kolkata"
            })
          });
        }}
        className="form-control border"
        style={{ height: "50px", paddingLeft: "17px" }}
        id="flatpickr"
      />
      <div className="select-mask"></div>
      <Form.Label
        htmlFor="flatpickr"
        className="btn h-100 border-start rounded-0 position-absolute top-0 end-0"
      >
        <IconItem icon="calendar" />
      </Form.Label>
    </Form.Group>
  );
}

export function DateCalendar({ onUpdate, date }) {
  const toLocalDate = (date) => {
    const local = new Date(date);
    local.setMinutes(date.getMinutes() - date.getTimezoneOffset());
    return local.toJSON();
  };

  return (
    <Form.Group className="calendar position-relative">
      <Flatpickr
        value={date}
        options={{
          mode: "single",
          allowInput: false,
          dateFormat: "d/m/Y",
          monthSelectorType: "static",
          yearSelectorType: "static",
          closeOnSelect: false,
          minDate: `${new Date().toISOString()}`,
          nextArrow:
            "<svg width='7' height='13' viewBox='0 0 7 13' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M1 11.814L5.2 6.657L1 1.5' stroke='#213A5C' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/></svg>",
          prevArrow:
            "<svg width='7' height='13' viewBox='0 0 7 13' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M5.2 11.814L1 6.657L5.2 1.5' stroke='#213A5C' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/></svg>",
          plugins: [
            new confirmDatePlugin({
              confirmText: "Done",
              showAlways: true
            })
          ]
        }}
        onChange={(date) => onUpdate(toLocalDate(new Date(date)))}
        className="form-control border"
        style={
          date?.length !== 0
            ? {
                height: "50px",
                paddingLeft: "25px",
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0
              }
            : { height: "50px", paddingLeft: "25px" }
        }
        id="flatpickr"
      />
      <Form.Label
        htmlFor="flatpickr"
        className="btn h-100 border-start rounded-0 position-absolute top-0 end-0"
      >
        <IconItem icon="calendar" />
      </Form.Label>
    </Form.Group>
  );
}
