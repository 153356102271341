import { createApi } from "@reduxjs/toolkit/query/react";

import { baseQueryWithReauth } from "./reAuth";
import { API_METHODS } from "../../utils/constants";

export const tenderApi = createApi({
  reducerPath: "tenderApi",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    // -------------Employer-----------------------------------------
    getTenders: builder.mutation({
      query: ({
        page,
        pricingVisibility,
        visibility,
        status,
        deadline,
        o
      }) => ({
        url: `/employer/tenders/?page=${page}${pricingVisibility}${visibility}${status}${deadline}${o}`,
        method: API_METHODS.get
      })
    }),
    createTender: builder.mutation({
      query: (body) => ({
        url: "/employer/tenders/",
        method: API_METHODS.post,
        body
      })
    }),
    createDraftTender: builder.mutation({
      query: (body) => ({
        url: "/employer/tender-draft/",
        method: API_METHODS.post,
        body
      })
    }),
    updateDraftTender: builder.mutation({
      query: ({ id, body }) => ({
        url: `/employer/tenders-draft/${id}/`,
        method: API_METHODS.patch,
        body
      })
    }),
    getTenderDetails: builder.mutation({
      query: (id) => ({
        url: `/employer/tenders/${id}/`,
        method: API_METHODS.get
      })
    }),
    updateTender: builder.mutation({
      query: ({ id, body }) => ({
        url: `/employer/tenders/${id}/`,
        method: API_METHODS.patch,
        body
      })
    }),
    getUpdatedTenders: builder.mutation({
      query: ({ id, credentials }) => ({
        url: `tenders/${id}`,
        method: API_METHODS.get,
        body: credentials
      })
    }),
    tenderFinalize: builder.mutation({
      query: ({ id, body }) => ({
        url: `/employer/tenders/${id}/finalize/`,
        method: API_METHODS.put,
        body
      })
    }), // -------------BIDS-----------------------------------------
    getTendersContractor: builder.mutation({
      query: (page) => ({
        url: `/contractor/tenders/?page=${page}`,
        method: API_METHODS.get
      })
    }),
    getTendersBids: builder.mutation({
      query: () => ({
        url: "/contractor/bids/",
        method: API_METHODS.get
      })
    }),
    getTenderDetailBids: builder.mutation({
      query: ({ tenderId, id }) => ({
        url: `/contractor/tender/${tenderId}/bid/${id}/`,
        method: API_METHODS.get
      })
    }),
    updateTenderBid: builder.mutation({
      query: ({ tenderId, id, body }) => ({
        url: `/contractor/tender/${tenderId}/bid/${id}/`,
        method: API_METHODS.put,
        body
      })
    }),
    deleteTenderBid: builder.mutation({
      query: ({ tenderId, id }) => ({
        url: `/contractor/tender/${tenderId}/bid/${id}/`,
        method: API_METHODS.delete
      })
    }),
    getTenderSubmitBids: builder.mutation({
      query: (id) => ({
        url: `/contractor/tender/${id}/`,
        method: API_METHODS.get
      })
    }),
    getTenderByUnregisteredContractor: builder.mutation({
      query: (id) => ({
        url: `/contractor/tender-un/${id}/`,
        method: API_METHODS.get
      })
    }),
    getTenderSearchBids: builder.mutation({
      query: ({ page, pricingVisibility, deadline }) => ({
        url: `/contractor/tenders/?page=${page}${pricingVisibility}${deadline}`,
        method: API_METHODS.get
      })
    }),
    postTenderBids: builder.mutation({
      query: ({ tenderId, body }) => ({
        url: `/contractor/tender/${tenderId}/bid/`,
        method: API_METHODS.post,
        body
      })
    }),
    postTenderBidsUnregistered: builder.mutation({
      query: ({ tenderId, body }) => ({
        url: `/contractor/bids-un/${tenderId}/bid/`,
        method: API_METHODS.post,
        body
      })
    }),
    getBidsTenders: builder.mutation({
      query: ({
        page,
        pricingVisibility,
        visibility,
        status,
        deadline,
        o
      }) => ({
        url: `/contractor/bids/?page=${page}${pricingVisibility}${visibility}${status}${deadline}${o}`,
        method: API_METHODS.get
      })
    }),
    getEmployerSearchContractors: builder.mutation({
      query: ({ provider, page }) => ({
        url: `/employer/search_contractors/?page=${page}${provider}`,
        method: API_METHODS.get
      })
    }), // -------------employer.contractors-----------------------------------------
    sendInvitationEmail: builder.mutation({
      query: ({ tenderId, body }) => ({
        url: `/employer/tenders/${tenderId}/invited_contractors/`,
        method: API_METHODS.post,
        body
      })
    }),
    alreadyInvited: builder.mutation({
      query: (tenderId) => ({
        url: `/employer/tenders/${tenderId}/invited_contractors/`,
        method: API_METHODS.get
      })
    }),
    deleteEmailInvitedContractor: builder.mutation({
      query: ({ tenderId, id }) => ({
        url: `/employer/tenders/${tenderId}/invited_contractors/${id}/`,
        method: API_METHODS.delete
      })
    }),
    // -------------employer.attachments-----------------------------------------
    attachmentsGetEmployer: builder.mutation({
      query: (id) => ({
        url: `/employer/tenders/${id}/attachments/`,
        method: API_METHODS.get
      })
    }),
    attachmentsPostEmployer: builder.mutation({
      query: ({ id, format, body }) => ({
        url: `/employer/tenders/${id}/attachments/`,
        method: API_METHODS.post,
        headers: {
          "content-disposition": `attachment; filename=upload_${format}`,
          "content-type": "*"
        },
        body
      })
    }),
    attachmentsDeleteEmployer: builder.mutation({
      query: ({ tenderId, id }) => ({
        url: `/employer/tenders/${tenderId}/attachments/${id}`,
        method: API_METHODS.delete
      })
    }),
    // -------------contractor.attachments-----------------------------------------
    attachmentsGetContractor: builder.mutation({
      query: (id) => ({
        url: `/contractor/bids/${id}/attachments/`,
        method: API_METHODS.get
      })
    }),
    attachmentsPostContractor: builder.mutation({
      query: ({ id, format, body }) => ({
        url: `/contractor/bids/${id}/attachments/`,
        method: API_METHODS.post,
        headers: {
          "content-disposition": `attachment; filename=upload_${format}`,
          "content-type": "*"
        },
        body
      })
    }),
    attachmentsDeleteContractor: builder.mutation({
      query: ({ bidId, attachmentId }) => ({
        url: `/contractor/bids/${bidId}/attachments/${attachmentId}/`,
        method: API_METHODS.delete
      })
    }),
    comparePrices: builder.mutation({
      query: (id) => ({
        url: `/file-comparative-price/${id}/`,
        method: API_METHODS.get
      })
    }),
    getTaxes: builder.mutation({
      query: () => ({
        url: "/taxes/",
        method: API_METHODS.get
      })
    }),
    // -------------orders---------------------------------------------------------
    getTendersWithOrders: builder.mutation({
      query: ({ page }) => ({
        url: `/employer/orders/?page=${page}`,
        method: API_METHODS.get
      })
    }),
    getTenderOrders: builder.mutation({
      query: (id) => ({
        url: `/employer/tender/${id}/orders/`,
        method: API_METHODS.get
      })
    }),
    postTenderOrder: builder.mutation({
      query: ({ tenderId, body }) => ({
        url: `/employer/tender/${tenderId}/orders/`,
        method: API_METHODS.post,
        body
      })
    }),
    postTenderOrderSendEmail: builder.mutation({
      query: ({ tenderId, orderId }) => ({
        url: `/employer/tender/${tenderId}/orders/${orderId}/send-email/`,
        method: API_METHODS.post
      })
    })
  })
});

export const {
  useGetTendersMutation,
  useGetCurrentPageMutation,
  useCreateTenderMutation,
  useCreateDraftTenderMutation,
  useUpdateDraftTenderMutation,
  useGetUpdatedTendersMutation,
  useGetTenderDetailsMutation,
  useUpdateTenderMutation,
  useDeleteTendersMutation,
  useTenderFinalizeMutation,

  useGetTendersContractorMutation,

  useGetTendersBidsMutation,
  useGetTenderDetailBidsMutation,
  useGetBidsTendersMutation,
  useUpdateTenderBidMutation,
  useDeleteTenderBidMutation,
  useGetTenderSubmitBidsMutation,
  useGetTenderByUnregisteredContractorMutation,
  useGetTenderSearchBidsMutation,
  usePostTenderBidsMutation,
  usePostTenderBidsUnregisteredMutation,
  useGetEmployerSearchContractorsMutation,
  useSendInvitationEmailMutation,
  useAlreadyInvitedMutation,
  useDeleteEmailInvitedContractorMutation,

  useAttachmentsGetEmployerMutation,
  useAttachmentsPostEmployerMutation,
  useAttachmentsDeleteEmployerMutation,
  useAttachmentsGetContractorMutation,
  useAttachmentsPostContractorMutation,
  useAttachmentsDeleteContractorMutation,
  useComparePricesMutation,
  useGetTaxesMutation,
  useGetTendersWithOrdersMutation,
  useGetTenderOrdersMutation,
  usePostTenderOrderMutation,
  usePostTenderOrderSendEmailMutation
} = tenderApi;
