import { Row, Col, Form, Button } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { EMAIL_REGEXP, APP_ROUTE } from "../../utils/constants";
import { IconError, IconSuccess } from "../../utils/svgHelper";
import { useMailSendMessageMutation } from "../../redux/services/authApi";
import { Loader } from "../../components";
// import { useSelector } from "react-redux";

export default function MailNewMessages() {
  const [form, setForm] = useState({ recipient: "", subject: "", body: "" });
  const [mailSendMessage, { isLoading: isLoadingMailSendMessage }] =
    useMailSendMessageMutation();
  const navigate = useNavigate();
  const { state } = useLocation();
  // const { user } = useSelector((state) => state.userStore);
  const schema = yup.object().shape({
    recipient: yup
      .string()
      .trim()
      .required("Recipient is a required field")
      .email("Recipient must be a valid email")
      .matches(EMAIL_REGEXP, "Recipient must be a valid email"),
    subject: yup.string().required("This field is required."),
    body: yup.string().required("This field is required.")
  });

  const formDateUpdateHandler = (opt) => {
    setForm({ ...form, ...opt });
  };

  useEffect(() => {
    const tehDateInState = {
      recipient: state?.sender?.email || state?.email,
      subject: state?.subject ? `Re: ${state.subject}` : "",
      body: state?.body
        ? `${state?.sender?.full_name} wrote: \n > ${state.body}  \n >`
        : ""
    };
    setForm(tehDateInState);
  }, [state]);

  const handleSubmit = async (values, { setErrors }) => {
    const tehData = {
      recipient: values.recipient,
      subject: values.subject,
      body: values.body
    };
    try {
      const { error } = await mailSendMessage(tehData);
      if (error && error.data.errors.length !== 0) {
        setErrors({
          body: error.data.errors[0].body,
          subject: error.data.errors[0].subject,
          recipient: error.data.errors[0].recipient
        });
        return;
      }
      setForm({});
      navigate(state ? APP_ROUTE.INBOX : -1);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <h1 className="h1 fw-bolder mb-14">Compose Message</h1>
      <Formik
        enableReinitialize
        validateOnChange
        initialValues={{
          recipient: form.recipient,
          subject: form.subject,
          body: form.body
        }}
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          isValid,
          handleSubmit,
          dirty
        }) => (
          <Form className="form" onSubmit={handleSubmit}>
            <div className="mb-4 px-3 px-lg-0">
              <Form.Group className="position-relative">
                <Row className="align-items-center">
                  <Col
                    sm={4}
                    lg={3}
                    className="text-start text-sm-end mb-2 mb-sm-0"
                  >
                    <Form.Label className="col-form-label py-0">
                      Recipient
                    </Form.Label>
                  </Col>
                  <Col sm={8} lg={5}>
                    <div className="position-relative">
                      <div className="position-relative">
                        <Form.Control
                          type="text"
                          name="recipient"
                          className={`${touched.recipient ? "is-touch " : ""} ${
                            errors.recipient && touched.recipient
                              ? " is-invalid"
                              : ""
                          } ${
                            !errors.recipient && touched.recipient
                              ? " is-valid"
                              : ""
                          }`}
                          value={values.recipient}
                          onBlur={handleBlur}
                          onChange={(e) => {
                            handleChange(e);
                            formDateUpdateHandler({
                              [e.target.name]: e.target.value
                            });
                          }}
                          placeholder="Recipient"
                        />
                        {errors.recipient && touched.recipient && <IconError />}

                        {!errors.recipient && touched.recipient && (
                          <IconSuccess />
                        )}
                      </div>

                      {errors.recipient && touched.recipient && (
                        <Form.Control.Feedback
                          type="invalid"
                          className="position-absolute start-0 top-100"
                        >
                          {errors.recipient}
                        </Form.Control.Feedback>
                      )}
                    </div>
                  </Col>
                </Row>
              </Form.Group>
            </div>
            <div className="mb-4 px-3 px-lg-0">
              <Form.Group className="position-relative">
                <Row className="align-items-center">
                  <Col
                    sm={4}
                    lg={3}
                    className="text-start text-sm-end mb-2 mb-sm-0"
                  >
                    <Form.Label className="col-form-label py-0">
                      Subject
                    </Form.Label>
                  </Col>
                  <Col sm={8} lg={5}>
                    <div className="position-relative">
                      <div className="position-relative">
                        <Form.Control
                          type="text"
                          name="subject"
                          className={`${touched.subject ? "is-touch " : ""} ${
                            errors.subject && touched.subject
                              ? " is-invalid"
                              : ""
                          } ${
                            !errors.subject && touched.subject
                              ? " is-valid"
                              : ""
                          }`}
                          value={values.subject}
                          onBlur={handleBlur}
                          onChange={(e) => {
                            handleChange(e);
                            formDateUpdateHandler({
                              [e.target.name]: e.target.value
                            });
                          }}
                          placeholder="Subject"
                        />

                        {errors.subject && touched.subject && <IconError />}
                        {!errors.subject && touched.subject && <IconSuccess />}
                      </div>
                      {errors.subject && touched.subject && (
                        <Form.Control.Feedback
                          type="invalid"
                          className="position-absolute start-0 top-100"
                        >
                          {errors.subject}
                        </Form.Control.Feedback>
                      )}
                    </div>
                  </Col>
                </Row>
              </Form.Group>
            </div>

            <div className="mb-4 px-3 px-lg-0">
              <Form.Group className="position-relative">
                <Row className="align-items-center">
                  <Col
                    sm={4}
                    lg={3}
                    className="text-start text-sm-end mb-2 mb-sm-0"
                  >
                    <Form.Label className="col-form-label py-0">
                      Body
                    </Form.Label>
                  </Col>
                  <Col sm={8} lg={5}>
                    <div className="position-relative">
                      <div className="position-relative">
                        <Form.Control
                          as="textarea"
                          rows={3}
                          name="body"
                          className={`${touched.body ? "is-touch " : ""} ${
                            errors.body && touched.body ? " is-invalid" : ""
                          } ${!errors.body && touched.body ? " is-valid" : ""}`}
                          value={values.body}
                          onBlur={handleBlur}
                          onChange={(e) => {
                            handleChange(e);
                            formDateUpdateHandler({
                              [e.target.name]: e.target.value
                            });
                          }}
                          placeholder="Body"
                        />

                        {errors.body && touched.body && <IconError />}
                        {!errors.body && touched.body && <IconSuccess />}
                      </div>
                      {errors.body && touched.body && (
                        <Form.Control.Feedback
                          type="invalid"
                          className="position-absolute start-0 top-100"
                        >
                          {errors.body}
                        </Form.Control.Feedback>
                      )}
                    </div>
                  </Col>
                </Row>
              </Form.Group>
            </div>

            <div className="mb-4 px-3 px-lg-0">
              <Form.Group className="position-relative">
                <Row className="align-items-center">
                  <Col
                    sm={4}
                    lg={3}
                    className="text-start text-sm-end mb-2 mb-sm-0"
                  >
                    &nbsp;
                  </Col>
                  <Col sm={8} lg={5}>
                    <Button
                      variant="primary"
                      className="w-100"
                      disabled={values.recipient === ""}
                      onClick={handleSubmit}
                    >
                      Send message {isLoadingMailSendMessage && <Loader />}
                    </Button>
                  </Col>
                </Row>
              </Form.Group>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}
