import { Row, Col } from "react-bootstrap";
import BlogCard from "./BlogCard";

export default function BlogRelated({ postsRelated }) {
  return (
    <div className="blog-related">
      <h2 className="h2 display-35">Related articles</h2>
      <Row>
        {postsRelated?.slice(0, 3).map((post) => (
          <Col md={4} className="mb-20" key={post.slug}>
            <BlogCard post={post} />
          </Col>
        ))}
      </Row>
    </div>
  );
}
