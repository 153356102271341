import { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";

import { useUsersInfoMutation } from "../../redux/services/authApi";
import { Loader } from "../../components";
import { APP_ROUTE } from "../../utils/constants";

const ProfileOf = () => {
  const [userInfoData, setUserInfoData] = useState({});
  const navigate = useNavigate();
  const { state } = useLocation();
  const [usersInfo, { isLoading: isLoadingUserInfo }] = useUsersInfoMutation();

  useEffect(() => {
    async function fetchData() {
      const res = await usersInfo(state?.id);
      setUserInfoData(res?.data.data);
    }
    fetchData();
  }, []);

  return (
    <>
      <h1 className="h1 fw-bolder mb-14">
        Profile of {userInfoData?.full_name}
      </h1>
      {isLoadingUserInfo ? (
        <Loader />
      ) : (
        <Row className="row justify-content-between">
          <Col md={8} lg={7}>
            <div className="form">
              <div className="mb-2">
                <Row className="g-0 align-items-center flex-sm-nowrap">
                  <Col sm="auto">
                    <div className="fs-6 lh-base col-form-label py-0">
                      Name:
                    </div>
                  </Col>
                  <Col>
                    <div className="form-control readonly">
                      {userInfoData?.full_name}
                    </div>
                  </Col>
                </Row>
              </div>

              <div className="mb-2">
                <Row className="g-0 align-items-center flex-sm-nowrap">
                  <Col sm="auto">
                    <div className="fs-6 lh-base col-form-label py-0">
                      Type:
                    </div>
                  </Col>
                  <Col>
                    <div className="form-control readonly">
                      {userInfoData?.role}
                    </div>
                  </Col>
                </Row>
              </div>

              {userInfoData?.gst && (
                <div className="mb-2">
                  <Row className="g-0 align-items-center flex-sm-nowrap">
                    <Col sm="auto">
                      <div className="fs-6 lh-base col-form-label py-0">
                        GST:
                      </div>
                    </Col>
                    <Col>
                      <div className="form-control readonly">
                        {userInfoData?.gst}
                      </div>
                    </Col>
                  </Row>
                </div>
              )}

              {userInfoData?.pan && (
                <div className="mb-2">
                  <Row className="g-0 align-items-center flex-sm-nowrap">
                    <Col sm="auto">
                      <div className="fs-6 lh-base col-form-label py-0">
                        PAN:
                      </div>
                    </Col>
                    <Col>
                      <div className="form-control readonly">
                        {userInfoData?.pan}
                      </div>
                    </Col>
                  </Row>
                </div>
              )}

              {userInfoData?.description && (
                <div className="mb-2">
                  <Row className="g-0 flex-sm-nowrap">
                    <Col sm="auto">
                      <div className="fs-6 lh-base col-form-label py-0">
                        Cover letter:
                      </div>
                    </Col>
                    <Col>
                      <div className="form-control readonly text-break py-1">
                        {userInfoData?.description}
                      </div>
                    </Col>
                  </Row>
                </div>
              )}

              <Row className="g-0">
                <Col sm={6} lg={6} xl={5}>
                  <Button
                    variant="primary"
                    className="w-100"
                    onClick={() => {
                      navigate(APP_ROUTE.NEW_MESSAGE, { state });
                    }}
                  >
                    Send a message
                  </Button>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      )}
    </>
  );
};

export default ProfileOf;
