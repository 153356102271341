import { useCallback, useEffect, useState } from "react";
import { Row, Col, Button, Form } from "react-bootstrap";
import delSVG from "../../assets/images/deleteCalendareDate.svg";

import { useGetTenderSearchBidsMutation } from "../../redux/services/tenderApi";
import { DateCalendar, TableBidSearch } from "../../components";
import { PRICING_VISIBILITY } from "../../utils/constants";

export default function BidSearch() {
  const [tenderList, setTenderList] = useState([]);
  const [countPage, setCountPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [date, setDate] = useState("");

  const pricingVisibilityList = [
    PRICING_VISIBILITY.openView,
    PRICING_VISIBILITY.employerView,
    PRICING_VISIBILITY.closedView
  ];

  const [pricingVisibility, setPricingVisibility] = useState([]);
  const [getTenderSearchBids, { isLoading }] = useGetTenderSearchBidsMutation();

  const getSearchList = useCallback(async () => {
    const pricingVisibilityStr =
      pricingVisibility.length > 0
        ? pricingVisibility.map((pv) => `&pricing_visibility=${pv}`).join("")
        : "";
    const deadline = date ? `&deadline=${date.split("T")[0]}` : "";

    const { data } = await getTenderSearchBids({
      page: currentPage,
      pricingVisibility: pricingVisibilityStr,
      deadline
    });

    if (data.data.count === 0) {
      setCountPage(1);
    } else {
      setCountPage(data.data.count);
    }

    setTenderList(data.data.results);
  }, [currentPage, pricingVisibility, date]);

  useEffect(() => {
    getSearchList();
  }, [currentPage]);

  const tenderTypeChangeHandler = (value) => {
    const index = pricingVisibility.findIndex((pv) => pv === value);

    if (index < 0) {
      setPricingVisibility([...pricingVisibility, value]);
    } else {
      setPricingVisibility(pricingVisibility.filter((pv) => pv !== value));
    }
    setCurrentPage(1);
  };

  const clearCalendarDate = () => {
    setDate("");
  };

  return (
    <>
      <h1 className="h1 fw-bolder mb-14">Search for Tenders</h1>

      <h6 className="fsz-18 mb-30">Filters</h6>

      <Form className="form">
        <Row className="justify-content-between">
          <Col md={5}>
            {/* <div className="mb-14">
              <Form.Select aria-label="Default select example">
                <option selected>Industry</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </Form.Select>
            </div> */}

            <div className="mb-14 d-flex justify-content-start">
              <DateCalendar onUpdate={setDate} date={date} />
              {date?.length !== 0 && (
                <Button
                  className="bg-light border"
                  style={{
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                    borderLeftWidth: 0,
                    background: "transparent"
                  }}
                  title="Clear calendar"
                  onClick={clearCalendarDate}
                >
                  <img src={delSVG} alt="clear date" />
                </Button>
              )}
            </div>

            <div className="d-none d-md-block">
              <Button
                variant="primary"
                className="px-lg-5"
                onClick={getSearchList}
              >
                Search
              </Button>
            </div>
          </Col>

          <Col md={6}>
            <div className="form-label mb-20">Type of Tender</div>

            <ul className="list-unstyled pb-10">
              {pricingVisibilityList.map((pv) => (
                <li key={pv} className="mb-2">
                  <Form.Check
                    key={pv}
                    inline
                    id={pv}
                    type="checkbox"
                    label={pv}
                    value={pv}
                    onChange={() => tenderTypeChangeHandler(pv)}
                  />
                </li>
              ))}
            </ul>
          </Col>
          <Col md={12}>
            <div className="d-md-none">
              <Button
                variant="primary"
                className="px-lg-5"
                onClick={getSearchList}
              >
                Search
              </Button>
            </div>
          </Col>
        </Row>
      </Form>

      <TableBidSearch
        tenderList={tenderList}
        countPage={countPage}
        currentPage={currentPage}
        isLoading={isLoading}
        setCurrentPage={setCurrentPage}
      />
    </>
  );
}
