import { useMemo } from "react";
import { Table, Form } from "react-bootstrap";

import { toFloat } from "../utils/helpersFunctions";

export default function TableFinalizeTender({
  items,
  onUpdate,
  isEditMode,
  grandTotal,
  finalizedItems,
  errors
}) {
  return (
    <div className="table-rounded mb-30 shadow bg-white rounded-1">
      <Table striped responsive>
        <thead>
          <tr>
            <th className="text-center">
              <div>S No</div>
            </th>
            <th>
              <div>Item Name</div>
            </th>
            <th>
              <div>Description</div>
            </th>
            <th>
              <div>Quantity</div>
            </th>
            <th>
              <div>Unit</div>
            </th>
            <th>
              <div>Price</div>
            </th>
            <th>
              <div>Total price</div>
            </th>
          </tr>
        </thead>
        <tbody>
          {items?.map((item, index) => (
            <ItemRow
              key={item.id}
              item={item}
              onUpdate={onUpdate}
              isEditMode={isEditMode}
              finalizedItem={finalizedItems.find((i) => i.id === item.id)}
              index={index}
              errors={errors}
            />
          ))}
          <GrandTotalRow grandTotal={grandTotal} />
        </tbody>
      </Table>
    </div>
  );
}

function ItemRow({ item, onUpdate, isEditMode, finalizedItem, index, errors }) {
  const priceError = useMemo(() => {
    if (errors && errors.items && errors.items[index]) {
      return errors.items[index].price;
    }
    return "";
  }, [errors]);

  return (
    <tr id={item.id}>
      <td className="text-center">
        <div>{index + 1}</div>
      </td>
      <td>
        <div>{item.name}</div>
      </td>
      <td>
        <div>{item.description}</div>
      </td>
      <td>
        <div>{item.quantity}</div>
      </td>
      <td>
        <div>{item.unit}</div>
      </td>
      <td className="ps-0">
        <div>
          {isEditMode ? (
            <>
              <Form.Control
                type="number"
                className={`w-75 ${priceError ? "is-invalid" : ""}`}
                value={finalizedItem?.price}
                onChange={(e) => {
                  const price = e.target.value ? Number(e.target.value) : null;
                  onUpdate({
                    id: item.id,
                    price,
                    total_price: price
                      ? toFloat(price * toFloat(item.quantity))
                      : null
                  });
                }}
                min="0"
              />
              {priceError && (
                <Form.Control.Feedback type="invalid">
                  {priceError}
                </Form.Control.Feedback>
              )}
            </>
          ) : (
            <div>{finalizedItem?.price}</div>
          )}
        </div>
      </td>
      <td>
        <div>{finalizedItem?.total_price}</div>
      </td>
    </tr>
  );
}

function GrandTotalRow({ grandTotal }) {
  return (
    <tr>
      <td className="text-center">
        <div>Grand Total</div>
      </td>
      <td>
        <div></div>
      </td>
      <td>
        <div></div>
      </td>
      <td>
        <div></div>
      </td>
      <td>
        <div></div>
      </td>
      <td>
        <div></div>
      </td>
      <td>
        <div>{grandTotal}</div>
      </td>
    </tr>
  );
}
