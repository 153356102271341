import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import cloneDeep from "lodash.clonedeep";

import TenderNew from "./TenderNew";
import TenderNew2 from "./TenderNew2";
import {
  useAttachmentsDeleteEmployerMutation,
  useAttachmentsPostEmployerMutation,
  useUpdateTenderMutation,
  useCreateTenderMutation,
  useCreateDraftTenderMutation,
  useUpdateDraftTenderMutation
} from "../../../redux/services/tenderApi";
import {
  PRICING_VISIBILITY,
  VISIBILITY,
  PRICING_TYPE,
  APP_ROUTE,
  TENDER_STATUSES
} from "../../../utils/constants";
import { uploadAttachments } from "../../../utils/helpersFunctions";

const TenderStepsBuilder = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState(0);
  const [serverErrors, setServerErrors] = useState({});
  const { user } = useSelector((state) => state.userStore);
  const [createTender, { isLoading: isCreateTenderLoading }] =
    useCreateTenderMutation();
  const [updateTender, { isLoading: isUpdateTenderLoading }] =
    useUpdateTenderMutation();
  const [uploadAttachment, { isLoading: isPostAttachmentLoading }] =
    useAttachmentsPostEmployerMutation();
  const [deleteAttachment, { isLoading: isDeleteAttachmentLoading }] =
    useAttachmentsDeleteEmployerMutation();
  const [createDraftTender, { isLoading: isCreateDraftTenderLoading }] =
    useCreateDraftTenderMutation();
  const [updateDraftTender, { isLoading: isUpdateDraftTenderLoading }] =
    useUpdateDraftTenderMutation();
  const isLoading =
    isPostAttachmentLoading ||
    isDeleteAttachmentLoading ||
    isUpdateTenderLoading;

  const isDraftLoading =
    isCreateDraftTenderLoading || isUpdateDraftTenderLoading;

  const { state } = useLocation();
  const tenderDetail = state?.tenderDetail;
  const isEdit = state?.isEdit;
  const isRecreate = state?.isRecreate;

  const initialState = {
    title: "",
    pricing_visibility: PRICING_VISIBILITY.employerView,
    visibility: VISIBILITY.public,
    description: "",
    pricing_type: PRICING_TYPE.showQuotes,
    deadline: new Date(new Date().getTime() + 86400000).toISOString(),
    allow_taxes: false,
    status: TENDER_STATUSES.draft
  };

  const itemInitialState = {
    id: Date.now(),
    name: "",
    description: "",
    quantity: "",
    unit: ""
  };

  const [form, setForm] = useState(initialState);
  const [itemList, setItemList] = useState([itemInitialState]);
  const [attachmentsList, setAttachmentsList] = useState([]);
  const [attachmentsDeleteList, setAttachmentsDeleteList] = useState([]);

  const formUpdateHandler = (opt) => {
    setForm({ ...form, ...opt });
  };

  useEffect(() => {
    if (isEdit || isRecreate) {
      setForm({
        title: tenderDetail?.title,
        pricing_visibility: tenderDetail?.pricing_visibility,
        visibility: tenderDetail?.visibility,
        description: tenderDetail?.description,
        pricing_type: tenderDetail?.pricing_type,
        deadline: isRecreate ? null : tenderDetail?.deadline,
        allow_taxes: tenderDetail?.allow_taxes,
        status: isRecreate ? TENDER_STATUSES.draft : tenderDetail?.status
      });
      let itemsCopy = cloneDeep(tenderDetail?.items).map((i) => ({
        ...i,
        price: null,
        total_price: null
      }));
      if (
        tenderDetail?.status === TENDER_STATUSES.draft &&
        itemsCopy.length === 0
      ) {
        itemsCopy = [initialState];
      }
      setItemList(itemsCopy);
      setAttachmentsList(tenderDetail?.attachments);
    } else {
      setForm(initialState);
      setItemList([itemInitialState]);
    }
  }, [isEdit, isRecreate]);

  const addAttachmentHandler = (attachment) =>
    setAttachmentsList([
      ...attachmentsList.filter((item) => item !== ""),
      attachment
    ]);

  const deleteAttachmentHandler = (attachment) => {
    if (attachment?.id) {
      setAttachmentsList(
        attachmentsList.filter((item) => item.id !== attachment.id)
      );
      setAttachmentsDeleteList([
        ...attachmentsDeleteList,
        attachmentsList.find((item) => item.id === attachment.id)
      ]);
      return;
    }
    setAttachmentsList(
      attachmentsList.filter((item) => item.name !== attachment.name)
    );
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    const tehData = {
      owner: user.id,
      title: form.title,
      visibility: form.visibility,
      pricing_visibility: form.pricing_visibility,
      deadline: form.deadline,
      description: form.description,
      allow_taxes: form.allow_taxes,
      items: itemList
    };

    if (form.pricing_visibility === PRICING_VISIBILITY.openView) {
      tehData.pricing_type = form.pricing_type;
    }

    if (tenderDetail?.status === TENDER_STATUSES.draft) {
      tehData.is_draft = false;
      tehData.is_finalized = false;
      tehData.is_withdrawn = false;
    }

    try {
      setServerErrors({});
      if (isEdit && !isRecreate) {
        const { error } = await updateTender({
          id: tenderDetail?.id,
          body: tehData
        });
        if (error && error.data.errors.length > 0) {
          setServerErrors(error.data.errors[0]);
          return;
        }
        const delList = [];
        if (attachmentsDeleteList) {
          for (let i = 0; i < attachmentsDeleteList.length; i++) {
            delList.push(
              deleteAttachment({
                tenderId: tenderDetail.id,
                id: attachmentsDeleteList[i].id
              })
            );
          }
          await Promise.all(delList);
          setAttachmentsDeleteList([]);
        }
        const newAttachmentsList = attachmentsList.filter((item) => !item.id);
        await uploadAttachments(
          uploadAttachment,
          newAttachmentsList,
          tenderDetail.id
        );
        if (tenderDetail.status === TENDER_STATUSES.draft) {
          navigate(`${APP_ROUTE.EMPLOYER_TENDER_DETAIL}/${tenderDetail.id}`);
          return;
        }
        navigate(-1);
      } else {
        const { data, error } = await createTender(tehData);
        if (error && error.data.errors.length > 0) {
          setServerErrors(error.data.errors[0]);
          return;
        }
        await uploadAttachments(
          uploadAttachment,
          attachmentsList,
          data.data.id
        );
        navigate(APP_ROUTE.EMPLOYER_TENDER_INVITE, {
          state: { tenderDetail: data.data }
        });
      }
    } catch (err) {
      navigate(1);
      console.error(err);
    }
  };

  const createDraftTenderHandler = async (e, openModalFn) => {
    e.preventDefault();

    const filteredItems = itemList.filter(
      (i) => i.name || i.description || i.quantity || i.unit
    );

    const tehData = {
      owner: user.id,
      title: form.title,
      visibility: form.visibility,
      pricing_visibility: form.pricing_visibility,
      ...(form.deadline && { deadline: form.deadline }),
      description: form.description,
      allow_taxes: form.allow_taxes,
      items: filteredItems
    };

    if (form.pricing_visibility === PRICING_VISIBILITY.openView) {
      tehData.pricing_type = form.pricing_type;
    }

    try {
      setServerErrors({});
      if (isEdit) {
        const { error } = await updateDraftTender({
          id: tenderDetail?.id,
          body: tehData
        });
        if (error && error.data.errors.length > 0) {
          setServerErrors(error.data.errors[0]);
          return;
        }
        const delList = [];
        if (attachmentsDeleteList) {
          for (let i = 0; i < attachmentsDeleteList.length; i++) {
            delList.push(
              deleteAttachment({
                tenderId: tenderDetail.id,
                id: attachmentsDeleteList[i].id
              })
            );
          }
          await Promise.all(delList);
          setAttachmentsDeleteList([]);
        }
        const newAttachmentsList = attachmentsList.filter((item) => !item.id);
        await uploadAttachments(
          uploadAttachment,
          newAttachmentsList,
          tenderDetail.id
        );
        openModalFn();
      } else {
        const { data, error } = await createDraftTender(tehData);
        if (error && error.data.errors.length > 0) {
          setServerErrors(error.data.errors[0]);
          return;
        }
        await uploadAttachments(
          uploadAttachment,
          attachmentsList,
          data.data.id
        );
        openModalFn();
      }
    } catch (err) {
      navigate(1);
      console.error(err);
    }
  };

  const addItemHandler = () => {
    setItemList([...itemList, itemInitialState]);
  };

  const updateItemHandler = ({ id, value }) => {
    const tempItemList = cloneDeep(itemList);
    const index = tempItemList.findIndex((item) => item.id === id);
    tempItemList[index] = { ...tempItemList[index], ...value };
    setItemList(tempItemList);
  };

  const deleteItemHandler = (id) => {
    setItemList(itemList.filter((item) => item.id !== id));
  };

  const stepPlus = () => setStep(step + 1);
  const stepMinus = () => setStep(step - 1);

  return (
    <div className="px-3 px-lg-0">
      {step === 0 && (
        <TenderNew
          form={form}
          itemList={itemList}
          onFormUpdateHandler={formUpdateHandler}
          onAddItemHandler={addItemHandler}
          onUpdateItemHandler={updateItemHandler}
          onDeleteItemHandler={deleteItemHandler}
          stepPlus={stepPlus}
          isEdit={isEdit}
          onSubmitHandler={submitHandler}
          attachmentsList={attachmentsList}
          onAddAttachment={addAttachmentHandler}
          onDeleteAttachment={deleteAttachmentHandler}
          isLoading={isLoading}
          isDraftLoading={isDraftLoading}
          createDraftTender={createDraftTenderHandler}
          serverErrors={serverErrors}
        />
      )}
      {step === 1 && (
        <TenderNew2
          form={form}
          itemList={itemList}
          stepMinus={stepMinus}
          onSubmitHandler={submitHandler}
          isLoadingPostTenders={isCreateTenderLoading || isUpdateTenderLoading}
          attachmentsList={attachmentsList}
          user={user}
        />
      )}
    </div>
  );
};

export default TenderStepsBuilder;
