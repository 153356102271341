import { createApi } from "@reduxjs/toolkit/query/react";

import { baseQueryWithReauth } from "./reAuth";
import { BLOG_API, API_METHODS } from "../../utils/constants";

export const blogApi = createApi({
  reducerPath: "blogApi",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    fetchPosts: builder.mutation({
      query: ({ page }) => ({
        url: `${BLOG_API.BLOG}/?page=${page}`,
        method: API_METHODS.get
      })
    }),
    fetchPostsFeatured: builder.mutation({
      query: () => ({
        url: `${BLOG_API.BLOG}/?is_featured=true`,
        method: API_METHODS.get
      })
    }),
    getPost: builder.mutation({
      query: (slug) => ({
        url: `${BLOG_API.BLOG}/${slug}/`,
        method: API_METHODS.get
      })
    })
  })
});

export const { useFetchPostsMutation, useGetPostMutation, useFetchPostsFeaturedMutation } = blogApi;
