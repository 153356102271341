import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Button } from "react-bootstrap";

import { Formik } from "formik";
import * as yup from "yup";

import { emailVerification } from "../../utils/yupHelper";
import { IconError, IconSuccess } from "../../utils/svgHelper";
import LayoutLogin from "../../layout/LayoutLogin";
import { usePasswordResetMutation } from "../../redux/services/authApi";
import { Loader } from "../../components";
import { APP_ROUTE } from "../../utils/constants";

export default function ResetPassword() {
  const navigate = useNavigate();
  const [success, setSuccess] = useState(false);
  const [passwordReset, { isLoading: idLoadingPasswordReset }] =
    usePasswordResetMutation();

  const schema = yup.object().shape({
    email: emailVerification
  });

  const handleSubmit = useCallback(
    async (values, { setStatus, setErrors }) => {
      const tehData = {
        email: values.email
      };
      try {
        const { error } = await passwordReset({ body: tehData });
        if (error && error.data.errors.length !== 0) {
          setErrors(error?.data.errors[0]);
          return;
        }
        // navigate("/");
        setSuccess(true);
      } catch (err) {
        console.error(err);
        setStatus(err.message);
      }
    },
    [navigate]
  );

  return (
    <>
      <LayoutLogin>
        {!success && (
          <Formik
            validateOnChange
            initialValues={{
              email: "",
              password: ""
            }}
            validationSchema={schema}
            onSubmit={handleSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              isValid,
              handleSubmit,
              dirty
            }) => (
              <Form className="form" onSubmit={handleSubmit}>
                <div className="text-center pb-4">
                  <h1 className="h2 fw-bolder mb-4 mx-xl-n3 mt-xl-n3">
                    Forgot your password?
                  </h1>

                  <div className="form-label fw-normal lh-base mb-4 ms-xl-n3 me-xl-n3">
                    Don’t worry! Just fill in your email and we&lsquo;ll send
                    you a link to reset your password.
                  </div>
                </div>

                <div className="mb-5">
                  <div className="mb-4">
                    <div className="position-relative">
                      <Form.Group className="position-relative">
                        <div className="position-relative">
                          <Form.Control
                            type="text"
                            name="email"
                            tabIndex="1"
                            className={`${touched.email ? "is-touch " : ""} ${
                              errors.email && touched.email ? " is-invalid" : ""
                            } ${
                              !errors.email && touched.email ? " is-valid" : ""
                            }`}
                            value={values.email}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            placeholder="Email"
                          />

                          {errors.email && touched.email && <IconError />}

                          {!errors.email && touched.email && <IconSuccess />}
                        </div>
                        {errors.email && (
                          <Form.Control.Feedback
                            type="invalid"
                            className="position-absolute start-0 top-100"
                          >
                            {errors.email}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                    </div>
                  </div>
                </div>

                <div className="text-center">
                  <Button
                    variant="primary"
                    type="submit"
                    className="w-100"
                    disabled={!(isValid && dirty)}
                  >
                    Reset {idLoadingPasswordReset && <Loader />}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        )}

        {success && (
          <>
            <div className="text-center pb-4">
              <h1 className="h2 fw-bolder mb-4 mx-xl-n3 mt-xl-n3">
                Reset password
              </h1>

              <div className="form-label fw-normal lh-base mb-4 ms-xl-n3 me-xl-n3">
                We send you an email including a link. <br />
                Please click the link to continue resetting your password. Thank
                you!
              </div>
            </div>
            <br />
            <br />

            <Button
              variant="primary"
              type="submit"
              className="w-100"
              onClick={() => navigate(APP_ROUTE.LOGIN)}
            >
              Login
            </Button>
          </>
        )}
      </LayoutLogin>
    </>
  );
}
