import { Row, Col } from "react-bootstrap";
import LayoutHome from "../../layout/LayoutHome";
import BlogSlider from "./BlogSlider";
import BlogList from "./BlogList";

export default function Blog() {
  return (
    <>
      <LayoutHome>
        <div className="blog">
          <div className="bg-intro">
            <Row className="justify-content-center">
              <Col xs={11}>
                <BlogSlider />
              </Col>
            </Row>
          </div>

          <div className="bg-light">
            <Row className="justify-content-center">
              <Col xs={11}>
                <BlogList />
              </Col>
            </Row>
          </div>
        </div>
      </LayoutHome>
    </>
  );
}
