import * as yup from "yup";

import { EMAIL_REGEXP } from "./constants";

export const emailVerification = yup
  .string()
  .trim()
  .required("Email is a required field")
  .email("Email must be a valid email")
  .matches(EMAIL_REGEXP, "Email must be a valid email");

export const messageVerification = yup
  .string()
  .required("Message is a required field");

export const nameVerification = yup
  .string()
  .required("Name is a required field");

export const phoneNumberVerification = yup
  .string()
  .required("Mobile phone number is a required field");
