import { Form, Row, Col } from "react-bootstrap";

import { IconError, IconSuccess } from "../../../../utils/svgHelper";

export default function TenderDescription({
  touched,
  errors,
  values,
  handleBlur,
  handleChange,
  onFormUpdateHandler
}) {
  return (
    <Row className="justify-content-center align-items-start g-2 ">
      <Col sm={4} lg={5} className="text-start mb-2 mb-sm-0">
        <Form.Label className="fs-6 lh-base col-form-label py-0">
          Description:
        </Form.Label>
      </Col>
      <Col sm={8} lg={7}>
        <div className="position-relative">
          <div className="position-relative">
            <div className="position-relative" tabIndex="5">
              <Form.Control
                as="textarea"
                rows={4}
                name="description"
                className={`${touched.description ? "is-touch " : ""} ${
                  errors.description && touched.description ? " is-invalid" : ""
                } ${
                  !errors.description && touched.description ? " is-valid" : ""
                }`}
                value={values.description}
                onBlur={handleBlur}
                onChange={(e) => {
                  handleChange(e);
                  onFormUpdateHandler({
                    [e.target.name]: e.target.value
                  });
                }}
              />

              {errors.description && touched.description && <IconError />}

              {!errors.description && touched.description && <IconSuccess />}
            </div>
            {errors.description && (
              <Form.Control.Feedback type="invalid">
                {errors.description}
              </Form.Control.Feedback>
            )}
          </div>
        </div>
      </Col>
    </Row>
  );
}
