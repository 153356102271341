import { Container, Row, Col } from "react-bootstrap";

export default function LayoutFull({ children }) {
  return (
    <>
      <div className="project py-4 px-2 p-lg-6 justify-content-start">
        <main className="page-content">
          <Container>
            <Row className="justify-content-center align-items-center">
              <Col lg={11}>
                <div className="md bg-light shadow-lg rounded-3 mx-auto py-4 px-2 p-lg-6">
                  <div className="text-center">
                    <Row className="justify-content-center">
                      <Col lg={9}>
                        <div className="sm sm-x mx-auto">
                          {children}
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </main>
      </div>
    </>
  );
}
