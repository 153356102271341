import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Button } from "react-bootstrap";
import { APP_ROUTE } from "../../utils/constants";

import LayoutHome from "../../layout/LayoutHome";
import { ModalVideo } from "../../components";

import img1 from "../../assets/images/home/img-1.png";
import icon1 from "../../assets/images/home/icon-1.svg";
import icon2 from "../../assets/images/home/icon-2.svg";
import icon3 from "../../assets/images/home/icon-3.svg";
import img2 from "../../assets/images/home/img-2.png";
import img3 from "../../assets/images/home/img-3.png";
import screen1 from "../../assets/images/home/screen-1.png";
import screen2 from "../../assets/images/home/screen-2.png";
import screen3 from "../../assets/images/home/screen-3.png";
import IconItem from "../../components/IconItem";

export default function Home() {
  const [showVideo, setShowVideo] = useState(false);

  const handleCloseVideo = () => setShowVideo(false);
  const handleShowVideo = () => setShowVideo(true);

  const navigate = useNavigate();

  useEffect(() => {
    document.documentElement.classList.add("home");

    return () => {
      document.documentElement.classList.remove("home");
    };
  }, []);

  return (
    <>
      <ModalVideo show={showVideo} onHide={handleCloseVideo} />

      <LayoutHome>
        <section className="px-3 px-lg-7 section-1 bg-intro">
          <Row className="align-items-center">
            <Col md={6} lg={5}>
              <div className="mb-3 mb-sm-3 pb-sm-3 mb-lg-4 pb-lg-3 mt-lg-7">
                <h1 className="display-1 fw-bolder mb-1">ZoomTender</h1>
                <p className="fw-600 fs-4 mb-0">
                  is an End-to-End E-Tender Management Platform
                </p>
              </div>
              <Button
                variant="outline-primary"
                className="bw-2 px-4"
                onClick={handleShowVideo}
              >
                <IconItem icon="play" />
                <span className="ms-2">Watch Video</span>
              </Button>
            </Col>

            <Col md={6} lg={7}>
              <img src={img1} alt="" className="img-fluid" />
            </Col>
          </Row>
        </section>

        <section className="px-3 px-lg-7 section-2 position-relative">
          <Row className="g-5 align-items-center">
            <Col sm={4}>
              <div className="bg-white shadow rounded-3 p-3 d-flex align-items-center justify-content-center">
                <img src={icon1} alt="" className="icon-size" />
                <span className="fw-600 ms-2 fs-5-3">Fast</span>
              </div>
            </Col>
            <Col sm={4}>
              <div className="bg-white shadow rounded-3 p-3 d-flex align-items-center justify-content-center">
                <img src={icon2} alt="" className="icon-size" />
                <span className="fw-600 ms-2 fs-5-3">Paperless</span>
              </div>
            </Col>
            <Col sm={4}>
              <div className="bg-white shadow rounded-3 p-3 d-flex align-items-center justify-content-center">
                <img src={icon3} alt="" className="icon-size" />
                <span className="fw-600 ms-2 fs-5-3">Eliminate Fraud</span>
              </div>
            </Col>
          </Row>
        </section>

        <section className="px-3 px-lg-7 section-3 bg-light">
          <Row className="align-items-center justify-content-lg-end">
            <Col md={6} lg={5} className="pb-lg-2 ps-lg-32">
              <div className="mb-3 mb-lg-5">
                <h2 className="display-2 fw-bolder mb-2 mb-lg-3">
                  Are you an Employer?
                </h2>
                <p className="fs-4-2 fw-500 mb-0">
                  User ZoomTender to <br />
                  invite e-tenders and get <br /> the best rates!
                </p>
              </div>
              <Button
                variant="primary"
                className="bw-2"
                onClick={() => navigate(APP_ROUTE.SIGNUP)}
              >
                I&apos;m an Employer
              </Button>
            </Col>

            <Col md={6} lg={6}>
              <img src={img2} alt="" className="img-fluid" />
            </Col>
          </Row>
        </section>

        <section className="px-3 px-lg-7 section-4">
          <Row className="align-items-center flex-sm-row-reverse g-2">
            <Col md={6} lg={5}>
              <div className="mb-3 mb-lg-5">
                <h2 className="display-2 fw-bolder mb-2 mb-lg-3">
                  Are you a Contractor?
                </h2>
                <p className="fs-4-2 fw-500 mb-0">
                  Find new tenders to bid on!
                </p>
              </div>
              <Button
                variant="primary"
                className="bw-2"
                onClick={() => navigate(APP_ROUTE.SIGNUP)}
              >
                I&apos;m a Contractor
              </Button>
            </Col>

            <Col md={6} lg={7}>
              <img src={img3} alt="" className="img-fluid d-block ms-lg-auto" />
            </Col>
          </Row>
        </section>

        <section id="steps" className="px-3 px-lg-7 section-step text-center">
          <h2 className="display-2 fw-bolder mb-2 mb-lg-3">How it works</h2>

          <Row className="g-3">
            <Col lg={4} className="mb-3 step position-relative">
              <div className="d-flex d-lg-block align-items-center justify-content-start">
                <div className="step-num">1</div>
                <div className="step-body">
                  <div className="step-thumb rounded-3">
                    <img src={screen1} alt="" />
                  </div>
                  <p className="m-0">Employer Creates Tender</p>
                </div>
              </div>
            </Col>

            <Col lg={4} className="mb-3 step position-relative">
              <div className="d-flex d-lg-block align-items-center justify-content-start">
                <div className="step-num">2</div>
                <div className="step-body">
                  <div className="step-thumb rounded-3">
                    <img src={screen2} alt="" />
                  </div>
                  <p className="m-0">Contractors Bid on Tender</p>
                </div>
              </div>
            </Col>

            <Col lg={4} className="mb-3 step position-relative">
              <div className="d-flex d-lg-block align-items-center justify-content-start">
                <div className="step-num">3</div>
                <div className="step-body">
                  <div className="step-thumb rounded-3">
                    <img src={screen3} alt="" />
                  </div>
                  <p className="m-0">
                    Employer Compares Bids and Awards Tender to the Contractor
                    of Their Choice
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </section>
      </LayoutHome>
    </>
  );
}
