import { useState, useRef } from "react";
import { Modal, Button, Row, Col, Form, Table } from "react-bootstrap";
import { ToWords } from "to-words";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

import { Loader } from "../components";
import logo from "../assets/images/logo.svg";
import { toFloat, getDateStr } from "../utils/helpersFunctions";

export default function PoWoCreateModal({
  show,
  onHide,
  form,
  items,
  totalPrice,
  grandTotal,
  totalTaxPrice,
  onSave,
  isOrderCreating
}) {
  const toWords = new ToWords({ localeCode: "en-US" });

  const [loading, setLoading] = useState(false);
  const ref = useRef();

  const createDocument = async () => {
    setLoading(true);
    const input = ref.current;
    const canvas = await html2canvas(input);
    const imgData = canvas.toDataURL("image/png");
    const imgWidth = 190;
    const pageHeight = 290;
    const imgHeight = (canvas.height * imgWidth) / canvas.width;
    let heightLeft = imgHeight;
    const pdf = new jsPDF({
      orientation: "p",
      unit: "mm",
      format: "a4",
      compress: true
    });
    let position = 0;
    pdf.addImage(imgData, "PNG", 10, 0, imgWidth, imgHeight + 25);
    heightLeft -= pageHeight;
    while (heightLeft >= 0) {
      position = heightLeft - imgHeight;
      pdf.addPage();
      pdf.addImage(imgData, "PNG", 10, position, imgWidth, imgHeight + 25);
      heightLeft -= pageHeight;
    }

    return pdf;
  };
  const dwDocument = async () => {
    const pdf = await createDocument();
    pdf.save(`${form.type}.pdf`);
    setLoading(false);
  };

  return (
    <>
      <Modal show={show} onHide={onHide} size="lg" className="outside">
        <Modal.Footer className="px-0 pt-0 pb-20 position-absolute end-0">
          <Button variant="light" onClick={onHide} className="px-30 ms-3">
            Back
          </Button>
          <Button
            variant="primary"
            onClick={async () => {
              const pdf = await createDocument();
              const arrayBuffer = await pdf.output("arraybuffer");
              const base64String = btoa(
                new Uint8Array(arrayBuffer).reduce(
                  (data, byte) => data + String.fromCharCode(byte),
                  ""
                )
              );
              onSave(`data:application/pdf;base64,${base64String}`);
            }}
            className="px-30 ms-3"
            hidden={!onSave}
          >
            Save {(loading || isOrderCreating) && <Loader />}
          </Button>

          <Button onClick={dwDocument}>DW</Button>
        </Modal.Footer>
        <div ref={ref}>
          <Modal.Header className="d-block d-md-flex flex-md-row-reverse pt-30 pb-0">
            <div className="logo logo-xs d-block mb-3 mb-md-0">
              <img
                src={form?.logo_file ? form?.logo_file : logo}
                alt="ZoomTender"
                className="img-fluid d-block mx-auto"
              />
            </div>
            <h1 className="h1 fw-bolder mb-0">{form.type}</h1>
          </Modal.Header>

          <Modal.Body>
            <ul className="list-unstyled mb-36 lh-lg">
              {form.number && (
                <li className="fw-500">
                  <strong className="fw-600">Order#</strong> {form.number}
                </li>
              )}
              <li className="fw-500">
                <strong className="fw-600">Invoice Date:</strong>{" "}
                {form.created_at
                  ? getDateStr(form.created_at)
                  : getDateStr(new Date().toISOString())}
              </li>
              <li className="fw-500">
                <strong className="fw-600">Due Date:</strong>{" "}
                {getDateStr(form.deadline)}
              </li>
            </ul>

            <Row className="mb-4">
              <Col sm={6} className="mb-3">
                <div className="py-12 px-30 bg-light-50 rounded-3 h-100">
                  <h5 className="h5 fw-600">Order By</h5>
                  <ul className="list-unstyled mb-0">
                    {/* <li className="mb-10">
                      <small className="d-block">Foobar Labs</small>
                    </li> */}

                    <li className="mb-10">
                      <small className="d-block">{form.order_by}</small>
                    </li>

                    {/* <li className="mb-10">
                      <small className="d-block">GSTIN 29ABCED1234F2Z5</small>
                    </li>

                    <li className="mb-10">
                      <small className="d-block">PAN ABCED1234F</small>
                    </li> */}
                  </ul>
                </div>
              </Col>
              <Col sm={6} className="mb-3">
                <div className="py-12 px-30 bg-light-50 rounded-3 h-100">
                  <h5 className="h5 fw-600">Order To</h5>
                  <ul className="list-unstyled mb-0">
                    {/* <li className="mb-10">
                      <small className="d-block">Foobar Labs</small>
                    </li> */}

                    <li className="mb-10">
                      <small className="d-block">{form.order_to}</small>
                    </li>

                    {/* <li className="mb-10">
                      <small className="d-block">GSTIN 29ABCED1234F2Z5</small>
                    </li>

                    <li className="mb-10">
                      <small className="d-block">PAN ABCED1234F</small>
                    </li> */}
                  </ul>
                </div>
              </Col>
            </Row>

            <div className="table-rounded mb-30 shadow bg-white rounded-1 table-layout-auto min-width-auto">
              <Table striped className="mb-0">
                <thead>
                  <tr>
                    <th style={{ width: "45px" }} className="text-center">
                      <div>S No</div>
                    </th>
                    <th style={{ width: "130px" }}>
                      <div>Item Name</div>
                    </th>
                    {/* <th style={{ width: "130px" }}>
                      <div>Description</div>
                    </th> */}
                    <th style={{ width: "60px" }}>
                      <div>Qty</div>
                    </th>
                    <th style={{ width: "80px" }}>
                      <div>Unit</div>
                    </th>
                    {/* <th style={{ width: "120px" }}>
                      <div>Item Details</div>
                    </th> */}
                    <th style={{ width: "60px" }}>
                      <div>Unit Price</div>
                    </th>
                    <th style={{ width: "80px" }}>
                      <div>Sub Total</div>
                    </th>
                    <th style={{ width: "60px" }}>
                      <div>Tax Rate</div>
                    </th>
                    <th style={{ width: "65px" }}>
                      <div>Tax Type</div>
                    </th>
                    <th style={{ width: "80px" }}>
                      <div>Total Type</div>
                    </th>
                    <th style={{ width: "85px" }}>
                      <div>Total</div>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {items &&
                    items.map((item, index) => (
                      <ItemRow
                        key={`PoWoCreateModal${item.id}`}
                        item={item}
                        index={index}
                      />
                    ))}
                </tbody>

                <tfoot className="border-top">
                  <tr>
                    <td colSpan="5" style={{ width: "530px" }}>
                      <div className="fw-600">Grand Total</div>
                    </td>
                    <td
                      style={{ width: "115px" }}
                      className="border-start border-end"
                    >
                      {totalPrice}
                    </td>
                    <td colSpan="2">&nbsp;</td>
                    <td style={{ width: "125px" }} className="border-start">
                      {totalTaxPrice}
                    </td>
                    <td style={{ width: "125px" }} className="border-start">
                      {grandTotal}
                    </td>
                  </tr>
                </tfoot>
              </Table>
            </div>

            <Row className="mb-4 justify-content-between">
              <Col sm={6} md={5} className="mb-3">
                <h5 className="h5 fw-600">Terms and Conditions</h5>
                <ul className="list-unstyled mb-0">
                  <li className="mb-10">
                    <small className="d-block">{form.terms}</small>
                  </li>

                  {/* <li className="mb-10">
                    <small className="d-block">
                      2. Please quote Invoice number when remitting funds.
                    </small>
                  </li> */}
                </ul>
              </Col>
              <Col sm={6} md={5} className="mb-3">
                <table className="w-100">
                  <tbody>
                    <tr>
                      <td className="py-1">
                        <div className="fw-600">Sub Total</div>
                      </td>
                      <td className="py-1 text-end">
                        <small>{totalPrice}</small>
                      </td>
                    </tr>
                    <tr hidden>
                      <td className="py-1">
                        <div className="fw-600 text-primary">Discount(10%)</div>
                      </td>
                      <td className="py-1 text-end">
                        <small className="text-primary">44x500</small>
                      </td>
                    </tr>
                    <tr>
                      <td className="py-1">
                        <div className="fw-600">Taxable Amount</div>
                      </td>
                      <td className="py-1 text-end">
                        <small>{totalTaxPrice}</small>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot className="border-top">
                    <tr>
                      <td className="py-10">
                        <div className="fw-600">Total</div>
                      </td>
                      <td className="py-10 text-end">
                        <span>{grandTotal}</span>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </Col>
            </Row>

            <Row className="mb-4 justify-content-between">
              <Col sm={12}>
                <h5 className="h5 fw-600">Additional Notes</h5>
              </Col>
              <Col sm={6} md={5} className="mb-3">
                <p className="mb-10">
                  <small>{form.notes}</small>
                </p>
              </Col>
              <Col sm={6} md={5} className="mb-3">
                <p className="mb-10">
                  <small>Invoice Total (in words)</small>
                </p>
                <p className="mb-10 fw-600">
                  {grandTotal ? toWords.convert(grandTotal) : ""}
                </p>

                <Form.Group
                  className="mb-10 mt-4"
                  controlId="formBasicEmail"
                  style={{ width: "148px" }}
                >
                  {form?.signature_file ? (
                    <img
                      src={form?.signature_file}
                      alt="ZoomTender"
                      className="img-fluid d-block mx-auto"
                    />
                  ) : (
                    <Form.Control type="text" placeholder="" readOnly />
                  )}

                  <Form.Text className="text-secondary">
                    Authorized Signature
                  </Form.Text>
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
}

function ItemRow({ item, index }) {
  return (
    <tr>
      <td style={{ width: "45px" }} className="text-center">
        {index + 1}
      </td>
      <td style={{ width: "130px" }}>{item.name}</td>
      {/* <td style={{ width: "130px" }}>{item.description}</td> */}
      <td style={{ width: "60px" }}>{item.quantity}</td>
      <td style={{ width: "80px" }}>{item.unit}</td>
      {/* <td style={{ width: "120px" }}>{item?.details}</td> */}
      <td style={{ width: "60px" }}>{item?.price}</td>
      <td style={{ width: "80px" }} className="border-start border-end">
        {toFloat(item?.quantity * item?.price)}
      </td>
      <td style={{ width: "60px" }}>
        {item?.taxes &&
          item.taxes.map((t) => (
            <div className="mb-1" key={`PoWoCreateModal${t.id}`}>
              {t.tax_rate}%
            </div>
          ))}
      </td>
      <td style={{ width: "65px" }}>
        {item?.taxes &&
          item.taxes.map((t) => (
            <div className="mb-1" key={`PoWoCreateModal${t.id}`}>
              {t.tax.name}
            </div>
          ))}
      </td>
      <td style={{ width: "80px" }} className="border-start">
        {item?.taxes &&
          item.taxes.map((t) => (
            <div className="mb-1" key={`PoWoCreateModal${t.id}`}>
              {t.tax_amount}
            </div>
          ))}
      </td>
      <td style={{ width: "85px" }} className="border-start">
        {item.total_price}
      </td>
    </tr>
  );
}
