import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Table } from "react-bootstrap";
import { useDispatch } from "react-redux";

import { setAllTenders } from "../../redux/slices/TenderSlice";
import { useGetBidsTendersMutation } from "../../redux/services/tenderApi";
import { transformationTime } from "../../utils/helpersFunctions";
import {
  Loader,
  Pagination,
  VisibilityDropdown,
  StatusDropdown,
  PricingVisibilityDropdown
} from "../../components";
import { APP_ROUTE } from "../../utils/constants";
import SortableCell from "../../components/SortableCell";

export default function Bids() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [getBidsTenders, { isLoading }] = useGetBidsTendersMutation();
  const [tenderBidsList, setTenderBidsList] = useState([]);
  const [pricingVisibility, setPricingVisibility] = useState("");
  const [visibility, setVisibility] = useState("");
  const [status, setStatus] = useState("");
  const [sortBy, setSortBy] = useState("");

  const [countPage, setCountPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  const sortByHandler = (param) => {
    setSortBy(
      !sortBy.includes(param) ? param : sortBy === param ? `-${param}` : ""
    );
  };

  useEffect(async () => {
    const visibilityStr = visibility ? `&visibility=${visibility}` : "";
    const pricingVisibilityStr = pricingVisibility
      ? `&pricing_visibility=${pricingVisibility}`
      : "";
    const statusStr = status ? `&status=${status}` : "";
    const sortByStr = sortBy ? `&o=${sortBy}` : "";

    const { data } = await getBidsTenders({
      page: currentPage,
      visibility: visibilityStr,
      pricingVisibility: pricingVisibilityStr,
      status: statusStr,
      deadline: "",
      o: sortByStr
    });
    dispatch(setAllTenders(data.data));
    setCountPage(data.data.count || 1);
    setTenderBidsList(data.data.results);
  }, [currentPage, visibility, pricingVisibility, status, sortBy]);

  const visibilityChange = (value) => {
    setCurrentPage(1);
    setVisibility(value);
  };
  const pricingVisibilityChange = (value) => {
    setCurrentPage(1);
    setPricingVisibility(value);
  };
  const statusChange = (value) => {
    setCurrentPage(1);
    setStatus(value);
  };

  return (
    <>
      <h1 className="h1 fw-bolder mb-0">My Bids</h1>
      <h6 className="fsz-18 mb-20">List of Active Tenders you have bid on</h6>

      <div className="mb-3 mx-n2 mx-lg-n4">
        <Table striped responsive>
          <thead>
            <tr>
              <SortableCell
                name="Tender"
                sortBy={sortByHandler}
                title="Sort by title"
                className="ps-2 ps-lg-4 tender-column"
              />
              <th className="visibility-column">
                <VisibilityDropdown
                  visibility={visibility}
                  onChange={visibilityChange}
                />
              </th>
              <th className="view-column">
                <PricingVisibilityDropdown
                  pv={pricingVisibility}
                  onChange={pricingVisibilityChange}
                />
              </th>
              <SortableCell
                name="Posted"
                sortBy={sortByHandler}
                title="Sort by date created"
                className="date-column"
              />
              <SortableCell
                name="Deadline"
                sortBy={sortByHandler}
                title="Sort by deadline"
                className="date-column"
              />
              <th>
                <StatusDropdown status={status} onChange={statusChange} />
              </th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan="6">
                  <Loader />
                </td>
              </tr>
            ) : tenderBidsList.length === 0 ? (
              <tr>
                <td colSpan="6">
                  <div className="px-2 px-lg-4">
                    <h6 className="my-10 fw-normal">Nothing Found</h6>
                  </div>
                </td>
              </tr>
            ) : (
              tenderBidsList?.map((tender) => (
                <BidTenderRow
                  key={tender.id}
                  tender={tender}
                  navigate={navigate}
                />
              ))
            )}
          </tbody>
        </Table>
      </div>

      {(tenderBidsList.length > 9 || currentPage !== 1) && (
        <Pagination
          itemsCount={countPage}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      )}
    </>
  );
}

function BidTenderRow({ tender, navigate }) {
  return (
    <tr
      className="cursor-pointer"
      onClick={() =>
        navigate(`${APP_ROUTE.CONTRACTOR_BID_SUBMIT}/${tender.id}`)
      }
    >
      <td className="ps-2 ps-lg-4 tender-column">
        <div>{tender.title}</div>
      </td>
      <td className="visibility-column">
        <div>{tender.visibility}</div>
      </td>
      <td className="view-column">
        <div>{tender.pricing_visibility}</div>
      </td>
      <td className="date-column">
        <div>{transformationTime(tender.created_at)}</div>
      </td>
      <td className="date-column">
        <div>{transformationTime(tender.deadline)}</div>
      </td>
      <td>
        <div>{tender.status}</div>
      </td>
    </tr>
  );
}
