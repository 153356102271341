import { useMemo } from "react";
import { Row, Col } from "react-bootstrap";

const LowestPossibleTotal = ({
  selectedBids,
  selectedItems,
  minBidsList,
  tehItems
}) => {
  const getMinPriceAndOwnerStr = (obj = {}) =>
    `${obj?.price} (${obj?.owner?.full_name})`;

  const minTotal = useMemo(() => {
    return minBidsList
      .map(
        (minBid) =>
          minBid.price * tehItems.find((i) => i.id === minBid.item).quantity
      )
      .reduce((accumulator, a) => accumulator + a, 0);
  }, [minBidsList]);

  return (
    <div className="pt-3">
      <Row className="align-items-center">
        <Col md={3} lg={4} className="text-lg-end">
          <div className="fs-6 lh-base form-label">
            Lowest <br />
            Possible Total
          </div>
        </Col>
        {selectedBids.length > 0 && (
          <Col md={9} lg="auto">
            <ul className="list-unstyled mb-0 ps-xl-4 small">
              {selectedItems.map((i, index, { length }) => {
                const priceObj = minBidsList.find(
                  (minBid) => minBid?.item === i.id
                );

                const priceStr = getMinPriceAndOwnerStr(priceObj);
                return (
                  <>
                    {!!priceObj?.price && priceObj.price > 0 && (
                      <li key={`li${i.id}`}>
                        <small>{`${i.quantity}x${priceStr}`}</small>
                      </li>
                    )}
                    {index + 1 === length && (
                      <li>
                        <small>{`= ${minTotal}`}</small>
                      </li>
                    )}
                  </>
                );
              })}
            </ul>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default LowestPossibleTotal;
