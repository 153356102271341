import { Modal, Button } from "react-bootstrap";

import { TableComparison } from "../components";

export default function ModalTableComparison({
  show,
  onHide,
  tehBids,
  items,
  allowTaxes,
  isCompact
}) {
  return (
    <Modal show={show} onHide={onHide} size="xxl" className="outside">
      <Modal.Footer className="px-0 pt-0 pb-20 position-absolute end-0 top-n20">
        <Button variant="" className="btn-close" onClick={onHide}></Button>
      </Modal.Footer>
      <Modal.Body className="p-0">
        <TableComparison
          tehBids={tehBids}
          items={items}
          allowTaxes={allowTaxes}
          isCompact={isCompact}
        />
      </Modal.Body>
    </Modal>
  );
}
