import { Table, Form, Dropdown } from "react-bootstrap";
import cloneDeep from "lodash.clonedeep";

import { toFloat } from "../utils/helpersFunctions";
import IconItem from "../components/IconItem";

export default function PoWoCreateTable({
  items,
  onItemsUpdate,
  taxes,
  totalPrice,
  totalTaxPrice,
  grandTotal
}) {
  const recalc = (item) => {
    const price = parseFloat(item.price);
    const quantity = parseFloat(item.quantity);
    const subtotal = price && quantity ? price * quantity : 0;
    const taxes = item?.taxes
      ? item.taxes.map((t) => ({
          ...t,
          tax_amount: toFloat(subtotal * (t.tax_rate / 100))
        }))
      : [];
    const totalTax = taxes.length
      ? taxes
          .map((t) => t.tax_amount)
          .reduce((prevValue, curValue) => prevValue + curValue, 0)
      : 0;

    return {
      ...item,
      price,
      quantity,
      taxes,
      total_price: toFloat(subtotal + totalTax)
    };
  };

  return (
    <>
      <div className="table-rounded mb-30 shadow bg-white rounded-1 ">
        <Table striped responsive className="mb-0 ">
          <thead>
            <tr>
              <th className="text-center">
                &nbsp;
              </th>
              <th className="text-center">
                <div>S No</div>
              </th>
              <th className="text-break">
                <div>Item Name</div>
              </th>
              <th className="text-break">
                <div>Description</div>
              </th>
              <th className="text-break">
                <div>Quantity</div>
              </th>
              <th className="text-break">
                <div>Unit</div>
              </th>
              <th className="text-break">
                <div>Item Details</div>
              </th>
              <th className="text-break">
                <div>Unit Price</div>
              </th>
              <th className="text-break">
                <div>Sub Total</div>
              </th>
              <th className="text-break">
                <div>Tax Rate</div>
              </th>
              <th className="text-break">
                <div>Tax Type</div>
              </th>
              <th className="text-break">
                <div>Total Type</div>
              </th>
              <th className="text-break">
                <div>Total</div>
              </th>
            </tr>
          </thead>

          <tbody>
            {items.map((item, index) => {
              return (
                <tr
                  key={`ordertableitem${item.id}`}
                  className={item.selected ? "" : "text-secondary"}
                >
                  <td
                    className="text-center border-end"
                  >
                    <Form.Check
                      inline
                      name="po_wo_logo"
                      type="checkbox"
                      checked={item.selected}
                      className="flex-grow-0 flex-shrink-0"
                      onChange={() => {
                        const changeItems = cloneDeep(items);
                        changeItems[index].selected =
                          !changeItems[index].selected;
                        onItemsUpdate(changeItems);
                      }}
                    />
                  </td>
                  <td className="text-center">
                    {index + 1}
                  </td>
                  <td>{item.name}</td>
                  <td>{item.description}</td>
                  <td>
                    <div className="position-relative">
                      <Form.Control
                        type="number"
                        className={
                          item.selected && !(item?.quantity >= 0)
                            ? "is-invalid"
                            : ""
                        }
                        value={item?.quantity}
                        onChange={(e) => {
                          const changeItems = cloneDeep(items);
                          changeItems[index] = {
                            ...changeItems[index],
                            quantity: e.target.value
                          };
                          changeItems[index] = recalc(changeItems[index]);
                          onItemsUpdate(changeItems);
                        }}
                        readOnly={!item.selected}
                        min="0"
                      />
                      {item.selected && !(item?.quantity >= 0) && (
                        <Form.Control.Feedback type="invalid" className="lh-1">
                          Required
                        </Form.Control.Feedback>
                      )}
                    </div>
                  </td>
                  <td>{item.unit}</td>
                  <td>{item?.details}</td>
                  <td>
                    <div className="position-relative">
                      <Form.Control
                        type="number"
                        className={
                          item.selected && !(item?.price >= 0)
                            ? "is-invalid"
                            : ""
                        }
                        value={item?.price}
                        onChange={(e) => {
                          const changeItems = cloneDeep(items);
                          changeItems[index] = {
                            ...changeItems[index],
                            price: e.target.value
                          };
                          changeItems[index] = recalc(changeItems[index]);
                          onItemsUpdate(changeItems);
                        }}
                        readOnly={!item.selected}
                        min="0"
                      />
                      {item.selected && !(item?.price >= 0) && (
                        <Form.Control.Feedback type="invalid" className="lh-1">
                          Required
                        </Form.Control.Feedback>
                      )}
                    </div>
                  </td>
                  <td className="border-start border-end"
                  >
                    {item?.quantity && item?.price
                      ? toFloat(item?.quantity * item?.price)
                      : ""}
                  </td>
                  <td>
                    <div className="position-relative">
                      <div className="h-26">&nbsp;</div>
                      {item?.taxes &&
                        item?.taxes.map((t, taxIndex) => (
                          <div
                            className="mb-1"
                            key={`ordertablechosentaxrate${t.id}`}
                          >
                            <Form.Control
                              type="number"
                              className={
                                item.selected && !(t.tax_rate >= 0)
                                  ? "is-invalid"
                                  : ""
                              }
                              value={t.tax_rate}
                              onChange={(e) => {
                                const changeItems = cloneDeep(items);
                                const rate = parseFloat(e.target.value);
                                changeItems[index].taxes[taxIndex] = {
                                  ...changeItems[index].taxes[taxIndex],
                                  tax_rate: rate,
                                  tax_amount: toFloat(
                                    item.quantity *
                                      item.price *
                                      ((isNaN(rate) ? 0 : rate) / 100)
                                  )
                                };
                                changeItems[index] = recalc(changeItems[index]);
                                onItemsUpdate(changeItems);
                              }}
                              readOnly={!item.selected}
                              min="0"
                            />
                            {item.selected && !(t.tax_rate >= 0) && (
                              <Form.Control.Feedback
                                type="invalid"
                                className="lh-1"
                              >
                                Required
                              </Form.Control.Feedback>
                            )}
                          </div>
                        ))}
                    </div>
                  </td>
                  <td>
                    <Dropdown className="dropdown-select">
                      <Dropdown.Toggle variant="link" id="dropdown-tax-type">
                        <span>Tax Type</span>
                        <IconItem icon="down" />
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {item?.taxes &&
                          taxes
                            .filter(
                              (tax) =>
                                !item.taxes
                                  .map((t) => t.tax.name)
                                  .includes(tax.name)
                            )
                            .map((tax) => (
                              <Dropdown.Item
                                as="li"
                                key={`ordertable${item.id}${tax.id}`}
                                onClick={() => {
                                  const changeItems = cloneDeep(items);
                                  changeItems[index].taxes = [
                                    ...changeItems[index].taxes,
                                    {
                                      id: Date.now(),
                                      tax,
                                      tax_rate: 0,
                                      tax_amount: 0
                                    }
                                  ];
                                  changeItems[index] = recalc(
                                    changeItems[index]
                                  );
                                  onItemsUpdate(changeItems);
                                }}
                              >
                                <Form.Check
                                  inline
                                  name="public"
                                  type="radio"
                                  label={tax.name}
                                />
                              </Dropdown.Item>
                            ))}
                      </Dropdown.Menu>
                      <ul className="list-unstyled mb-0">
                        {item?.taxes &&
                          item?.taxes.map((tax) => (
                            <li
                              key={`ordertablechosentax${tax.id}`}
                              className="mb-1 d-flex align-items-center justify-content-between"
                            >
                              <span>{tax.tax.name}</span>
                              <span
                                className="text-secondary"
                                onClick={() => {
                                  const changeItems = cloneDeep(items);
                                  changeItems[index].taxes = changeItems[
                                    index
                                  ].taxes.filter((t) => t.id !== tax.id);
                                  changeItems[index] = recalc(
                                    changeItems[index]
                                  );
                                  onItemsUpdate(changeItems);
                                }}>
                                <IconItem icon="close" />
                              </span>
                            </li>
                          ))}
                      </ul>
                    </Dropdown>
                  </td>
                  <td className="border-start">
                    <div className="h-26">&nbsp;</div>
                    {item?.taxes &&
                      item.taxes.map((t) => (
                        <div className="mb-1" key={`chosentaxamount${t.id}`}>
                          {t.tax_amount}
                        </div>
                      ))}
                  </td>
                  <td className="border-start">
                    {item?.total_price}
                  </td>
                </tr>
              );
            })}

            {/* <tr className="text-secondary">
              <td style={{ width: "55px" }} className="text-center border-end">
                <Form.Check
                  inline
                  name="po_wo_logo"
                  type="checkbox"
                  className="flex-grow-0 flex-shrink-0"
                />
              </td>
              <td style={{ width: "85px" }} className="text-center">
                2
              </td>
              <td style={{ width: "130px" }}>Item 2</td>
              <td style={{ width: "155px" }}>Desc 2</td>
              <td style={{ width: "125px" }}>
                <div className="position-relative">
                  <Form.Control type="number" />
                </div>
              </td>
              <td style={{ width: "115px" }}>bags</td>
              <td style={{ width: "145px" }}>Good Quality</td>
              <td style={{ width: "125px" }}>
                <div className="position-relative">
                  <Form.Control type="number" />
                </div>
              </td>
              <td
                style={{ width: "115px" }}
                className="border-start border-end"
              >
                5000
              </td>
              <td style={{ width: "115px" }}>
                <div className="position-relative">
                  <Form.Control type="number" />
                </div>
              </td>
              <td style={{ width: "115px" }}>GST</td>
              <td style={{ width: "125px" }} className="border-start">
                250
              </td>
              <td style={{ width: "125px" }} className="border-start">
                5250
              </td>
            </tr> */}
          </tbody>

          <tfoot className="border-top">
            <tr>
              <td className="text-center border-end">
                &nbsp;
              </td>
              <td colSpan="7">
                <div className="fw-600">Grand Total</div>
              </td>
              <td
                className="border-start border-end"
              >
                {totalPrice}
              </td>
              <td colSpan="2">&nbsp;</td>
              <td className="border-start">
                {totalTaxPrice}
              </td>
              <td className="border-start">
                {grandTotal}
              </td>
            </tr>
          </tfoot>
        </Table>
      </div>
    </>
  );
}
