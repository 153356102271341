import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Carousel, Row, Col, Button } from "react-bootstrap";
import { Loader } from "../../components";
import defaultThumb from "../../assets/images/blog/post.png";

import { useFetchPostsFeaturedMutation } from "../../redux/services/blogApi";
import { APP_ROUTE } from "../../utils/constants";
import IconItem from "../../components/IconItem";

export default function BlogSlider() {
  const [postsFeatured, setPostsFeatured] = useState([]);
  const [fetchPostsFeatured, { isLoading }] = useFetchPostsFeaturedMutation();

  useEffect(() => {
    async function fetchData() {
      const { data } = await fetchPostsFeatured();
      setPostsFeatured(data.data.results);
    }
    fetchData();
  }, []);

  const publish = (pubdate) => {
    const day = new Date(pubdate);
    const mmStr = day.toLocaleString("en-us", { month: "long" });
    const dd = String(new Date(day).getDate()).padStart(2, "0");
    const YYYY = String(new Date(day).getFullYear());

    return dd + " " + mmStr + " " + YYYY;
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="blog-slider">
      <Carousel controls={false}>
        {postsFeatured
          ?.filter((post) => post.is_featured)
          .map((post) => (
            <Carousel.Item key={post?.slug}>
              <Row className="g-0">
                <Col sm={6}>
                  <Link
                    to={`${APP_ROUTE.BLOG}/${post?.slug}/`}
                    className="text-reset text-decoration-none"
                  >
                    <img
                      src={post?.image || defaultThumb}
                      alt=""
                      className="blog-slider-thumb"
                    />
                  </Link>
                </Col>
                <Col sm={6}>
                  <div className="blog-slider-inner d-flex flex-column align-items-start h-100">
                    <div className="d-flex mb-12">
                      {post?.author && (
                        <div className="d-flex align-items-center me-lg-4 me-xl-40">
                          <IconItem icon="user-avatar" className="sz-4 me-2" />
                          <span className="text-primary">{post?.author}</span>
                        </div>
                      )}
                      {post?.published_at && (
                        <div className="d-flex align-items-center me-lg-4 me-xl-40">
                          <IconItem icon="calendar-fill" className="sz-4 me-2" />
                          <span className="text-primary">
                            {publish(post?.published_at)}
                          </span>
                        </div>
                      )}
                    </div>
                    <h2 className="h2">
                      <Link
                        to={`${APP_ROUTE.BLOG}/${post?.slug}/`}
                        className="text-reset text-decoration-none"
                      >
                        {post.title}
                      </Link>
                    </h2>
                    <p>{post?.body_preview}</p>
                    <Link
                      to={`${APP_ROUTE.BLOG}/${post?.slug}/`}
                      className="text-reset text-decoration-none mt-auto"
                    >
                      <Button variant="outline-primary" className="bw-2 px-4">
                        Read More
                      </Button>
                    </Link>
                  </div>
                </Col>
              </Row>
            </Carousel.Item>
          ))}
      </Carousel>
    </div>
  );
}
