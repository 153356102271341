import { Row, Col, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { APP_ROUTE } from "../../utils/constants";

export default function Profile() {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.userStore);
  const { image, email, name, phone_number, role, description, pan, gst } =
    user;
  return (
    <>
      <h1 className="h1 fw-bolder mb-14">My Profile</h1>

      <Row className="row justify-content-between">
        {image && (
          <Col md={4} className="mb-4">
            <img src={image} alt="" className="img-fluid d-block mb-2" />
          </Col>
        )}

        <Col md={8} lg={7}>
          <div className="form">
            <div className="mb-2">
              <Row className="g-0 align-items-center flex-sm-nowrap">
                <Col sm="auto">
                  <div className="fs-6 lh-base col-form-label py-0">Name:</div>
                </Col>
                <Col>
                  <div className="form-control text-break readonly">{name}</div>
                </Col>
              </Row>
            </div>

            <div className="mb-2">
              <Row className="g-0 align-items-center flex-sm-nowrap">
                <Col sm="auto">
                  <div className="fs-6 lh-base col-form-label py-0">Email:</div>
                </Col>
                <Col>
                  <div className="form-control readonly">{email}</div>
                </Col>
              </Row>
            </div>

            <div className="mb-2">
              <Row className="g-0 align-items-center flex-sm-nowrap">
                <Col sm="auto">
                  <div className="fs-6 lh-base col-form-label py-0">
                    Mobile phone number:
                  </div>
                </Col>
                <Col>
                  <div className="form-control readonly">{phone_number}</div>
                </Col>
              </Row>
            </div>

            <div className="mb-2">
              <Row className="g-0 align-items-center flex-sm-nowrap">
                <Col sm="auto">
                  <div className="fs-6 lh-base col-form-label py-0">Type:</div>
                </Col>
                <Col>
                  <div className="form-control readonly">{role}</div>
                </Col>
              </Row>
            </div>

            {gst && (
              <div className="mb-2">
                <Row className="g-0 align-items-center flex-sm-nowrap">
                  <Col sm="auto">
                    <div className="fs-6 lh-base col-form-label py-0">GST:</div>
                  </Col>
                  <Col>
                    <div className="form-control readonly">{gst}</div>
                  </Col>
                </Row>
              </div>
            )}

            {pan && (
              <div className="mb-2">
                <Row className="g-0 align-items-center flex-sm-nowrap">
                  <Col sm="auto">
                    <div className="fs-6 lh-base col-form-label py-0">PAN:</div>
                  </Col>
                  <Col>
                    <div className="form-control readonly">{pan}</div>
                  </Col>
                </Row>
              </div>
            )}

            {description && (
              <div className="mb-2">
                <Row className="g-0">
                  <div className="col-sm-auto pt-2">
                    <div className="fs-6 lh-base col-form-label py-0">
                      Description:
                    </div>
                  </div>
                  <Col>
                    <div className="form-control text-break readonly">
                      {description}
                    </div>
                  </Col>
                </Row>
              </div>
            )}

            <Row className="g-0">
              <Col sm={6} lg={6} xl={5}>
                <Button
                  variant="primary"
                  className="w-100"
                  onClick={() => navigate(APP_ROUTE.PROFILE_CHANGE)}
                >
                  Change Profile
                </Button>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </>
  );
}
