import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Table } from "react-bootstrap";

import { setAllTenders } from "../../redux/slices/TenderSlice";
import { transformationTime } from "../../utils/helpersFunctions";
import {
  useGetTendersContractorMutation,
  useGetTenderDetailsMutation,
  useGetTendersMutation
} from "../../redux/services/tenderApi";
import {
  Loader,
  Pagination,
  VisibilityDropdown,
  StatusDropdown,
  PricingVisibilityDropdown
} from "../../components";
import { TENDER_STATUSES, APP_ROUTE, CLIENT_TYPE } from "../../utils/constants";
import SortableCell from "../../components/SortableCell";

export default function Tenders() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user } = useSelector((state) => state.userStore);

  const [getTenders, { isLoading: isLoadingGetTenders }] =
    useGetTendersMutation();
  const [getTendersContractor, { isLoading: isLoadingGetTendersContractor }] =
    useGetTendersContractorMutation();
  const [getTenderDetails] = useGetTenderDetailsMutation();
  const isLoading = isLoadingGetTenders || isLoadingGetTendersContractor;

  const [tenderList, setTenderList] = useState([]);
  const [pricingVisibility, setPricingVisibility] = useState("");
  const [visibility, setVisibility] = useState("");
  const [status, setStatus] = useState("");
  const [sortBy, setSortBy] = useState("");

  const [countPage, setCountPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  const sortByHandler = (param) => {
    setSortBy(
      !sortBy.includes(param) ? param : sortBy === param ? `-${param}` : ""
    );
  };

  useEffect(async () => {
    const visibilityStr = visibility ? `&visibility=${visibility}` : "";
    const pricingVisibilityStr = pricingVisibility
      ? `&pricing_visibility=${pricingVisibility}`
      : "";
    const statusStr = status ? `&status=${status}` : "";
    const sortByStr = sortBy ? `&o=${sortBy}` : "";
    const { data } =
      user.role === CLIENT_TYPE.employer
        ? await getTenders({
            page: currentPage,
            visibility: visibilityStr,
            pricingVisibility: pricingVisibilityStr,
            status: statusStr,
            deadline: "",
            o: sortByStr
          })
        : await getTendersContractor(currentPage);
    dispatch(setAllTenders(data.data.results));
    setCountPage(data.data.count || 1);
    setTenderList(data.data.results);
  }, [currentPage, visibility, pricingVisibility, status, sortBy]);

  const visibilityChange = (value) => {
    setCurrentPage(1);
    setVisibility(value);
  };
  const pricingVisibilityChange = (value) => {
    setCurrentPage(1);
    setPricingVisibility(value);
  };
  const statusChange = (value) => {
    setCurrentPage(1);
    setStatus(value);
  };

  return (
    <>
      <h1 className="h1 fw-bolder mb-14">My Tenders</h1>
      <div className="mb-3 mx-n2 mx-lg-n4">
        <Table striped responsive>
          <thead>
            <tr>
              <SortableCell
                name="Tender"
                sortBy={sortByHandler}
                title="Sort by title"
                className="ps-2 ps-lg-4 tender-column"
              />
              <th className="visibility-column">
                <VisibilityDropdown
                  visibility={visibility}
                  onChange={visibilityChange}
                />
              </th>
              <th className="view-column">
                <PricingVisibilityDropdown
                  pv={pricingVisibility}
                  onChange={pricingVisibilityChange}
                />
              </th>
              <SortableCell
                name="Posted"
                sortBy={sortByHandler}
                title="Sort by date created"
                className="date-column"
              />
              <SortableCell
                name="Deadline"
                sortBy={sortByHandler}
                title="Sort by deadline"
                className="date-column"
              />
              <SortableCell
                name="Bids"
                sortBy={sortByHandler}
                title="Sort by bids count"
                className="bids-column"
              />
              <th>
                <StatusDropdown status={status} onChange={statusChange} />
              </th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan="7">
                  <Loader />
                </td>
              </tr>
            ) : tenderList.length === 0 ? (
              <tr>
                <td colSpan="7">
                  <div className="px-2 px-lg-4">
                    <h6 className="my-10 fw-normal">
                      You haven&apos;t created any tenders yet. Please click on
                      &quot;Create New Tender&quot; in the left navigation bar
                      to create your first tender.
                    </h6>
                  </div>
                </td>
              </tr>
            ) : (
              tenderList?.map((tender) => (
                <TenderRow
                  key={tender.id}
                  tender={tender}
                  navigate={navigate}
                  getTenderDetails={getTenderDetails}
                />
              ))
            )}
          </tbody>
        </Table>
      </div>
      {(tenderList.length > 9 || currentPage !== 1) && (
        <Pagination
          itemsCount={countPage}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      )}
    </>
  );
}

function TenderRow({ tender, navigate, getTenderDetails }) {
  return (
    <tr
      className="cursor-pointer"
      key={tender.id}
      onClick={async () => {
        if (tender.status === TENDER_STATUSES.draft) {
          const { data } = await getTenderDetails(tender.id);
          const tenderDetail = data.data;
          navigate(APP_ROUTE.EMPLOYER_TENDER_EDIT, {
            state: {
              tenderDetail,
              isEdit: true
            }
          });
          return;
        }
        navigate(`${APP_ROUTE.EMPLOYER_TENDER_DETAIL}/${tender.id}`);
      }}
    >
      <td className="ps-2 ps-lg-4 tender-column">
        <div>{tender.title}</div>
      </td>
      <td className="visibility-column">
        <div>{tender.visibility}</div>
      </td>
      <td className="view-column">
        <div>
          <Link to="/">{tender.pricing_visibility}</Link>
        </div>
      </td>
      <td className="date-column">
        <div>{transformationTime(tender.created_at)}</div>
      </td>
      <td className="date-column">
        <div>{transformationTime(tender.deadline)}</div>
      </td>
      <td className="bids-column">
        <div>
          <Link to="/">{tender.bids_count}</Link>
        </div>
      </td>
      <td>
        <div>{tender.status}</div>
      </td>
    </tr>
  );
}
