import { useCallback, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";

import { transformationTime } from "../../utils/helpersFunctions";
import {
  useMailDeleteMessageMutation,
  useMailReadMessageMutation
} from "../../redux/services/authApi";
import { Loader } from "../../components";
import { APP_ROUTE } from "../../utils/constants";

const MailInboxOpenMessage = () => {
  const [message, setMessage] = useState({});
  const [mailReadMessage, { isLoading: isMsgLoading }] =
    useMailReadMessageMutation();
  const [mailDeleteMessage, { isLoading: isLoadingMailDeleteMessage }] =
    useMailDeleteMessageMutation();
  const navigate = useNavigate();
  const { state } = useLocation();

  const getMessage = useCallback(async (id) => {
    try {
      const { data } = await mailReadMessage(Number(id));
      setMessage(data.data);
    } catch (err) {
      console.log(err);
    }
  }, []);
  useEffect(() => {
    async function fetchData() {
      state.target_object_id
        ? await getMessage(state.target_object_id)
        : await getMessage(state.id);
    }
    fetchData();
  }, []);

  const handlerDeleteMessage = async (id) => {
    await mailDeleteMessage(id);
    navigate(APP_ROUTE.INBOX);
  };

  return (
    <>
      {isMsgLoading && <Loader />}
      <h1 className="h1 fw-bolder mb-14">Inbox messages</h1>
      <div className=" mx-n2 mx-lg-n4 px-5">
        <ul className="list-unstyled mb-30 lh-lg">
          <li className="mb-4 d-flex align-items-center">
            <span className="fs-6 lh-base form-label me-2">Subject:</span>
            <small>
              {state.target_object_id ? message?.subject : state?.subject}
            </small>
          </li>
          <li className="mb-4 d-flex align-items-center">
            <span className="fs-6 lh-base form-label me-2">Sender:</span>
            <small>
              {state.target_object_id
                ? message?.sender?.full_name
                : state?.sender?.full_name}
            </small>
          </li>
          <li className="mb-4 d-flex align-items-center">
            <span className="fs-6 lh-base form-label me-2">Date:</span>
            <small>
              {state.target_object_id
                ? transformationTime(message?.sent_at)
                : transformationTime(state?.sent_at)}
            </small>
          </li>
          <li className="mb-4 d-flex align-items-center">
            <span className="fs-6 lh-base form-label me-2">Recipient:</span>
            <small>{message?.recipient?.full_name}</small>
          </li>
          <li className="mb-4 d-flex align-items-start">
            <span className="fs-6 lh-base form-label me-2">Message:</span>
            <small>
              {state.target_object_id ? message?.body : state?.body}
            </small>
          </li>
        </ul>
      </div>
      <div className="px-3">
        <Button
          variant="primary"
          className="px-30 me-3"
          onClick={() =>
            navigate(APP_ROUTE.NEW_MESSAGE, {
              state: state.target_object_id ? message : state
            })
          }
        >
          Reply
        </Button>
        <Button
          variant="danger"
          onClick={() =>
            state.target_object_id
              ? handlerDeleteMessage(message.id)
              : handlerDeleteMessage(state?.id)
          }
          className="px-30 me-3"
        >
          Delete {isLoadingMailDeleteMessage && <Loader />}
        </Button>
      </div>
    </>
  );
};

export default MailInboxOpenMessage;
