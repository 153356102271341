import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import { authApi } from "./services/authApi";
import userSlice from "./slices/userSlice";
import blogSlice from "./slices/blogSlice";
import { blogApi } from "./services/blogApi";

// middleware
import { requestErrorLogger } from "./../utils/requestErrorLogger";
import { tenderApi } from "./services/tenderApi";
import tenderSlice from "./slices/TenderSlice";

const { configureStore } = require("@reduxjs/toolkit");

export const store = configureStore({
  reducer: {
    userStore: persistReducer({ key: "auth", storage }, userSlice),
    tenderStore: persistReducer({ key: "tender", storage }, tenderSlice),
    [authApi.reducerPath]: authApi.reducer,
    [tenderApi.reducerPath]: tenderApi.reducer,
    blog: blogSlice,
    [blogApi.reducerPath]: blogApi.reducer
  },
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    }).concat([authApi.middleware, tenderApi.middleware, blogApi.middleware]),
    requestErrorLogger
  ]
});
