import { useState, useMemo } from "react";
import { Table, Button } from "react-bootstrap";

import { usePostTenderOrderSendEmailMutation } from "../redux/services/tenderApi";
import { Loader, PoWoCreateModal } from "../components";
import {
  reducePrice,
  reduceTotalPrice,
  toFloat,
  getDateStr
} from "../utils/helpersFunctions";

export default function PoWoList({
  orders,
  isCreateOrder,
  setCreateOrder,
  contractorList,
  tenderId,
  onSend
}) {
  const [sendId, setSendId] = useState(null);
  const [order, setOrder] = useState({});
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    setOrder({});
  };
  const handleShow = (o) => {
    setOrder(o);
    setShow(true);
  };

  const [postTenderOrderSendEmail, { isLoading }] =
    usePostTenderOrderSendEmailMutation();

  const sendEmail = async (orderId) => {
    const { error } = await postTenderOrderSendEmail({
      tenderId,
      orderId
    });
    if (error) {
      return console.log("error", error);
    }

    setSendId(null);
    onSend(orderId);
  };

  const totalPrice = useMemo(() => reducePrice(order?.items), [order]);
  const grandTotal = useMemo(() => reduceTotalPrice(order?.items), [order]);
  const totalTaxPrice = useMemo(
    () => toFloat(grandTotal - totalPrice),
    [totalPrice, grandTotal]
  );

  return (
    <>
      <PoWoCreateModal
        show={show}
        onHide={handleClose}
        form={order}
        items={order.items}
        totalPrice={totalPrice}
        grandTotal={grandTotal}
        totalTaxPrice={totalTaxPrice}
      />
      <div className="mb-36">
        {orders.length > 0 && (
          <div className="mb-36 py-4 dotted mx-n4 px-4 mt-36">
            <div className="fs-6 lh-base form-label mb-2">
              List of Previously Created PO/WO
            </div>

            <div className="table-rounded mb-0 shadow bg-white rounded-1">
              <Table striped responsive className="mb-0">
                <thead>
                  <tr>
                    <th className="text-center">
                      <div>PO/WO Number</div>
                    </th>
                    <th>
                      <div>Issued To </div>
                    </th>
                    <th>
                      <div>Date</div>
                    </th>
                    <th>
                      <div>Emailed </div>
                    </th>
                    <th>
                      <div>Links</div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {orders.map((order) => (
                    <tr key={`PoWoList${order.id}`}>
                      <td className="text-center">{order.number}</td>
                      <td>{order.issued_to.full_name}</td>
                      <td>{getDateStr(order.created_at)}</td>
                      <td>
                        {order.is_emailed ? (
                          "Yes"
                        ) : (
                          <Button
                            variant="primary"
                            size="sm"
                            onClick={() => {
                              setSendId(order.id);
                              sendEmail(order.id);
                            }}
                          >
                            Send Email{" "}
                            {sendId === order.id && isLoading && <Loader />}
                          </Button>
                        )}
                      </td>
                      <td>
                        <div
                          className="d-block text-reset text-decoration-none cursor-pointer"
                          onClick={() => handleShow(order)}
                        >
                          Open
                        </div>
                        {order.file && (
                          <a
                            href={order.file}
                            className="d-block text-reset text-decoration-none cursor-pointer"
                            download
                            target="_blank"
                            rel="noreferrer noopener"
                          >
                            PDF
                          </a>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        )}

        {!isCreateOrder && contractorList.length > 0 && (
          <div className="text-end">
            <Button variant="primary" onClick={() => setCreateOrder(true)}>
              Create PO/WO
            </Button>
          </div>
        )}
      </div>
    </>
  );
}
