import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Row, Col, Pagination } from "react-bootstrap";
import BlogCard from "./BlogCard";
import { Loader } from "../../components";

import { getPages } from "../../helpers/getPages";
import { useFetchPostsMutation } from "../../redux/services/blogApi";
import { FIRST_PAGE, PAGE_SIZE } from "../../utils/constants";

export default function BlogList() {
  const [fetchPosts, { isLoading }] = useFetchPostsMutation();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageArray, setPageArray] = useState([]);

  const posts = useSelector((state) => state.blog.posts);

  const totalPage = Math.ceil(posts.count / PAGE_SIZE);

  useEffect(() => {
    async function fetchData() {
      await fetchPosts({
        page: FIRST_PAGE
      });
      setPageArray(getPages(totalPage, currentPage));
      setCurrentPage(1);
    }
    fetchData();
  }, [totalPage]);

  const getNextPreviousData = async (pageClicked) => {
    await fetchPosts({
      page: pageClicked
    });
    setPageArray(getPages(totalPage, currentPage));
    setCurrentPage(pageClicked);
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="blog-related">
      <h2 className="h2 display-35">Latest Article</h2>
      <Row>
        {posts?.results?.map((post) => (
          <Col md={4} className="mb-20" key={post.slug}>
            <BlogCard post={post} />
          </Col>
        ))}
      </Row>

      {pageArray.length >= 1 && (
        <Pagination activepage={currentPage} onSelect={getNextPreviousData}>
          {pageArray.map((page, index) => {
            const toReturn = [];
            if (index === 0) {
              toReturn.push(
                <Pagination.Prev
                  key="prevPage"
                  disabled={currentPage === 1}
                  onClick={
                    currentPage === 1
                      ? () => {}
                      : () => getNextPreviousData(currentPage - 1)
                  }
                />
              );
            }
            if (page === "") toReturn.push(<Pagination.Ellipsis key={index} />);
            else {
              toReturn.push(
                <Pagination.Item
                  key={page}
                  active={currentPage === page}
                  onClick={
                    currentPage === page
                      ? () => {}
                      : () => getNextPreviousData(page)
                  }
                >
                  {page}
                </Pagination.Item>
              );
            }
            if (index === pageArray.length - 1) {
              toReturn.push(
                <Pagination.Next
                  key="nextPage"
                  disabled={currentPage === page}
                  onClick={
                    currentPage === page
                      ? () => {}
                      : () => getNextPreviousData(currentPage + 1)
                  }
                />
              );
            }
            return toReturn;
          })}
        </Pagination>
      )}
    </div>
  );
}
