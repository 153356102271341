export default function AttachmentsList({ list }) {
  return (
    <>
      <div className="col-lg-12  mb-2">
        <div className="fs-6 lh-base form-label">Attachments</div>
      </div>
      <div className="mb-30 d-flex flex-column">
        {list?.map((item, index) => (
          <a
            key={index + 1}
            href={item.file}
            download
            rel="noreferrer"
            target="_blank"
            className="text-body"
          >
            {item.file.split("/").pop()}
          </a>
        ))}
      </div>
    </>
  );
}
