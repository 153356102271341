import { useMemo } from "react";
import { Link } from "react-router-dom";
import { Card, Button } from "react-bootstrap";

import { APP_ROUTE } from "../../utils/constants";

import defaultThumb from "../../assets/images/blog/post.png";
import IconItem from "../../components/IconItem";

export default function BlogCard({ post }) {
  const publish = useMemo(() => {
    const day = new Date(post?.published_at);
    const mmStr = day.toLocaleString("en-us", { month: "long" });
    const dd = String(new Date(day).getDate()).padStart(2, "0");
    const YYYY = String(new Date(day).getFullYear());

    return dd + " " + mmStr + " " + YYYY;
  }, [post]);

  return (
    <Card className="post h-100">
      <Card.Img variant="top" src={post?.image || defaultThumb} />
      <Card.Body className="d-flex flex-column align-items-start">
        <div className="d-flex mb-14">
          {post?.author && (
            <div className="d-flex align-items-center me-3">
              <IconItem icon="avatar" className="sz-4 me-2" />
              <small className="text-primary">{post?.author}</small>
            </div>
          )}
          {post?.published_at && (
            <div className="d-flex align-items-center me-3">
              <IconItem icon="calendar-fill" className="sz-4 me-2" />
              <small className="text-primary">{publish}</small>
            </div>
          )}
        </div>

        <Card.Title>
          <Link
            to={`${APP_ROUTE.BLOG}/${post?.slug}/`}
            className="text-reset text-decoration-none"
          >
            {post?.title}
          </Link>
        </Card.Title>
        <Card.Text>{post?.body_preview}</Card.Text>
        <Link
          to={`${APP_ROUTE.BLOG}/${post?.slug}/`}
          className="text-reset text-decoration-none mt-auto"
        >
          <Button variant="outline-primary" className="px-30">
            Read More
          </Button>
        </Link>
      </Card.Body>
    </Card>
  );
}
