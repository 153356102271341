import { createSlice } from "@reduxjs/toolkit";

import { authApi } from "../services/authApi";

const initialState = {
  user: {
    name: "",
    email: "",
    id: "",
    role: "",
    phone_number: "",
    description: "",
    gst: "",
    image: "",
    pan: ""
  },
  token: {
    refresh: null,
    access: null
  },
  isLogin: false,
  statistics: { INBOX_MESSAGE_count: null, notifications_count: null }
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    logout: () => initialState,
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setToken: (state, action) => {
      state.token = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(authApi.endpoints.login.matchFulfilled, (state, action) => {
        state.token = action.payload.data;
        state.isLogin = true;
      })
      .addMatcher(authApi.endpoints.getMe.matchFulfilled, (state, action) => {
        state.user = {
          ...action.payload.data,
          name: action.payload.data.full_name
        };
      })
      .addMatcher(
        authApi.endpoints.verifyCode.matchFulfilled,
        (state, action) => {
          state.token = action.payload.data;
          state.isLogin = true;
        }
      )
      .addMatcher(
        authApi.endpoints.registerUser.matchFulfilled,
        (state, action) => {
          state.token = action.payload.data;
        }
      )
      .addMatcher(
        authApi.endpoints.getStats.matchFulfilled,
        (state, action) => {
          state.statistics = action.payload.data;
        }
      )
      .addMatcher(authApi.endpoints.login.matchRejected, (state, action) => {
        console.log("rejected:", action);
      });
  }
});

const { actions, reducer } = userSlice;
export const { logout, setUser, setToken } = actions;
export default reducer;
