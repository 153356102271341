import { Link, useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";

import LayoutFull from "../../layout/LayoutFull";

import logo from "../../assets/images/logo.svg";
import image from "../../assets/images/img-4.svg";

import { APP_ROUTE, CLIENT_TYPE } from "../../utils/constants";

export default function SignUp() {
  const navigate = useNavigate();

  return (
    <>
      <LayoutFull>
        <Link to={APP_ROUTE.HOME} className="logo-md logo d-block mx-auto">
          <img
            src={logo}
            alt="ZoomTender"
            className="img-fluid d-block mx-auto mb-20"
          />
        </Link>

        <img
          src={image}
          alt="SignUp an account"
          className="img-fluid d-block mx-auto mb-4"
        />

        <h1 className="h2 fw-bolder mb-36">Sign up an account</h1>

        <div className="row justify-content-center g-3">
          <div className="col-sm-6 mb-1 mb-sm-3 mb-lg-4">
            <Button
              variant="primary"
              className="w-100"
              onClick={() =>
                navigate(APP_ROUTE.REGISTRATION, {
                  state: { role: CLIENT_TYPE.employer }
                })
              }
            >
              I&lsquo;m an Employer <br />
              (I will invite tenders)
            </Button>
          </div>

          <div className="col-sm-6 mb-1 mb-sm-3 mb-lg-4">
            <Button
              variant="primary"
              className="w-100"
              onClick={() =>
                navigate(APP_ROUTE.REGISTRATION, {
                  state: { role: "Contractor" }
                })
              }
            >
              I&lsquo;m a Contractor <br />
              (I will bid on tenders)
            </Button>
          </div>
        </div>
      </LayoutFull>
    </>
  );
}
