import { useCallback, useEffect, useState, useMemo } from "react";
import { Form, Button } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import cloneDeep from "lodash.clonedeep";

import {
  Loader,
  PersonalInfo,
  PoWoCreate,
  PoWoCreateSuccessModal,
  PoWoList,
  TableFinalizeTender,
  TableBidsComparisonUpdate,
  WithdrawModal
} from "../../components";
import {
  useGetTenderDetailsMutation,
  useUpdateTenderMutation,
  useTenderFinalizeMutation,
  useGetTenderOrdersMutation,
  useGetTaxesMutation
} from "../../redux/services/tenderApi";
// import TableBidsComparison from "../../components/TableBidsComparison";
import { reduceTotalPrice } from "../../utils/helpersFunctions";

import {
  TENDER_STATUSES,
  PRICING_VISIBILITY,
  APP_ROUTE
} from "../../utils/constants";

export default function TenderDetail() {
  const { user } = useSelector((state) => state.userStore);
  const { id } = useParams();
  const navigate = useNavigate();

  const [tenderDetail, setTenderDetail] = useState({});
  const [orders, setOrders] = useState([]);
  const [taxes, setTaxes] = useState([]);
  const [isCreateOrder, setCreateOrder] = useState(false);
  const [finalizedItems, setFinalizedItems] = useState([]);
  const [errors, setErrors] = useState([]);
  const [orderId, setOrderId] = useState(null);
  const [showSuccess, setShowSuccess] = useState(false);
  const [tenderError, setTenderError] = useState("");

  const [getTenderDetails, { isLoading: isLoadingGetTenderDetails }] =
    useGetTenderDetailsMutation();
  const [tenderFinalize, { isLoading: isTenderFinalizeLoading }] =
    useTenderFinalizeMutation();
  const [updateTender, { isLoading: isLoadingPatchTenders }] =
    useUpdateTenderMutation();
  const [getTenderOrders, { isLoading: isLoadingGetTenderOrders }] =
    useGetTenderOrdersMutation();
  const [getTaxes, { isLoading: isTaxesLoading }] = useGetTaxesMutation();

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const getTenderData = useCallback(async () => {
    const response = await Promise.all([
      getTenderDetails(id),
      getTenderOrders(id),
      getTaxes()
    ]);

    if (response[0]?.error?.data?.errors.length > 0) {
      return setTenderError(response[0]?.error?.data.errors[0].detail);
    }

    const tenderData = response[0].data.data;
    setTenderDetail(tenderData);
    setOrders(response[1].data.data);
    setTaxes(response[2].data.data);
    if (tenderDetail.status !== TENDER_STATUSES.active) {
      setFinalizedItems(
        tenderData.items.map((i) => ({
          ...i,
          price: i?.price || null,
          total_price: i?.total_price || null
        }))
      );
    }
  }, []);

  useEffect(() => {
    async function fetchData() {
      await getTenderData();
    }
    fetchData();
  }, [isLoadingPatchTenders]);

  const finalizeTenderHandler = async (e) => {
    e.preventDefault();
    const tehData = {
      items: finalizedItems,
      is_finalized: true,
      grand_total: grandTotal
    };
    try {
      const { data, error } = await tenderFinalize({
        id,
        body: tehData
      });
      setErrors([]);
      if (error && error.data.errors.length !== 0) {
        setErrors(error.data.errors[0]);
        return;
      }
      setTenderDetail(data.data);
    } catch (err) {
      console.log(err);
    }
  };

  const withdrawTenderHandler = async () => {
    await updateTender({
      id,
      body: {
        is_withdrawn: true
      }
    });
  };

  const updateFormHandler = (item) => {
    const index = finalizedItems.findIndex((i) => i.id === item.id);
    const tehItems = [...finalizedItems];
    tehItems[index] = item;

    return setFinalizedItems(tehItems);
  };

  const grandTotal = useMemo(
    () => reduceTotalPrice(finalizedItems),
    [finalizedItems]
  );

  const contractorList = useMemo(
    () =>
      tenderDetail?.bids && tenderDetail?.bids.length > 0
        ? tenderDetail?.bids.map((bid) => bid.owner)
        : [],
    [tenderDetail]
  );

  const closeSuccessHandler = () => {
    setShowSuccess(false);
    setOrderId(null);
  };
  const showSuccessHandler = (id) => {
    setOrderId(id);
    setShowSuccess(true);
  };

  const orderCreateHandler = (order) => {
    setOrders([...orders, order]);
    setCreateOrder(false);
    showSuccessHandler(order.id);
  };
  const orderSentHandler = (orderId) => {
    const index = orders.findIndex((o) => o.id === orderId);
    const ordersCopy = cloneDeep(orders);
    ordersCopy[index].is_emailed = true;
    setOrders([...ordersCopy]);
  };

  if (tenderError) {
    return <h1 className="h1 fw-bolder mb-14">{tenderError}</h1>;
  }

  return (
    <>
      <WithdrawModal
        show={show}
        onHide={handleClose}
        entity="tender"
        onConfirm={withdrawTenderHandler}
      />
      <PoWoCreateSuccessModal
        show={showSuccess}
        onHide={closeSuccessHandler}
        orderId={orderId}
        tenderId={tenderDetail.id}
        onSend={orderSentHandler}
      />
      <>
        <h1 className="h1 fw-bolder mb-14">Tender Details</h1>
        {isLoadingGetTenderDetails ||
        isLoadingGetTenderOrders ||
        isTaxesLoading ? (
          <Loader />
        ) : (
          <Form className="form">
            <div className="mb-36">
              <div className="mb-3">
                <PersonalInfo value={tenderDetail} />
              </div>
              {tenderDetail.attachments?.length > 0 && (
                <>
                  <div className="col-lg-12  mb-2">
                    <div className="fs-6 lh-base form-label">Attachments</div>
                  </div>
                  <div className="mb-3 d-flex flex-column">
                    {tenderDetail.attachments?.map((attachment) => (
                      <AttachmentLink
                        key={`attachment${attachment.id}`}
                        attachment={attachment}
                      />
                    ))}
                  </div>
                </>
              )}

              {tenderDetail.status === TENDER_STATUSES.active && (
                <div className="row g-2">
                  <div className="col-auto">
                    <Button
                      variant="light"
                      className="px-36"
                      onClick={() =>
                        navigate(APP_ROUTE.EMPLOYER_TENDER_EDIT, {
                          state: {
                            tenderDetail,
                            isEdit: true
                          }
                        })
                      }
                    >
                      Edit
                    </Button>
                  </div>

                  <div className="col-auto">
                    <Button
                      variant="primary"
                      className="px-4"
                      onClick={handleShow}
                    >
                      Withdraw Tender
                    </Button>
                  </div>

                  <div className="col-auto">
                    <Button
                      variant="primary"
                      className="px-4"
                      onClick={() =>
                        navigate(APP_ROUTE.EMPLOYER_TENDER_INVITE, {
                          state: { tenderDetail }
                        })
                      }
                    >
                      Invite more Contractors
                    </Button>
                  </div>
                </div>
              )}
              {tenderDetail.status !== TENDER_STATUSES.active && (
                <div className="col-auto">
                  <Button
                    variant="light"
                    className="px-36"
                    onClick={() =>
                      navigate(APP_ROUTE.EMPLOYER_TENDER_NEW, {
                        state: {
                          tenderDetail,
                          isEdit: false,
                          isRecreate: true
                        }
                      })
                    }
                  >
                    Re-invite tender
                  </Button>
                </div>
              )}
            </div>

            {tenderDetail?.bids && tenderDetail?.bids.length > 0 && (
              <div className="mb-36">
                {(tenderDetail.pricing_visibility !==
                  PRICING_VISIBILITY.closedView ||
                  tenderDetail.status === TENDER_STATUSES.finalized) && (
                  <TableBidsComparisonUpdate
                    items={tenderDetail.items}
                    bids={tenderDetail?.bids || []}
                    role={user.role}
                    tenderId={tenderDetail.id}
                    allowTaxes={tenderDetail.allow_taxes}
                  />
                )}
              </div>
            )}

            {/* {tenderDetail?.bids && tenderDetail?.bids.length > 0 && (
              <div className="mb-36">
                {(tenderDetail.pricing_visibility !==
                  PRICING_VISIBILITY.closedView ||
                  tenderDetail.status === TENDER_STATUSES.finalized) && (
                  <TableBidsComparison
                    items={tenderDetail.items}
                    bids={tenderDetail?.bids || []}
                    role={user.role}
                    tenderId={tenderDetail.id}
                  />
                )}
              </div>
            )} */}

            {tenderDetail.status !== TENDER_STATUSES.active && (
              <>
                <div className="mb-36">
                  <div className="fs-6 lh-base form-label mb-2">
                    Finalize Tender
                  </div>

                  <TableFinalizeTender
                    items={tenderDetail.items}
                    isEditMode={
                      tenderDetail.status === TENDER_STATUSES.biddingComplete
                    }
                    grandTotal={grandTotal}
                    onUpdate={updateFormHandler}
                    finalizedItems={finalizedItems}
                    errors={errors}
                  />
                </div>

                {tenderDetail.status === TENDER_STATUSES.biddingComplete && (
                  <div className="text-end py-10">
                    <Button
                      type="submit"
                      variant="primary"
                      className="px-36"
                      onClick={finalizeTenderHandler}
                      disabled={
                        isTenderFinalizeLoading ||
                        !finalizedItems.every((i) => i.price)
                      }
                    >
                      Finalize {isTenderFinalizeLoading && <Loader />}
                    </Button>
                  </div>
                )}
              </>
            )}

            {tenderDetail?.bids?.map(
              (bid) =>
                bid?.attachments.length > 0 && (
                  <>
                    <div className="col-lg-12  mb-2">
                      <div className="fs-6 lh-base form-label">{`Attachments (${bid.owner.full_name})`}</div>
                    </div>
                    <div className="mb-3 d-flex flex-column">
                      {bid.attachments.map((attachment) => (
                        <AttachmentLink
                          key={`bid_attachment${attachment.id}`}
                          attachment={attachment}
                        />
                      ))}
                    </div>
                  </>
                )
            )}

            <PoWoList
              orders={orders}
              isCreateOrder={isCreateOrder}
              setCreateOrder={setCreateOrder}
              contractorList={contractorList}
              tenderId={tenderDetail.id}
              onSend={orderSentHandler}
            />

            {isCreateOrder && (
              <PoWoCreate
                setCreateOrder={setCreateOrder}
                contractorList={contractorList}
                items={tenderDetail.items}
                bids={tenderDetail?.bids}
                taxes={taxes}
                tenderId={tenderDetail.id}
                onCreate={orderCreateHandler}
              />
            )}
          </Form>
        )}
      </>
    </>
  );
}

function AttachmentLink({ attachment }) {
  return (
    <a
      href={attachment.file}
      download
      rel="noreferrer"
      target="_blank"
      className="text-body"
    >
      {attachment.file.split("/").pop()}
    </a>
  );
}
