import { useEffect, useState } from "react";
import { Table, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import {
  useMailDeleteMessageMutation,
  useMailInboxMutation
} from "../../redux/services/authApi";
import { Loader, Pagination } from "../../components";
import { transformationTime } from "../../utils/helpersFunctions";
import { APP_ROUTE } from "../../utils/constants";
import IconItem from "../../components/IconItem";

export default function MailInbox() {
  const [mailInbox, { isLoading: isLoadingMailInbox }] = useMailInboxMutation();
  const [mailDeleteMessage] = useMailDeleteMessageMutation();

  const [mailList, setMailList] = useState([]);

  const navigate = useNavigate();

  const [countPage, setCountPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    async function fetchData() {
      const tehMailInbox = await mailInbox(currentPage);
      setMailList(tehMailInbox.data.data.results);
      setCountPage(tehMailInbox.data.data.count);
    }
    fetchData();
  }, [currentPage]);

  const handlerDeleteMessage = async (id) => {
    setMailList(mailList.filter((item) => item.id !== id));
    await mailDeleteMessage(id);
  };

  return (
    <>
      <h1 className="h1 fw-bolder mb-14">Inbox messages</h1>

      <div className="mb-3 mx-n2 mx-lg-n4">
        <Table striped responsive>
          <thead>
            <tr>
              <th className="ps-2 ps-lg-4">
                <div>Sender</div>
              </th>
              <th>
                <div>Subject</div>
              </th>
              <th>
                <div>Received</div>
              </th>
              <th className="pe-2 pe-lg-4">&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {isLoadingMailInbox ? (
              <tr>
                <td colSpan="4">
                  <Loader />
                </td>
              </tr>
            ) : mailList.length === 0 ? (
              <tr>
                <td colSpan="4" className="ps-2 ps-lg-4">
                  <h6 className="fsz-18 mb-10">No messages</h6>
                </td>
              </tr>
            ) : (
              mailList?.map((item) => (
                <MailRow
                  key={item.id}
                  item={item}
                  navigate={navigate}
                  onDelete={handlerDeleteMessage}
                />
              ))
            )}
          </tbody>
        </Table>
      </div>
      {(mailList.length > 9 || currentPage !== 1) && (
        <Pagination
          itemsCount={countPage}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      )}
    </>
  );
}

function MailRow({ item, navigate, onDelete }) {
  return (
    <tr
      onClick={() => {
        navigate(APP_ROUTE.INBOX_MESSAGE, { state: item });
      }}
    >
      <td
        className={`cursor-pointer ps-2 ps-lg-4 ${
          !item.is_read ? "fw-600" : ""
        }`}
      >
        <div>{item.sender.full_name}</div>
      </td>
      <td className={`cursor-pointer ${!item.is_read ? "fw-600" : ""}`}>
        <div>{item.subject}</div>
      </td>
      <td className={`cursor-pointer ${!item.is_read ? "fw-600" : ""}`}>
        <div>{transformationTime(item.sent_at)}</div>
      </td>
      <td className="pe-2 pe-lg-4 text-center">
        <div>
          <Button
            variant="link"
            onClick={(e) => {
              e.stopPropagation();
              onDelete(item.id);
            }}
          >
            <IconItem icon="delete" />
          </Button>
        </div>
      </td>
    </tr>
  );
}
