import { Form, Dropdown } from "react-bootstrap";

import { VISIBILITY } from "../../utils/constants";

export default function VisibilityDropdown({ visibility, onChange }) {
  const clearText = "Clear filter";
  const visibilityList = [VISIBILITY.public, VISIBILITY.private, clearText];

  const clickHandler = (value) => {
    const newValue = value === clearText ? "" : value;
    onChange(newValue);
  };
  return (
    <Dropdown>
      <Dropdown.Toggle variant="" id="dropdown-visibility">
        Visibility
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {visibilityList.map((v, i) => (
          <Dropdown.Item key={i} as="li" onClick={() => clickHandler(v)}>
            <Form.Check
              inline
              name={v}
              type="radio"
              checked={visibility === v}
              label={v}
              onChange={() => clickHandler(v)}
            />
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}
