import LayoutHome from "../../layout/LayoutHome";
import BidSubmit from "./BidSubmit";

export default function BidSubmitWrapped() {
  return (
    <LayoutHome>
      <div className="py-4 px-2 p-lg-6">
        <BidSubmit />
      </div>
    </LayoutHome>
  );
}
