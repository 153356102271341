import { Row, Col, Form } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";

import { IconError, IconSuccess } from "../utils/svgHelper";
import {
  nameVerification,
  phoneNumberVerification,
  emailVerification
} from "../utils/yupHelper";

export default function AddBidInfo({ form, setForm, setValid, errors }) {
  const schema = yup.object().shape({
    name: nameVerification,
    email: emailVerification,
    phone_number: phoneNumberVerification
  });

  const changeHandler = (e, isValid) => {
    setForm({ ...form, [e.target.name]: e.target.value });
    setValid(isValid);
  };

  return (
    <>
      <Formik
        validateOnChange
        initialValues={{
          name: "",
          email: "",
          phone_number: ""
        }}
        validationSchema={schema}
      >
        {({ values, touched, handleChange, handleBlur, isValid }) => (
          <Form className="form">
            <div className="mb-4">
              <Row className="align-items-center">
                <Col sm={3} lg={3}>
                  <Form.Label className="fs-6 lh-base col-form-label py-0">
                    Contractor Name:
                  </Form.Label>
                </Col>
                <Col sm={9} lg={4}>
                  <div className="position-relative">
                    <div className="position-relative">
                      <Form.Control
                        type="text"
                        name="name"
                        className={`${touched.name ? "is-touch " : ""} ${
                          errors.name && touched.name ? " is-invalid" : ""
                        } ${!errors.name && touched.name ? " is-valid" : ""}`}
                        value={values.name}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          handleChange(e);
                          changeHandler(e, isValid);
                        }}
                        placeholder="Name"
                      />

                      {errors.name && touched.name && <IconError />}
                      {!errors.name && touched.name && <IconSuccess />}
                    </div>
                    {errors.name && touched.name && (
                      <Form.Control.Feedback
                        type="invalid"
                        className="position-absolute start-0 top-100"
                      >
                        {errors.name}
                      </Form.Control.Feedback>
                    )}
                  </div>
                </Col>
              </Row>
            </div>

            <div className="mb-4">
              <Row className="align-items-center">
                <Col sm={3} lg={3}>
                  <Form.Label className="fs-6 lh-base col-form-label py-0">
                    Email:
                  </Form.Label>
                </Col>
                <Col sm={9} lg={4}>
                  <div className="position-relative">
                    <div className="position-relative">
                      <Form.Control
                        type="text"
                        name="email"
                        className={`${touched.email ? "is-touch " : ""} ${
                          errors.email && touched.email ? " is-invalid" : ""
                        } ${!errors.email && touched.email ? " is-valid" : ""}`}
                        value={values.email}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          handleChange(e);
                          changeHandler(e, isValid);
                        }}
                        placeholder="Email"
                      />

                      {errors.email && touched.email && <IconError />}
                      {!errors.email && touched.email && <IconSuccess />}
                    </div>
                    {(errors.email || errors.non_field_errors) &&
                      touched.email && (
                        <Form.Control.Feedback
                          type="invalid"
                          className="position-absolute start-0 top-100"
                        >
                          {errors.email || errors.non_field_errors}
                        </Form.Control.Feedback>
                      )}
                  </div>
                </Col>
              </Row>
            </div>

            <div className="mb-4">
              <Row className="align-items-center">
                <Col sm={3} lg={3}>
                  <Form.Label className="fs-6 lh-base col-form-label py-0">
                    Phone:
                  </Form.Label>
                </Col>
                <Col sm={9} lg={4}>
                  <div className="position-relative">
                    <div className="position-relative">
                      <Form.Control
                        type="text"
                        name="phone_number"
                        className={`${
                          touched.phone_number ? "is-touch " : ""
                        } ${
                          errors.phone_number && touched.phone_number
                            ? " is-invalid"
                            : ""
                        } ${
                          !errors.phone_number && touched.phone_number
                            ? " is-valid"
                            : ""
                        }`}
                        value={values.phone_number}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          handleChange(e);
                          changeHandler(e, isValid);
                        }}
                        placeholder="Phone number"
                      />

                      {errors.phone_number && touched.phone_number && (
                        <IconError />
                      )}
                      {!errors.phone_number && touched.phone_number && (
                        <IconSuccess />
                      )}
                    </div>
                    {errors.phone_number && touched.phone_number && (
                      <Form.Control.Feedback
                        type="invalid"
                        className="position-absolute start-0 top-100"
                      >
                        {errors.phone_number}
                      </Form.Control.Feedback>
                    )}
                  </div>
                </Col>
              </Row>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}
