import { Table } from "react-bootstrap";

export default function PoWo() {
  return (
    <>
      <h1 className="h1 fw-bolder mb-14">PO/WO</h1>

      <div className="table-rounded mb-30 shadow bg-white rounded-1">
        <Table striped responsive className="mb-0">
          <thead>
            <tr>
              <th className="text-center" style={{ width: "85px" }}>
                <div>S No</div>
              </th>
              <th>
                <div>Tender</div>
              </th>
              <th>
                <div>Employer</div>
              </th>
              <th>
                <div>Posted</div>
              </th>
              <th>
                <div>Deadline</div>
              </th>
              <th>
                <div>PO/WO Number</div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="text-center" style={{ width: "85px" }}>
                1
              </td>
              <td>Construction</td>
              <td>Z Industries</td>
              <td>11/11/2021</td>
              <td>12/11/2021</td>
              <td>001 HTML PDF</td>
            </tr>
            <tr>
              <td className="text-center" style={{ width: "85px" }}>
                2
              </td>
              <td>Renovation</td>
              <td>ABC Cons</td>
              <td>11/11/2021</td>
              <td>12/11/2021</td>
              <td>001 HTML PDF</td>
            </tr>
          </tbody>
        </Table>
      </div>
    </>
  );
}
