import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Row, Col, Form, Button } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import Resizer from "react-image-file-resizer";

import { PASSWORD_REGEXP, APP_ROUTE, CLIENT_TYPE } from "../../utils/constants";
import {
  emailVerification,
  nameVerification,
  phoneNumberVerification
} from "../../utils/yupHelper";
import LayoutRegistration from "../../layout/LayoutRegistration";
import { useRegisterUserMutation } from "../../redux/services/authApi";
import { Loader } from "../../components";
import { IconError, IconEye, IconSuccess } from "../../utils/svgHelper";

export default function Registration() {
  const { state } = useLocation();
  const [role] = useState(state.role);
  const [typeRadio, setTypeRadio] = useState("Individual");
  const [passwordType, setPasswordType] = useState("password");
  const [confirmPasswordType, setConfirmPasswordType] = useState("password");
  const [form, setForm] = useState({});
  const [image, setImage] = useState(null);
  const [imageBase64, setImageBase64] = useState(null);

  const [registerUser, { isLoading }] = useRegisterUserMutation();
  const navigate = useNavigate();
  const schema = yup.object().shape({
    full_name: nameVerification,
    email: emailVerification,
    phone_number: phoneNumberVerification,
    password: yup
      .string()
      .required("Password is a required field")
      .matches(PASSWORD_REGEXP, "Must contain at least 6 characters"),
    password_confirm: yup
      .string()
      .required("Password confirmation is a required field")
      .matches(PASSWORD_REGEXP, "Must contain at least 6 characters")
      .oneOf(
        [yup.ref("password"), null],
        "Password and Password confirmation must match"
      )
      .oneOf(
        [yup.ref("password"), null],
        "Password and Password confirmation must match"
      ),

    description: yup.string(),
    gst: yup.string(),
    pan: yup.string(),
    file: yup.mixed()
  });

  const resizeFile = (file) => {
    Resizer.imageFileResizer(
      file,
      600,
      480,
      "JPEG",
      100,
      0,
      (uri) => {
        setImageBase64(uri);
      },
      "base64",
      350,
      300
    );
  };

  const formDateUpdateHandler = (opt) => {
    setForm({ ...form, ...opt });
  };

  const handleSwitch = (e) => {
    if (e.target.checked) {
      setTypeRadio(e.target.value);
    }
  };

  const handleSubmit = async (values, { setErrors }) => {
    const tehData = {
      password: values.password,
      full_name: values.full_name,
      email: values.email,
      phone_number: values.phone_number,
      gst: values.gst,
      pan: values.pan,
      description: values.description,

      role: role === CLIENT_TYPE.contractor ? `${role} ${typeRadio}` : role,
      password_confirm: values.password_confirm
    };
    if (imageBase64) {
      tehData.image = imageBase64;
    }

    try {
      const { error } = await registerUser(tehData);
      if (error && error.data.errors.length !== 0) {
        setErrors(error.data.errors[0]);
        return;
      }
      navigate(APP_ROUTE.COMPLETE_REGISTRATION, {
        state: { email: form.email }
      });
    } catch (err) {
      console.error(err);
    }
  };

  const reversePasswordType = () =>
    passwordType === "password"
      ? setPasswordType("text")
      : setPasswordType("password");
  const reverseConfirmPasswordType = () =>
    confirmPasswordType === "password"
      ? setConfirmPasswordType("text")
      : setConfirmPasswordType("password");

  return (
    <LayoutRegistration>
      <Formik
        validateOnChange
        initialValues={{
          full_name: "",
          email: "",
          password: "",
          password_confirm: "",
          description: "",
          phone_number: "",
          gst: "",
          pan: "",
          image: ""
        }}
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          isValid,
          handleSubmit,
          dirty
        }) => (
          <Form className="form" onSubmit={handleSubmit}>
            {role === CLIENT_TYPE.employer && (
              <>
                <h1 className="h2 fw-bolder mb-4">Employer Registration</h1>
              </>
            )}
            {role === CLIENT_TYPE.contractor && (
              <>
                <h1 className="h2 fw-bolder mb-4">Contractor Registration</h1>

                <div className="mb-4 pt-2 d-flex align-items-center justify-content-center">
                  <Form.Check
                    inline
                    name="registrationType"
                    type="radio"
                    id="individual"
                    label="Individual"
                    value="Individual"
                    checked={typeRadio === "Individual"}
                    onChange={handleSwitch}
                  />
                  <Form.Check
                    inline
                    name="registrationType"
                    type="radio"
                    id="company"
                    label="Company"
                    value="Company"
                    checked={typeRadio === "Company"}
                    onChange={handleSwitch}
                  />
                </div>
              </>
            )}

            <div className="mb-4">
              <Form.Group className="position-relative">
                <Row className="justify-content-center align-items-center">
                  <Col
                    sm={4}
                    lg={3}
                    className="text-start text-sm-end mb-2 mb-sm-0"
                  >
                    <Form.Label className="col-form-label py-0">
                      Name
                    </Form.Label>
                  </Col>
                  <Col sm={8} lg={7}>
                    <div className="position-relative">
                      <div className="position-relative">
                        <Form.Control
                          type="text"
                          name="full_name"
                          tabIndex="1"
                          className={`${touched.full_name ? "is-touch " : ""} ${
                            errors.full_name && touched.full_name
                              ? " is-invalid"
                              : ""
                          } ${
                            !errors.full_name && touched.full_name
                              ? " is-valid"
                              : ""
                          }`}
                          value={values.full_name}
                          onBlur={handleBlur}
                          onChange={(e) => {
                            handleChange(e);
                            formDateUpdateHandler({
                              [e.target.full_name]: e.target.value
                            });
                          }}
                          placeholder="Name"
                        />

                        {errors.full_name && touched.full_name && <IconError />}

                        {!errors.full_name && touched.full_name && (
                          <IconSuccess />
                        )}
                      </div>
                      {errors.full_name && touched.full_name && (
                        <Form.Control.Feedback
                          type="invalid"
                          className="position-absolute start-0 top-100"
                        >
                          {errors.full_name}
                        </Form.Control.Feedback>
                      )}
                    </div>
                  </Col>
                </Row>
              </Form.Group>
            </div>

            <div className="mb-4">
              <Form.Group className="position-relative">
                <Row className="justify-content-center align-items-center">
                  <Col
                    sm={4}
                    lg={3}
                    className="text-start text-sm-end mb-2 mb-sm-0"
                  >
                    <Form.Label className="col-form-label py-0">
                      Email
                    </Form.Label>
                  </Col>
                  <Col sm={8} lg={7}>
                    <div className="position-relative">
                      <div className="position-relative">
                        <Form.Control
                          type="text"
                          name="email"
                          tabIndex="2"
                          className={`${touched.email ? "is-touch " : ""} ${
                            errors.email && touched.email ? " is-invalid" : ""
                          } ${
                            !errors.email && touched.email ? " is-valid" : ""
                          }`}
                          value={values.email}
                          onBlur={handleBlur}
                          onChange={(e) => {
                            handleChange(e);
                            formDateUpdateHandler({
                              [e.target.name]: e.target.value
                            });
                          }}
                          placeholder="Email"
                        />

                        {errors.email && touched.email && <IconError />}

                        {!errors.email && touched.email && <IconSuccess />}
                      </div>
                      {errors.email && touched.email && (
                        <Form.Control.Feedback
                          type="invalid"
                          className="position-absolute start-0 top-100"
                        >
                          {errors.email}
                        </Form.Control.Feedback>
                      )}
                    </div>
                  </Col>
                </Row>
              </Form.Group>
            </div>

            <div className="mb-4">
              <Form.Group className="position-relative">
                <Row className="justify-content-center align-items-center">
                  <Col
                    sm={4}
                    lg={3}
                    className="text-start text-sm-end mb-2 mb-sm-0"
                  >
                    <Form.Label className="col-form-label py-0">
                      Mobile phone number
                    </Form.Label>
                  </Col>
                  <Col sm={8} lg={7}>
                    <div className="position-relative">
                      <div className="position-relative">
                        <Form.Control
                          type="text"
                          name="phone_number"
                          tabIndex="3"
                          className={`${
                            touched.phone_number ? "is-touch " : ""
                          } ${
                            errors.phone_number && touched.phone_number
                              ? " is-invalid"
                              : ""
                          } ${
                            !errors.phone_number && touched.phone_number
                              ? " is-valid"
                              : ""
                          }`}
                          value={values.phone_number}
                          onBlur={handleBlur}
                          onChange={(e) => {
                            handleChange(e);
                            formDateUpdateHandler({
                              [e.target.name]: e.target.value
                            });
                          }}
                          placeholder="Mobile phone number"
                        />

                        {errors.phone_number && touched.phone_number && (
                          <IconError />
                        )}

                        {!errors.phone_number && touched.phone_number && (
                          <IconSuccess />
                        )}
                      </div>
                      {errors.phone_number && touched.phone_number && (
                        <Form.Control.Feedback
                          type="invalid"
                          className="position-absolute start-0 top-100"
                        >
                          {errors.phone_number}
                        </Form.Control.Feedback>
                      )}
                    </div>
                  </Col>
                </Row>
              </Form.Group>
            </div>

            <div className="mb-4">
              <Form.Group className="position-relative">
                <Row className="justify-content-center align-items-center">
                  <Col
                    sm={4}
                    lg={3}
                    className="text-start text-sm-end mb-2 mb-sm-0"
                  >
                    <Form.Label className="col-form-label py-0">
                      Password
                    </Form.Label>
                  </Col>
                  <Col sm={8} lg={7}>
                    <Form.Group className="position-relative">
                      <div className="position-relative">
                        <Button
                          variant=""
                          className={`position-absolute top-0 end-0 h-100 ${
                            passwordType === "text" ? "text-secondary" : ""
                          }`}
                          onClick={reversePasswordType}
                        >
                          {passwordType === "password" && <IconEye />}
                          {passwordType === "text" && <IconEye />}
                        </Button>
                        <Form.Control
                          tabIndex="4"
                          className={`${touched.password ? "is-touch " : ""} ${
                            errors.password && touched.password
                              ? " is-invalid"
                              : ""
                          } ${
                            !errors.password && touched.password
                              ? " is-valid"
                              : ""
                          }`}
                          type={passwordType}
                          name="password"
                          value={values.password}
                          onBlur={handleBlur}
                          onChange={(e) => {
                            handleChange(e);
                            formDateUpdateHandler({
                              [e.target.name]: e.target.value
                            });
                          }}
                          placeholder="Password"
                        />

                        {errors.password && touched.password && <IconError />}

                        {!errors.password && touched.password && (
                          <IconSuccess />
                        )}
                      </div>

                      {errors.password && touched.password && (
                        <Form.Control.Feedback
                          type="invalid"
                          className="position-absolute start-0 top-100"
                        >
                          {errors.password}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
              </Form.Group>
            </div>

            <div className="mb-4">
              <Form.Group className="position-relative">
                <Row className="justify-content-center align-items-center">
                  <Col
                    sm={4}
                    lg={3}
                    className="text-start text-sm-end mb-2 mb-sm-0"
                  >
                    <Form.Label className="col-form-label py-0">
                      Password confirmation
                    </Form.Label>
                  </Col>
                  <Col sm={8} lg={7}>
                    <Form.Group className="position-relative">
                      <div className="position-relative">
                        <Button
                          variant=""
                          className={`position-absolute top-0 end-0 h-100 ${
                            confirmPasswordType === "text"
                              ? "text-secondary"
                              : ""
                          }`}
                          onClick={reverseConfirmPasswordType}
                        >
                          {confirmPasswordType === "password" && <IconEye />}
                          {confirmPasswordType === "text" && <IconEye />}
                        </Button>
                        <Form.Control
                          tabIndex="5"
                          className={`${
                            touched.password_confirm ? "is-touch " : ""
                          } ${
                            errors.password_confirm && touched.password_confirm
                              ? " is-invalid"
                              : ""
                          } ${
                            !errors.password_confirm && touched.password_confirm
                              ? " is-valid"
                              : ""
                          }`}
                          type={confirmPasswordType}
                          name="password_confirm"
                          value={values.password_confirm}
                          onBlur={handleBlur}
                          onChange={(e) => {
                            handleChange(e);
                            formDateUpdateHandler({
                              [e.target.name]: e.target.value
                            });
                          }}
                          placeholder="Password confirmation"
                        />

                        {errors.password_confirm &&
                          touched.password_confirm && <IconError />}

                        {!errors.password_confirm &&
                          touched.password_confirm && <IconSuccess />}
                      </div>

                      {errors.password_confirm && touched.password_confirm && (
                        <Form.Control.Feedback
                          type="invalid"
                          className="position-absolute start-0 top-100"
                        >
                          {errors.password_confirm}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
              </Form.Group>
            </div>

            <div className="mb-10">
              <Form.Group
                className="position-relative overflow-hidden"
                controlId="file"
              >
                <Row className="justify-content-center align-items-center">
                  <Col
                    sm={4}
                    lg={3}
                    className="text-start text-sm-end mb-2 mb-sm-0"
                  >
                    <Form.Label className="col-form-label py-0">
                      Image/Logo
                    </Form.Label>
                  </Col>
                  <Col sm={8} lg={7}>
                    <div className="position-relative">
                      <div className="file d-flex align-items-center justify-content-between flex-row">
                        <Form.Label
                          // htmlFor="file"
                          className="btn btn-light px-lg-4 ms-0 position-relative d-inline-flex align-items-center justify-content-center"
                        >
                          <span>Choose File</span>
                          <Form.Control
                            type="file"
                            className="position-absolute z--1 start-0 top-0 h-100"
                            accept="image/png, image/jpeg"
                            value={values.image}
                            onChange={(e) => {
                              setImage(e.target.files[0]);
                              resizeFile(e.target.files[0]);
                            }}
                          />
                        </Form.Label>
                        <span
                          className="form-label d-flex align-items-center justify-content-center ms-1"
                          id="file"
                        >
                          {image ? (
                            <>
                              <p className="p-0 m-0 text-truncate">
                                {image?.name}
                              </p>
                              <span
                                className="text-reset btn-close ms-2 cursor-pointer"
                                title="delete file"
                                onClick={() => {
                                  setImageBase64(null);
                                  setImage(null);
                                }}
                              >
                                {/* <span className="text-close"></span> */}
                              </span>
                            </>
                          ) : (
                            "No file chosen"
                          )}
                        </span>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Form.Group>
            </div>

            <div className="mb-4">
              <Form.Group className="position-relative">
                <Row className="justify-content-center align-items-center">
                  <Col
                    sm={4}
                    lg={3}
                    className="text-start text-sm-end mb-2 mb-sm-0"
                  >
                    <Form.Label className="col-form-label py-0">
                      Description
                    </Form.Label>
                  </Col>
                  <Col sm={8} lg={7}>
                    <div className="position-relative">
                      <div className="position-relative">
                        <Form.Control
                          as="textarea"
                          rows={4}
                          name="description"
                          tabIndex="6"
                          className={`${
                            touched.description ? "is-touch " : ""
                          } ${
                            errors.description && touched.description
                              ? " is-invalid"
                              : ""
                          } ${
                            !errors.description && touched.description
                              ? " is-valid"
                              : ""
                          }`}
                          value={values.description}
                          onBlur={handleBlur}
                          onChange={(e) => {
                            handleChange(e);
                            formDateUpdateHandler({
                              [e.target.name]: e.target.value
                            });
                          }}
                          placeholder="Description"
                        />

                        {errors.description && touched.description && (
                          <IconError />
                        )}

                        {!errors.description && touched.description && (
                          <IconSuccess />
                        )}
                      </div>
                      {errors.description && (
                        <Form.Control.Feedback
                          type="invalid"
                          className="position-absolute start-0 top-100"
                        >
                          {errors.description}
                        </Form.Control.Feedback>
                      )}
                    </div>
                  </Col>
                </Row>
              </Form.Group>
            </div>

            <div className="mb-4">
              <Form.Group className="position-relative">
                <Row className="justify-content-center align-items-center">
                  <Col
                    sm={4}
                    lg={3}
                    className="text-start text-sm-end mb-2 mb-sm-0"
                  >
                    <Form.Label className="col-form-label py-0">GST</Form.Label>
                  </Col>
                  <Col sm={8} lg={7}>
                    <div className="position-relative">
                      <div className="position-relative">
                        <Form.Control
                          type="text"
                          name="gst"
                          tabIndex="7"
                          className={`${touched.gst ? "is-touch " : ""} ${
                            errors.gst && touched.gst ? " is-invalid" : ""
                          } ${!errors.gst && touched.gst ? " is-valid" : ""}`}
                          value={values.gst}
                          onBlur={handleBlur}
                          onChange={(e) => {
                            handleChange(e);
                            formDateUpdateHandler({
                              [e.target.name]: e.target.value
                            });
                          }}
                          placeholder="GST"
                        />

                        {errors.gst && touched.gst && <IconError />}

                        {!errors.gst && touched.gst && <IconSuccess />}
                      </div>
                      {errors.gst && (
                        <Form.Control.Feedback
                          type="invalid"
                          className="position-absolute start-0 top-100"
                        >
                          {errors.gst}
                        </Form.Control.Feedback>
                      )}
                    </div>
                  </Col>
                </Row>
              </Form.Group>
            </div>

            <div className="mb-4">
              <Form.Group className="position-relative">
                <Row className="justify-content-center align-items-center">
                  <Col
                    sm={4}
                    lg={3}
                    className="text-start text-sm-end mb-2 mb-sm-0"
                  >
                    <Form.Label className="col-form-label py-0">PAN</Form.Label>
                  </Col>
                  <Col sm={8} lg={7}>
                    <div className="position-relative">
                      <div className="position-relative">
                        <Form.Control
                          type="text"
                          name="pan"
                          tabIndex="8"
                          className={`${touched.pan ? "is-touch " : ""} ${
                            errors.pan && touched.pan ? " is-invalid" : ""
                          } ${!errors.pan && touched.pan ? " is-valid" : ""}`}
                          value={values.pan}
                          onBlur={handleBlur}
                          onChange={(e) => {
                            handleChange(e);
                            formDateUpdateHandler({
                              [e.target.name]: e.target.value
                            });
                          }}
                          placeholder="PAN"
                        />

                        {errors.pan && touched.pan && <IconError />}

                        {!errors.pan && touched.pan && <IconSuccess />}
                      </div>
                      {errors.pan && (
                        <Form.Control.Feedback
                          type="invalid"
                          className="position-absolute start-0 top-100"
                        >
                          {errors.pan}
                        </Form.Control.Feedback>
                      )}
                    </div>
                  </Col>
                </Row>
              </Form.Group>
            </div>
            <div className="mb-4">
              <Form.Group className="position-relative">
                <Row className="justify-content-center pt-4">
                  <Col sm={8} lg={7}>
                    <Button
                      variant="primary"
                      type="submit"
                      className="w-100"
                      tabIndex="9"
                      disabled={(!isValid && dirty) || isLoading}
                    >
                      Register{isLoading && <Loader />}
                    </Button>
                    <Link
                      to={APP_ROUTE.LOGIN}
                      className="btn btn-link mt-12 d-block"
                    >
                      Already have an account?
                    </Link>
                  </Col>
                </Row>
              </Form.Group>
            </div>
          </Form>
        )}
      </Formik>
    </LayoutRegistration>
  );
}
