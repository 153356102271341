import { useEffect, useState } from "react";
import { Formik } from "formik";
import { Button, Form } from "react-bootstrap";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";

import LayoutLogin from "../../layout/LayoutLogin";
import { Loader } from "../../components";
import { APP_ROUTE, PASSWORD_REGEXP } from "../../utils/constants";
import { IconError, IconEye, IconSuccess } from "../../utils/svgHelper";
import { useResetConfirmPasswordNotLoginMutation } from "../../redux/services/authApi";

const ForgotYourPassword = () => {
  const [newPasswordType, setNewPasswordType] = useState("password");
  const [confirmNewPasswordType, setConfirmNewPasswordType] =
    useState("password");
  const [params, setParams] = useState({ uidb64: "", token: "" });
  const [resetConfirmPasswordNotLogin, { isLoading: isLoadingForgotPass }] =
    useResetConfirmPasswordNotLoginMutation();
  const [resPasswordTrue, setResPasswordTrue] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const paramsList = window.location.href.split("confirm/").pop().split("/");
    setParams({ uidb64: paramsList[0], token: paramsList[1] });
  }, []);
  const reverseNewPasswordType = () =>
    newPasswordType === "password"
      ? setNewPasswordType("text")
      : setNewPasswordType("password");

  const reverseConfirmNewPasswordType = () =>
    confirmNewPasswordType === "password"
      ? setConfirmNewPasswordType("text")
      : setConfirmNewPasswordType("password");

  const schema = yup.object().shape({
    newPassword: yup
      .string()
      .required("New password is a required field")
      .matches(PASSWORD_REGEXP, "Must contain at least 6 characters"),
    confirmNewPassword: yup
      .string()
      .required("Confirm new password is a required field")
      .matches(PASSWORD_REGEXP, "Must contain at least 6 characters")
      .oneOf(
        [yup.ref("newPassword"), null],
        "New password and Confirm new password must match"
      )
  });

  const handleSubmit = async (values, { setStatus, setErrors }) => {
    try {
      const { error } = await resetConfirmPasswordNotLogin({
        new_password1: values.newPassword,
        new_password2: values.confirmNewPassword,
        uid: params.uidb64,
        token: params.token
      });
      if (error) {
        setErrors({
          newPassword: error.data.errors[0].new_password1,
          confirmNewPassword: error.data.errors[0].new_password2
        });
        return;
      }
      setResPasswordTrue(true);
    } catch (err) {
      console.error(err);
      setStatus(err.message);
    }
  };

  return (
    <>
      <LayoutLogin>
        <Formik
          validateOnChange
          initialValues={{
            newPassword: "",
            confirmNewPassword: ""
          }}
          validationSchema={schema}
          onSubmit={handleSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            isValid,
            handleSubmit,
            dirty
          }) => (
            <Form className="form" onSubmit={handleSubmit}>
              <div className="text-center pb-4">
                <h1 className="h2 fw-bolder mb-4 mx-xl-n3 mt-xl-n3">
                  Reset password
                </h1>
              </div>
              {resPasswordTrue ? (
                <>
                  <div className="form-label fw-normal lh-base mb-4 ms-xl-n3 me-xl-n3">
                    We successfully reset your password.
                  </div>
                  <div className="text-center">
                    <Button
                      variant="primary"
                      type="submit"
                      className="w-100"
                      onClick={() => navigate(APP_ROUTE.LOGIN)}
                    >
                      Login
                    </Button>
                  </div>
                </>
              ) : (
                <>
                  <div className="mb-5">
                    <div className="mb-4">
                      <div className="position-relative">
                        <Form.Group className="position-relative mb-3">
                          {/* <Form.Label className="fs-6 lh-base col-form-label py-0">New password</Form.Label> */}
                          <div className="position-relative">
                            <Button
                              variant=""
                              className={`position-absolute top-0 end-0 h-100 ${
                                newPasswordType === "text"
                                  ? "text-secondary"
                                  : ""
                              }`}
                              onClick={reverseNewPasswordType}
                            >
                              {newPasswordType === "password" && <IconEye />}
                              {newPasswordType === "text" && <IconEye />}
                            </Button>
                            <Form.Control
                              className={`${
                                touched.newPassword ? "is-touch " : ""
                              } ${
                                errors.newPassword && touched.newPassword
                                  ? " is-invalid"
                                  : ""
                              } ${
                                !errors.newPassword && touched.newPassword
                                  ? " is-valid"
                                  : ""
                              }`}
                              type={newPasswordType}
                              name="newPassword"
                              value={values.newPassword}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              placeholder="New password"
                            />

                            {errors.newPassword && touched.newPassword && (
                              <IconError />
                            )}

                            {!errors.newPassword && touched.newPassword && (
                              <IconSuccess />
                            )}
                          </div>

                          {errors.newPassword && touched.newPassword && (
                            <Form.Control.Feedback type="invalid">
                              {errors.newPassword}
                            </Form.Control.Feedback>
                          )}
                        </Form.Group>

                        <Form.Group className="position-relative mb-3">
                          {/* <Form.Label className="fs-6 lh-base col-form-label py-0">Confirm new password</Form.Label> */}
                          <div className="position-relative">
                            <Button
                              variant=""
                              className={`position-absolute top-0 end-0 h-100 ${
                                confirmNewPasswordType === "text"
                                  ? "text-secondary"
                                  : ""
                              }`}
                              onClick={reverseConfirmNewPasswordType}
                            >
                              {confirmNewPasswordType === "password" && (
                                <IconEye />
                              )}
                              {confirmNewPasswordType === "text" && <IconEye />}
                            </Button>
                            <Form.Control
                              className={`${
                                touched.confirmNewPassword ? "is-touch " : ""
                              } ${
                                errors.confirmNewPassword &&
                                touched.confirmNewPassword
                                  ? " is-invalid"
                                  : ""
                              } ${
                                !errors.confirmNewPassword &&
                                touched.confirmNewPassword
                                  ? " is-valid"
                                  : ""
                              }`}
                              type={confirmNewPasswordType}
                              name="confirmNewPassword"
                              value={values.confirmNewPassword}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              placeholder="Confirm new password"
                            />

                            {errors.confirmNewPassword &&
                              touched.confirmNewPassword && <IconError />}

                            {!errors.confirmNewPassword &&
                              touched.confirmNewPassword && <IconSuccess />}
                          </div>
                          {errors.confirmNewPassword &&
                            touched.confirmNewPassword && (
                              <Form.Control.Feedback type="invalid">
                                {errors.confirmNewPassword}
                              </Form.Control.Feedback>
                            )}
                          {errors.token && (
                            <Form.Control.Feedback type="invalid">
                              {"Token is invalid or expired"}
                            </Form.Control.Feedback>
                          )}
                        </Form.Group>
                      </div>
                    </div>
                  </div>

                  <div className="text-center">
                    <Button
                      variant="primary"
                      type="submit"
                      className="w-100"
                      disabled={!(isValid && dirty) || isLoadingForgotPass}
                    >
                      Reset {isLoadingForgotPass && <Loader />}
                    </Button>
                  </div>
                </>
              )}
            </Form>
          )}
        </Formik>
        {!resPasswordTrue && (
          <a className="btn btn-link mt-12" href={APP_ROUTE.LOGIN}>
            Login
          </a>
        )}
      </LayoutLogin>
    </>
  );
};

export default ForgotYourPassword;
