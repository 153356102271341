import { Form, Dropdown } from "react-bootstrap";

import { PRICING_VISIBILITY } from "../../utils/constants";

export default function PricingVisibilityDropdown({ pv, onChange }) {
  const clearText = "Clear filter";
  const pricingVisibilityList = [
    PRICING_VISIBILITY.openView,
    PRICING_VISIBILITY.employerView,
    PRICING_VISIBILITY.closedView,
    clearText
  ];

  const clickHandler = (value) => {
    const newValue = value === clearText ? "" : value;
    onChange(newValue);
  };
  return (
    <Dropdown>
      <Dropdown.Toggle variant="" id="dropdown-view">
        View
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {pricingVisibilityList.map((v, i) => (
          <Dropdown.Item key={i} as="li" onClick={() => clickHandler(v)}>
            <Form.Check
              inline
              name={v}
              type="radio"
              checked={pv === v}
              label={v}
              onChange={() => clickHandler(v)}
            />
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}
