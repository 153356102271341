import { useEffect, useMemo } from "react";
import { Table, Form, Dropdown } from "react-bootstrap";

import { toFloat } from "../utils/helpersFunctions";
import IconItem from "../components/IconItem";

export default function TableBidSubmitUpdate({
  items,
  bids,
  grandTotal,
  onUpdate,
  isEditMode,
  errors,
  taxes,
  onTaxAdd,
  onTaxDelete,
  onRateChange,
  totalSummeryByBid,
  totalTaxPrice,
  totalPrice,
  totalTaxCount,
  allowTaxes
}) {
  return (
    <div className="table-rounded mb-30 shadow bg-white rounded-1 v-top ">
      <Table striped responsive>
        <thead>
          <tr>
            <th style={{ width: "68px" }}>S No</th>
            <th style={{ width: "118px" }}>Item Name</th>
            <th style={{ width: "128px" }}>Description</th>
            <th style={{ width: "98px" }}>Quantity</th>
            <th style={{ width: "70px" }}>Unit</th>
            <th style={{ width: "126px" }}>Item Details</th>
            <th style={{ width: "126px" }}>Price</th>
            <th style={{ width: "120px" }} hidden={!allowTaxes}>
              Tax Type
            </th>
            <th style={{ width: "120px" }} hidden={!allowTaxes}>
              Tax Rate %
            </th>
            <th style={{ width: "120px" }} hidden={!allowTaxes}>
              Tax
            </th>
            <th style={{ width: "120px" }}>Total Price</th>
          </tr>
        </thead>
        <tbody>
          {items?.map((item, index) => (
            <BidRow
              key={item.id}
              item={item}
              onUpdate={onUpdate}
              isEditMode={isEditMode}
              bid={bids.find((b) => b.item === item.id)}
              index={index}
              errors={errors}
              taxes={taxes}
              onTaxAdd={onTaxAdd}
              onTaxDelete={onTaxDelete}
              onRateChange={onRateChange}
              totalSummeryByBid={totalSummeryByBid}
              allowTaxes={allowTaxes}
            />
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan="6" style={{ width: "608px" }}>
              {" "}
              <div className="fw-600">Total</div>
            </td>
            <td style={{ width: "126px" }}>{totalPrice}</td>

            <td style={{ width: "120px" }} hidden={!allowTaxes}>
              &nbsp;
            </td>
            <td style={{ width: "120px" }} hidden={!allowTaxes}>
              {totalTaxCount}
            </td>
            <td style={{ width: "120px" }} hidden={!allowTaxes}>
              {totalTaxPrice}
            </td>
            <td style={{ width: "120px" }}>{grandTotal}</td>
          </tr>
        </tfoot>
      </Table>
    </div>
  );
}

function BidRow({
  item,
  onUpdate,
  isEditMode,
  bid,
  index,
  errors,
  taxes,
  onTaxAdd,
  onTaxDelete,
  onRateChange,
  totalSummeryByBid,
  allowTaxes
}) {
  const priceError = useMemo(() => {
    if (errors && errors.items_bids && errors.items_bids[index]) {
      return errors.items_bids[index].price;
    }
    return "";
  }, [errors]);

  const taxesErrorsList = useMemo(() => {
    if (errors && errors.items_bids && errors.items_bids[index]) {
      return errors.items_bids[index].taxes;
    }
    return [];
  }, [errors]);

  useEffect(() => {
    document.addEventListener("mousewheel", function (event) {
      if (document.activeElement.type === "number") {
        document.activeElement.blur();
      }
    });
  }, []);

  const changeBidPriceHandler = (e) => {
    const price = e.target.value ? Number(e.target.value) : null;
    const taxRateSum = bid.taxes
      .map((t) => t.tax_rate)
      .reduce((prevValue, curValue) => prevValue + curValue, 0);
    onUpdate({
      ...bid,
      id: bid.id,
      item: item.id,
      price,
      total_price: price
        ? toFloat(price * Number(item.quantity) * (1 + taxRateSum / 100))
        : null,
      taxes: bid.taxes.map((t) => ({
        ...t,
        tax_amount: price
          ? toFloat(item.quantity * price * (t.tax_rate / 100))
          : 0
      }))
    });
  };

  const changeBidTaxHandler = (e, t) => {
    const taxRate = e.target.value ? Number(e.target.value) : null;
    onRateChange({
      itemId: item.id,
      tax: {
        ...t,
        tax_rate: taxRate,
        tax_amount: bid.price
          ? toFloat(item.quantity * bid.price * (taxRate / 100))
          : 0
      }
    });
  };

  const isBidTaxes = useMemo(() => bid?.taxes, [bid]);

  return (
    <tr>
      <td style={{ width: "68px" }}>{index + 1}</td>
      <td style={{ width: "118px" }}>{item.name}</td>
      <td style={{ width: "128px" }}>{item.description}</td>
      <td style={{ width: "98px" }}>{item.quantity}</td>
      <td style={{ width: "70px" }}>{item.unit}</td>
      <td style={{ width: "126px" }}>
        {isEditMode ? (
          <Form.Control
            type="text"
            className="w-100"
            value={bid.details}
            onChange={(e) => onUpdate({ ...bid, details: e.target.value })}
          />
        ) : (
          <div>{bid.details}</div>
        )}
      </td>
      <td style={{ width: "126px" }}>
        {isEditMode ? (
          <>
            <Form.Control
              type="number"
              className={`w-100 ${priceError ? "is-invalid" : ""}`}
              value={bid.price}
              onChange={changeBidPriceHandler}
              min="0"
            />
            {priceError && (
              <Form.Control.Feedback type="invalid" className="lh-1">
                {priceError}
              </Form.Control.Feedback>
            )}
          </>
        ) : (
          <div>{bid.price}</div>
        )}
      </td>
      <td style={{ width: "120px" }} hidden={!allowTaxes}>
        {isEditMode ? (
          <Dropdown className="dropdown-select">
            <Dropdown.Toggle variant="link" id="dropdown-tax-type">
              <span>Tax Type</span>
              <IconItem icon="down" />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {isBidTaxes &&
                taxes
                  .filter(
                    (tax) =>
                      !bid.taxes.map((t) => t.tax.name).includes(tax.name)
                  )
                  .map((tax) => (
                    <Dropdown.Item
                      as="li"
                      key={`${bid.id}${tax.id}`}
                      onClick={() => {
                        onTaxAdd({
                          itemId: item.id,
                          tax: {
                            tax,
                            tax_rate: 0,
                            tax_amount: 0
                          }
                        });
                      }}
                    >
                      <Form.Check
                        inline
                        name="public"
                        type="radio"
                        label={tax.name}
                      />
                    </Dropdown.Item>
                  ))}
            </Dropdown.Menu>
            <ul className="list-unstyled mb-0">
              {isBidTaxes &&
                bid.taxes.map((t) => (
                  <li
                    key={`chosentax${t.tax.id}`}
                    className="mb-1 d-flex align-items-center justify-content-between"
                  >
                    <span>{t.tax.name}</span>
                    <svg
                      className="icon icon-close text-secondary"
                      onClick={() =>
                        onTaxDelete({ itemId: item.id, tax: t.tax.id })
                      }
                    >
                      <use xlinkHref="/sprite.svg#icon-close"></use>
                    </svg>
                  </li>
                ))}
            </ul>
          </Dropdown>
        ) : (
          <ul className="list-unstyled mb-0">
            {isBidTaxes &&
              bid.taxes.map((t) => (
                <li
                  key={`chosentax${t.tax.id}`}
                  className="mb-1 d-flex align-items-center justify-content-between"
                >
                  <span>{t.tax.name}</span>
                </li>
              ))}
          </ul>
        )}
      </td>
      <td style={{ width: "120px" }} hidden={!allowTaxes}>
        {isEditMode ? (
          <>
            <div className="h-26">&nbsp;</div>
            {isBidTaxes &&
              bid.taxes.map((t, i) => (
                <div className="mb-1" key={`chosentaxrateedit${t.tax.id}`}>
                  <Form.Control
                    type="number"
                    className={`w-75 ${
                      taxesErrorsList && taxesErrorsList[i]?.tax_rate
                        ? "is-invalid"
                        : ""
                    }`}
                    value={t.tax_rate}
                    onChange={(e) => changeBidTaxHandler(e, t)}
                    min="0"
                  />
                  {taxesErrorsList && taxesErrorsList[i]?.tax_rate && (
                    <Form.Control.Feedback type="invalid" className="lh-1">
                      {taxesErrorsList[i].tax_rate}
                    </Form.Control.Feedback>
                  )}
                </div>
              ))}
          </>
        ) : (
          <>
            {isBidTaxes &&
              bid.taxes.map((t) => (
                <div className="mb-1" key={`chosentaxrate${t.tax.id}`}>
                  {t.tax_rate}
                </div>
              ))}
          </>
        )}
      </td>
      <td style={{ width: "120px" }} hidden={!allowTaxes}>
        {isEditMode && <div className="h-26">&nbsp;</div>}
        {isBidTaxes &&
          bid.taxes.map((t) => (
            <div className="mb-1" key={`chosentaxamount${t.tax.id}`}>
              {t.tax_amount}
            </div>
          ))}
      </td>
      <td style={{ width: "120px" }}>{totalSummeryByBid[index].grandTotal}</td>
    </tr>
  );
}
