import { useEffect, useState } from "react";
import { Table } from "react-bootstrap";

import { useActivityLogsMutation } from "../../redux/services/authApi";
import { transformationTime } from "../../utils/helpersFunctions";
import { Loader, Pagination } from "../../components";

export default function Settings() {
  const [activityLogs, { isLoading: isLoadingActivityLogs }] =
    useActivityLogsMutation();
  const [activityLogList, setActivityLogList] = useState([]);

  const [countPage, setCountPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    async function fetchData() {
      const { data } = await activityLogs(currentPage);
      setActivityLogList(data.data.results);
      setCountPage(data.data.count);
    }
    fetchData();
  }, [currentPage]);

  return (
    <>
      <h1 className="h1 fw-bolder mb-14">Activity Log</h1>
      <div className="mb-3 mx-n2 mx-lg-n4">
        <Table>
          <thead>
            <tr>
              <th className="ps-2 ps-lg-4">Date and Time</th>
              <th className="ps-2 ps-lg-4">Activity</th>
            </tr>
          </thead>
          <tbody>
            {isLoadingActivityLogs ? (
              <Loader />
            ) : activityLogList.length === 0 ? (
              <tr>
                <td colSpan="4" className="ps-2 ps-lg-4">
                  <h6 className="fsz-18 mb-10">No activity log</h6>
                </td>
              </tr>
            ) : (
              activityLogList?.map((item) => (
                <tr key={item.id}>
                  <td className="ps-2 ps-lg-4">
                    {transformationTime(item.created_at)}
                  </td>
                  <td className="ps-2 ps-lg-4">{item.text}</td>
                </tr>
              ))
            )}
          </tbody>
        </Table>
        {(activityLogList.length > 9 || currentPage !== 1) && (
          <Pagination
            itemsCount={countPage}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        )}
      </div>
    </>
  );
}
