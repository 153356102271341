import { Table } from "react-bootstrap";

export default function TableVendor({ vendorList, bidsList }) {
  return (
    <div className="table-rounded mb-30 shadow bg-white rounded-1">
      <Table striped responsive>
        <thead>
          <tr>
            <th className="text-center">
              <div>Vendor</div>
            </th>
            <th>
              <div>Number of Lowest Prices</div>
            </th>
          </tr>
        </thead>
        <tbody>
          {vendorList?.map((vendor) => (
            <VendorRow
              key={`vendor${vendor.id}`}
              vendor={vendor}
              bidsList={bidsList}
            />
          ))}
        </tbody>
      </Table>
    </div>
  );
}

function VendorRow({ vendor, bidsList }) {
  return (
    <tr>
      <td className="text-center">
        <div>{vendor.full_name}</div>
      </td>
      <td>
        <div>{bidsList.filter((b) => b?.owner?.id === vendor.id).length}</div>
      </td>
    </tr>
  );
}
