import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { apiBaseUrl } from "../../utils/makeUrl";

import { APP_ROUTE, AUTH_API, API_METHODS } from "../../utils/constants";

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({
    baseUrl: apiBaseUrl,
    headers: {
      "content-type": "application/json"
    },
    prepareHeaders: (headers, { getState }) => {
      const { token } = getState().userStore;
      if (token?.access) {
        headers.set("Authorization", `Bearer ${token.access}`);
      }
      return headers;
    }
  }),
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: AUTH_API.LOGIN,
        method: API_METHODS.post,
        body: credentials
      })
    }),
    registerUser: builder.mutation({
      query: (credentials) => ({
        url: AUTH_API.SIGNUP,
        method: API_METHODS.post,
        body: credentials
      })
    }),
    getMe: builder.mutation({
      query: () => ({
        url: AUTH_API.ME,
        method: API_METHODS.get
      })
    }),
    patchProfile: builder.mutation({
      query: (body) => ({
        url: AUTH_API.ME,
        method: API_METHODS.patch,
        body
      })
    }),
    verifyCode: builder.mutation({
      query: (body) => ({
        url: "verify-code/",
        method: API_METHODS.post,
        body
      })
    }),
    resetConfirmPasswordNotLogin: builder.mutation({
      query: (body) => ({
        url: `${APP_ROUTE.PASSWORD_RESET_CONFIRM}${body.uid}/${body.token}/`,
        method: API_METHODS.post,
        body
      })
    }),
    resendCode: builder.mutation({
      query: (body) => ({
        url: "resend-code/",
        method: API_METHODS.post,
        body
      })
    }),
    changePassword: builder.mutation({
      query: (body) => ({
        url: AUTH_API.PASSWORD_CHANGE,
        method: API_METHODS.post,
        body
      })
    }),
    contactUs: builder.mutation({
      query: (body) => ({
        url: AUTH_API.CONTACT_US,
        method: API_METHODS.post,
        body
      })
    }),
    // notifications ********************************************************
    getNotifications: builder.mutation({
      query: () => ({
        url: "notifications/",
        method: API_METHODS.get
      })
    }),
    getNotificationsSettings: builder.mutation({
      query: () => ({
        url: AUTH_API.NOTIFICATION_SETTINGS,
        method: API_METHODS.get
      })
    }),
    updateNotificationsSettings: builder.mutation({
      query: (body) => ({
        url: AUTH_API.NOTIFICATION_SETTINGS,
        method: API_METHODS.put,
        body
      })
    }),
    getEmailNotifications: builder.mutation({
      query: () => ({
        url: AUTH_API.EMAIL_SETTINGS,
        method: API_METHODS.get
      })
    }),
    updateEmailNotifications: builder.mutation({
      query: (body) => ({
        url: AUTH_API.EMAIL_SETTINGS,
        method: API_METHODS.put,
        body
      })
    }),
    getSmsNotifications: builder.mutation({
      query: () => ({
        url: AUTH_API.SMS_SETTINGS,
        method: API_METHODS.get
      })
    }),
    updateSmsNotifications: builder.mutation({
      query: (body) => ({
        url: AUTH_API.SMS_SETTINGS,
        method: API_METHODS.put,
        body
      })
    }),
    activityLogs: builder.mutation({
      query: (page) => ({
        url: `/activity_logs/?page=${page}`,
        method: API_METHODS.get
      })
    }),
    notifications: builder.mutation({
      query: (page) => ({
        url: `/notifications/?page=${page}`,
        method: API_METHODS.get
      })
    }),
    // mail ********************************************************
    getStats: builder.mutation({
      query: () => ({
        url: "/stats/",
        method: API_METHODS.get
      })
    }),
    mailInbox: builder.mutation({
      query: (page) => ({
        url: `/inbox/?page=${page}`,
        method: API_METHODS.get
      })
    }),
    mailOutbox: builder.mutation({
      query: (page) => ({
        url: `/outbox/?page=${page}`,
        method: API_METHODS.get
      })
    }),
    mailSendMessage: builder.mutation({
      query: (body) => ({
        url: AUTH_API.SEND_MESSAGE,
        method: API_METHODS.post,
        body
      })
    }),
    mailReadMessage: builder.mutation({
      query: (id) => ({
        url: `/message/${id}/`,
        method: API_METHODS.get
      })
    }),
    mailDeleteMessage: builder.mutation({
      query: (id) => ({
        url: `/message/${id}/`,
        method: API_METHODS.delete
      })
    }),
    mailDeleteTrashMessage: builder.mutation({
      query: (id) => ({
        url: `/message/${id}/erase/`,
        method: API_METHODS.delete
      })
    }),
    mailTrashBox: builder.mutation({
      query: (page) => ({
        url: `/trashbox/?page=${page}`,
        method: API_METHODS.get
      })
    }),
    recoverMessage: builder.mutation({
      query: (id) => ({
        url: `/message/${id}/`,
        method: API_METHODS.post
      })
    }),
    // password ********************************************************
    passwordReset: builder.mutation({
      query: ({ body }) => ({
        url: AUTH_API.PASSWORD_RESET,
        method: API_METHODS.post,
        body
      })
    }),
    // profile image ****************************************************
    updateProfileImage: builder.mutation({
      query: ({ body }) => ({
        url: "me/image/",
        headers: {
          "content-disposition": "attachment; filename=upload.jpg",
          "content-type": "image/jpeg"
        },
        method: API_METHODS.patch,
        body
      })
    }),
    deleteProfileImage: builder.mutation({
      query: () => ({
        url: "me/image/",
        method: API_METHODS.delete
      })
    }),
    usersInfo: builder.mutation({
      query: (id) => ({
        url: `users/${id}`,
        method: API_METHODS.get
      })
    })
  })
});

export const {
  useLoginMutation,
  useGetUserQuery,
  useRegisterUserMutation,
  useGetMeMutation,
  usePatchProfileMutation,
  useVerifyCodeMutation,
  useResendCodeMutation,
  useChangePasswordMutation,
  useContactUsMutation,
  useActivityLogsMutation,
  useResetConfirmPasswordNotLoginMutation,

  useNotificationsMutation,
  useGetStatsMutation,
  useMailInboxMutation,
  useMailOutboxMutation,
  useMailSendMessageMutation,
  useMailReadMessageMutation,
  useMailDeleteMessageMutation,
  useMailDeleteTrashMessageMutation,
  useMailTrashBoxMutation,
  useRecoverMessageMutation,

  useFetchUserMutation,
  useCheckPasswordMutation,
  useResetPasswordMutation,
  useResetConfirmPasswordMutation,

  useGetNotificationsMutation,
  useGetNotificationsSettingsMutation,
  useUpdateNotificationsSettingsMutation,
  useGetEmailNotificationsMutation,
  useUpdateEmailNotificationsMutation,
  useGetSmsNotificationsMutation,
  useUpdateSmsNotificationsMutation,

  usePasswordResetMutation,
  useUpdateProfileImageMutation,
  useDeleteProfileImageMutation,

  useUsersInfoMutation
} = authApi;
