// import { Redirect } from "react-router";
import { Route, Routes, Navigate } from "react-router-dom";

import Home from "./views/Home/Home";
import ContactUs from "./views/ContactUs/ContactUs";
import FAQ from "./views/FAQ/FAQ";
import Blog from "./views/Blog/Blog";
import Post from "./views/Blog/Post";

// Registration
import Login from "./views/Registration/Login";
import ResetPassword from "./views/Registration/ResetPassword";
import SignUp from "./views/Registration/SignUp";
import Registration from "./views/Registration/Registration";
import CompleteRegistration from "./views/Registration/CompleteRegistration";
import ForgotYourPassword from "./views/ForgotPassword/ForgotYourPassword";

// Contractor
import Bids from "./views/Contractor/Bids";
import BidSubmit from "./views/Contractor/BidSubmit";
import BidSubmitWrapped from "./views/Contractor/BidSubmitWrapped";
import BidSearch from "./views/Contractor/BidSearch";

import Profile from "./views/Profile/Profile";
import ProfileOf from "./views/Profile/ProfileOf";
import ProfileChange from "./views/Profile/ProfileChange";
import Notifications from "./views/Notifications/Notifications";

import MailInbox from "./views/Mail/MailInbox";
import MailSentMessages from "./views/Mail/MailSentMessages";
import MailNewMessages from "./views/Mail/MailNewMessages";
import MailTrash from "./views/Mail/MailTrash";

import SettingsChangePassword from "./views/Settings/SettingsChangePassword";
import NotificationsSettings from "./views/Settings/NotificationsSettings";
import EmailNotificationsSettings from "./views/Settings/EmailNotificationsSettings";
import SmsNotificationsSettings from "./views/Settings/SmsNotificationsSettings";
import SettingsLog from "./views/Settings/SettingsLog";

import PoWoContractor from "./views/Contractor/PoWo";

// Employer
import Tenders from "./views/Employer/Tenders";
import TenderSearch from "./views/Employer/ProviderSearch";
import TenderLive from "./views/Employer/TenderLive";
import TenderDetail from "./views/Employer/TenderDetail";
import TenderStepsBuilder from "./views/Employer/tenderSteps/TenderStepsBuilder";
import MailInboxOpenMessage from "./views/Mail/MailInboxOpenMessage";

import PoWoEmployer from "./views/Employer/PoWo";
import { APP_ROUTE } from "./utils/constants";

export const ContractorRoutes = () => {
  return (
    <Routes>
      <Route path={APP_ROUTE.CONTRACTOR_BIDS} element={<Bids />} />
      <Route
        path={`${APP_ROUTE.CONTRACTOR_BID_SUBMIT}/:id`}
        element={<BidSubmit />}
      />
      <Route path={`${APP_ROUTE.TENDER}/:id`} element={<BidSubmit />} />
      <Route
        path={APP_ROUTE.CONTRACTOR_TENDER_SEARCH}
        element={<BidSearch />}
      />
      <Route path={APP_ROUTE.PROFILE} element={<Profile />} />
      <Route path={APP_ROUTE.PROFILE_CHANGE} element={<ProfileChange />} />
      <Route path={APP_ROUTE.NOTIFICATIONS} element={<Notifications />} />
      <Route path={APP_ROUTE.INBOX} element={<MailInbox />} />
      <Route path={APP_ROUTE.EMPLOYER_PROFILE} element={<ProfileOf />} />
      <Route
        path={APP_ROUTE.INBOX_MESSAGE}
        element={<MailInboxOpenMessage />}
      />
      <Route path={APP_ROUTE.SENT_MESSAGES} element={<MailSentMessages />} />
      <Route path={APP_ROUTE.NEW_MESSAGE} element={<MailNewMessages />} />
      <Route path={APP_ROUTE.MAIL_TRASH} element={<MailTrash />} />
      <Route
        path={APP_ROUTE.CHANGE_PASSWORD}
        element={<SettingsChangePassword />}
      />
      <Route
        path={APP_ROUTE.SETTINGS_NOTIFICATIONS}
        element={<NotificationsSettings />}
      />
      <Route
        path={APP_ROUTE.SETTINGS_EMAIL}
        element={<EmailNotificationsSettings />}
      />
      <Route
        path={APP_ROUTE.SETTINGS_SMS}
        element={<SmsNotificationsSettings />}
      />
      <Route path={APP_ROUTE.SETTINGS_LOG} element={<SettingsLog />} />
      <Route path={APP_ROUTE.CONTACT_US} element={<ContactUs />} />
      <Route path={APP_ROUTE.FAQ} element={<FAQ />} />
      <Route
        path={APP_ROUTE.ENTRY}
        element={<Navigate replace to={APP_ROUTE.CONTRACTOR_BIDS} />}
      />
      <Route path={APP_ROUTE.POWO_CONTRACTOR} element={<PoWoContractor />} />
    </Routes>
  );
};

export const EmployerRoutes = () => {
  return (
    <Routes>
      <Route path={APP_ROUTE.CONTRACTOR_PROFILE} element={<ProfileOf />} />
      <Route path={APP_ROUTE.EMPLOYER_TENDERS} element={<Tenders />} />
      <Route
        path={APP_ROUTE.EMPLOYER_TENDER_NEW}
        element={<TenderStepsBuilder />}
      />
      <Route
        path={APP_ROUTE.EMPLOYER_TENDER_EDIT}
        element={<TenderStepsBuilder />}
      />
      <Route
        path={APP_ROUTE.EMPLOYER_PROVIDER_SEARCH}
        element={<TenderSearch />}
      />
      <Route path={APP_ROUTE.EMPLOYER_TENDER_INVITE} element={<TenderLive />} />
      <Route
        path={`${APP_ROUTE.EMPLOYER_TENDER_DETAIL}/:id`}
        element={<TenderDetail />}
      />
      <Route path={APP_ROUTE.PROFILE} element={<Profile />} />
      <Route path={APP_ROUTE.PROFILE_CHANGE} element={<ProfileChange />} />
      <Route path={APP_ROUTE.NOTIFICATIONS} element={<Notifications />} />
      <Route path={APP_ROUTE.INBOX} element={<MailInbox />} />
      <Route
        path={APP_ROUTE.INBOX_MESSAGE}
        element={<MailInboxOpenMessage />}
      />
      <Route path={APP_ROUTE.SENT_MESSAGES} element={<MailSentMessages />} />
      <Route path={APP_ROUTE.NEW_MESSAGE} element={<MailNewMessages />} />
      <Route path={APP_ROUTE.MAIL_TRASH} element={<MailTrash />} />
      <Route
        path={APP_ROUTE.CHANGE_PASSWORD}
        element={<SettingsChangePassword />}
      />
      <Route
        path={APP_ROUTE.SETTINGS_NOTIFICATIONS}
        element={<NotificationsSettings />}
      />
      <Route
        path={APP_ROUTE.SETTINGS_EMAIL}
        element={<EmailNotificationsSettings />}
      />
      <Route
        path={APP_ROUTE.SETTINGS_SMS}
        element={<SmsNotificationsSettings />}
      />
      <Route path={APP_ROUTE.SETTINGS_LOG} element={<SettingsLog />} />
      <Route path={APP_ROUTE.CONTACT_US} element={<ContactUs />} />
      <Route path={APP_ROUTE.FAQ} element={<FAQ />} />
      <Route
        path={APP_ROUTE.ENTRY}
        element={<Navigate replace to={APP_ROUTE.EMPLOYER_TENDERS} />}
      />
      <Route path={APP_ROUTE.POWO_EMPLOYER} element={<PoWoEmployer />} />
    </Routes>
  );
};

export const RegistrationRoutes = () => {
  return (
    <Routes>
      <Route path={APP_ROUTE.HOME} element={<Home />} />
      <Route path={APP_ROUTE.CONTACT_US} element={<ContactUs />} />
      <Route path={APP_ROUTE.FAQ} element={<FAQ />} />
      <Route path={APP_ROUTE.BLOG} element={<Blog />} />
      <Route path={APP_ROUTE.POST} element={<Post />} />
      <Route path={APP_ROUTE.LOGIN} element={<Login />} />
      <Route path={APP_ROUTE.FORGOT} element={<ResetPassword />} />
      <Route path={APP_ROUTE.SIGNUP} element={<SignUp />} />
      <Route path={APP_ROUTE.REGISTRATION} element={<Registration />} />
      <Route
        path={APP_ROUTE.COMPLETE_REGISTRATION}
        element={<CompleteRegistration />}
      />
      <Route path={`${APP_ROUTE.TENDER}/:id`} element={<BidSubmitWrapped />} />
      <Route
        path={`${APP_ROUTE.PASSWORD_RESET_CONFIRM}/*`}
        element={<ForgotYourPassword />}
      />
      <Route path={APP_ROUTE.ENTRY} element={<Navigate replace to="/" />} />
    </Routes>
  );
};
