import { useEffect, useState, useMemo } from "react";
import { useParams } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import parser from "html-react-parser";
import LayoutHome from "../../layout/LayoutHome";
import BlogRelated from "./BlogRelated";
import { Loader, Share } from "../../components";
import IconItem from "../../components/IconItem";

import { useGetPostMutation } from "../../redux/services/blogApi";

export default function Post() {
  const [postData, setPostData] = useState({});

  const { postSlug } = useParams();

  const [getPost, { isLoading }] = useGetPostMutation();

  const publish = useMemo(() => {
    const day = new Date(postData.published_at);
    const mmStr = day.toLocaleString("en-us", { month: "long" });
    const dd = String(new Date(day).getDate()).padStart(2, "0");
    const YYYY = String(new Date(day).getFullYear());

    return dd + " " + mmStr + " " + YYYY;
  }, [postData]);

  useEffect(() => {
    async function fetchData() {
      const { data } = await getPost(postSlug);
      setPostData(data.data);
    }
    fetchData();
  }, [postSlug]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <LayoutHome>
        <div className="blog">
          <div className="blog-single">
            <Share />
            <div className="bg-light bg-intro">
              <Row className="justify-content-center">
                <Col xs={11}>
                  <div className="card post">
                    {postData?.image && (
                      <img
                        src={postData?.image}
                        alt=""
                        className="card-img-top"
                      />
                    )}

                    <div className="card-body">
                      <div className="card-body-inner">
                        <div className="d-flex mb-3">
                          {postData?.author && (
                            <div className="d-flex align-items-center me-3 me-lg-4 me-xl-40">
                              <IconItem icon="user-avatar" className="sz-4 me-2" />
                              <span className="text-primary">
                                {postData?.author}
                              </span>
                            </div>
                          )}
                          {postData?.published_at && (
                            <div className="d-flex align-items-center me-3 me-lg-4 me-xl-40">
                              <IconItem icon="calendar-fill" className="sz-4 me-2" />
                              <span className="text-primary">{publish}</span>
                            </div>
                          )}
                        </div>

                        <h2 className="card-title">{postData?.title}</h2>

                        <div className="card-text">
                          {postData &&
                            postData.body &&
                            parser(postData.body, { trim: true })}
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>

          {postData?.related?.length > 1 && (
            <div className="bg-white">
              <Row className="justify-content-center">
                <Col xs={11}>
                  <BlogRelated postsRelated={postData?.related} />
                </Col>
              </Row>
            </div>
          )}
        </div>
      </LayoutHome>
    </>
  );
}
