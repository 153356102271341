import cloneDeep from "lodash.clonedeep";

import { toFloat } from "../../../utils/helpersFunctions";

export const getTotalSummeryByBid = ({ bids, tenderDetails }) =>
  bids.map((bid) => {
    const quantity = tenderDetails?.items
      ? tenderDetails?.items.find((i) => i.id === bid.item)?.quantity
      : 0;
    const totalTaxCount =
      bid?.taxes && bid.taxes.length
        ? bid.taxes
            .map((tax) => tax.tax_rate)
            .reduce((prevValue, curValue) => prevValue + curValue, 0)
        : 0;

    const totalTax =
      bid?.taxes && bid.taxes.length
        ? bid.taxes
            .map((tax) => tax.tax_amount)
            .reduce((prevValue, curValue) => prevValue + curValue, 0)
        : 0;

    const totalPrice = toFloat(bid.price * quantity);

    return {
      totalPrice,
      totalTax,
      totalTaxCount,
      grandTotal: toFloat(totalPrice + totalTax)
    };
  });

export const deleteTax = ({ obj, tenderDetails, bids, setBids }) => {
  const bidIndex = bids.findIndex((b) => b.item === obj.itemId);
  const newBids = cloneDeep(bids);
  newBids[bidIndex] = {
    ...newBids[bidIndex],
    taxes: newBids[bidIndex].taxes.filter((t) => t.tax.id !== obj.tax)
  };
  const taxRateSum = newBids[bidIndex].taxes
    .map((t) => t.tax_rate)
    .reduce((prevValue, curValue) => prevValue + curValue, 0);
  const quantity = tenderDetails?.items.find(
    (i) => i.id === newBids[bidIndex].item
  ).quantity;
  newBids[bidIndex].total_price = newBids[bidIndex].price
    ? toFloat(
        newBids[bidIndex].price * toFloat(quantity) * (1 + taxRateSum / 100)
      )
    : null;
  setBids([...newBids]);
};

export const rateChange = ({ obj, bids, tenderDetails, setBids }) => {
  const bidIndex = bids.findIndex((b) => b.item === obj.itemId);
  const taxIndex = bids[bidIndex].taxes.findIndex((t) => t.tax === obj.tax.tax);
  const newBids = cloneDeep(bids);
  if (taxIndex >= 0) {
    newBids[bidIndex].taxes[taxIndex] = obj.tax;
    const taxRateSum = newBids[bidIndex].taxes
      .map((t) => t.tax_rate)
      .reduce((prevValue, curValue) => prevValue + curValue, 0);
    const quantity = tenderDetails?.items.find(
      (i) => i.id === newBids[bidIndex].item
    ).quantity;
    newBids[bidIndex].total_price = newBids[bidIndex].price
      ? toFloat(
          newBids[bidIndex].price * parseFloat(quantity) * (1 + taxRateSum / 100)
        )
      : null;
  } else {
    newBids[bidIndex].taxes.push(obj.tax);
  }
  setBids([...newBids]);
};

export const cancelBid = ({
  tenderDetails,
  addTehBids,
  setBids,
  setEditMode
}) => {
  const myBid = tenderDetails.bids.find((b) => b.is_bid_owner === true);
  let newItemsBids = [];
  if (tenderDetails.items.length !== myBid.items_bids.length) {
    newItemsBids = addTehBids(
      tenderDetails.items.filter(
        ({ id }) => !myBid.items_bids.some(({ item }) => id === item)
      )
    );
  }
  setBids([...myBid.items_bids, ...newItemsBids]);
  setEditMode(false);
};
