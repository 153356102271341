import { Modal } from "react-bootstrap";

export default function ModalSaveForLater({ show, onHide }) {
  return (
    <>
      <Modal show={show} onHide={onHide} size="xs">
        <Modal.Header closeButton className="pb-0">
          <Modal.Title>&nbsp;</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <div className="text-center">
            Your tender has been saved. <br/> You can access it later from the <br/> <strong>&ldquo;My Tenders&rdquo;</strong> page.
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
