import React, { useState, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import { Formik } from "formik";
// import * as yup from "yup";

import {
  useGetMeMutation,
  useLoginMutation,
  useResendCodeMutation
} from "../../redux/services/authApi";
import { APP_ROUTE } from "../../utils/constants";
// import { emailVerification } from "../../utils/yupHelper";
import { IconError, IconEye, IconSuccess } from "../../utils/svgHelper";
import { Loader } from "../../components";

export default function LoginForm() {
  const [passwordType, setPasswordType] = useState("password");

  const navigate = useNavigate();

  const [getMe] = useGetMeMutation();
  const [resendCode, { isLoading: isResendLoading }] = useResendCodeMutation();
  const [login, { isLoading: isLoginLoading }] = useLoginMutation();
  const isLoading = isLoginLoading || isResendLoading;

  // const schema = yup.object().shape({
  //   email: emailVerification,
  //   password: yup.string().required("Password is a required field")
  // });

  const reversePasswordType = () =>
    passwordType === "password"
      ? setPasswordType("text")
      : setPasswordType("password");

  const handleSubmit = useCallback(
    async (values, { setErrors }) => {
      try {
        const email = values.email.trim().toLowerCase();
        const { error } = await login({
          email,
          password: values.password
        });
        if (error) {
          if (Object.keys(error.data.errors[0])[0] === "detail") {
            const isConfirmNeeded = error.data.errors.some((e) =>
              e.detail.some((de) => de.includes("confirm your account firstly"))
            );
            if (isConfirmNeeded) {
              await resendCode({ email });
              return navigate(APP_ROUTE.COMPLETE_REGISTRATION, {
                state: { email }
              });
            }
            const emailError = { email: "" };
            emailError.email = Object.values(error.data.errors[0])[0];
            setErrors(emailError);
          }
          return;
        }
        await getMe();
      } catch (e) {
        console.log("Unhandled login error :", e);
      }

      navigate(APP_ROUTE.EMPLOYER_TENDERS);
    },
    [login]
  );

  return (
    <>
      <Formik
        validateOnChange
        initialValues={{ email: "", password: "" }}
        // validationSchema={schema}
        onSubmit={handleSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          isValid,
          handleSubmit,
          dirty
        }) => (
          <Form className="form" onSubmit={handleSubmit}>
            <h1 className="h2 fw-bolder mb-4">Log In</h1>

            <div className="mb-4">
              <div className="mb-4">
                <Form.Group className="position-relative">
                  <div className="position-relative">
                    <Form.Control
                      type="text"
                      name="email"
                      tabIndex="1"
                      className={`${touched.email ? "is-touch " : ""} ${
                        errors.email && touched.email ? " is-invalid" : ""
                      } ${!errors.email && touched.email ? " is-valid" : ""}`}
                      value={values.email}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Email or phone number"
                    />

                    {errors.email && touched.email && <IconError />}
                    {!errors.email && touched.email && <IconSuccess />}
                  </div>
                  {errors.email && touched.email && (
                    <Form.Control.Feedback
                      type="invalid"
                      className="position-absolute start-0 top-100"
                    >
                      {errors.email}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </div>

              <div className="mb-4">
                <Form.Group className="position-relative">
                  <div className="position-relative">
                    <Button
                      variant=""
                      className={`h-100 position-absolute top-0 end-0 ${
                        passwordType === "text" ? "text-secondary" : ""
                      }`}
                      onClick={reversePasswordType}
                    >
                      {passwordType === "password" && <IconEye />}
                      {passwordType === "text" && <IconEye />}
                    </Button>
                    <Form.Control
                      className={`${touched.password ? "is-touch " : ""} ${
                        errors.password && touched.password ? " is-invalid" : ""
                      } ${
                        !errors.password && touched.password ? " is-valid" : ""
                      }`}
                      type={passwordType}
                      name="password"
                      value={values.password}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      tabIndex="2"
                      placeholder="Password"
                    />

                    {errors.password && touched.password && <IconError />}
                    {!errors.password && touched.password && <IconSuccess />}
                  </div>

                  {errors.password && touched.password && (
                    <Form.Control.Feedback
                      type="invalid"
                      className="position-absolute start-0 top-100"
                    >
                      {errors.password}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </div>

              <Link
                to={APP_ROUTE.FORGOT}
                className="btn btn-link text-decoration-none"
              >
                Forgot your password?
              </Link>
            </div>

            <div className="text-center">
              <Button
                variant="primary"
                type="submit"
                className="w-100"
                disabled={!(isValid && dirty) || isLoading}
              >
                Log In {isLoading && <Loader />}
              </Button>
              <Link
                to={APP_ROUTE.SIGNUP}
                className="btn btn-link mt-12 d-block"
              >
                Create an account
              </Link>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}
