/* eslint-disable no-unused-vars */
import { useEffect, useState, useMemo } from "react";
import { Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { useGetTendersWithOrdersMutation } from "../../redux/services/tenderApi";
import { Loader, Pagination, PoWoCreateModal } from "../../components";

import {
  transformationDate,
  reduceTotalPrice,
  reducePrice,
  toFloat
} from "../../utils/helpersFunctions";

import { APP_ROUTE } from "../../utils/constants";

export default function PoWo() {
  const navigate = useNavigate();
  const [tendersList, setTendersList] = useState([]);
  const [countPage, setCountPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [getTendersWithOrders, { isLoading }] =
    useGetTendersWithOrdersMutation();

  useEffect(() => {
    async function fetchData() {
      const { data } = await getTendersWithOrders({ page: currentPage });
      setCountPage(data.data.count || 1);
      setTendersList(data.data.results);
    }
    fetchData();
  }, [currentPage]);

  const [order, setOrder] = useState({});
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    setOrder({});
  };
  const handleShow = (o) => {
    setOrder(o);
    setShow(true);
  };

  const totalPrice = useMemo(() => reducePrice(order?.items), [order]);
  const grandTotal = useMemo(() => reduceTotalPrice(order?.items), [order]);
  const totalTaxPrice = useMemo(
    () => toFloat(grandTotal - totalPrice),
    [totalPrice, grandTotal]
  );
  return (
    <>
      <PoWoCreateModal
        show={show}
        onHide={handleClose}
        form={order}
        items={order.items}
        totalPrice={totalPrice}
        grandTotal={grandTotal}
        totalTaxPrice={totalTaxPrice}
      />

      <h1 className="h1 fw-bolder mb-14">PO/WO</h1>

      <div className="table-rounded mb-30 shadow bg-white rounded-1">
        <Table striped responsive className="mb-0">
          <thead>
            <tr>
              <th className="text-center" style={{ width: "85px" }}>
                <div>S No</div>
              </th>
              <th>
                <div>Tender</div>
              </th>
              <th>
                <div>Posted</div>
              </th>
              <th>
                <div>Deadline</div>
              </th>
              <th>
                <div>PO/WO Number</div>
              </th>
              <th>
                <div>Awarded To</div>
              </th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan="7">
                  <Loader />
                </td>
              </tr>
            ) : tendersList.length === 0 ? (
              <tr>
                <td colSpan="7">
                  <div className="px-2 px-lg-4">
                    <h6 className="my-10 fw-normal">
                      You haven&apos;t created any orders yet.
                    </h6>
                  </div>
                </td>
              </tr>
            ) : (
              tendersList.map((tender, index) => (
                <TenderRow
                  key={`PoWo${tender.id}`}
                  tender={tender}
                  index={index}
                  navigate={navigate}
                  handleShow={handleShow}
                />
              ))
            )}
          </tbody>
        </Table>
      </div>
      {(tendersList.length > 9 || currentPage !== 1) && (
        <Pagination
          itemsCount={countPage}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      )}
    </>
  );
}

function TenderRow({ tender, navigate, index, handleShow }) {
  return (
    <tr
      onClick={() =>
        navigate(`${APP_ROUTE.EMPLOYER_TENDER_DETAIL}/${tender.id}`)
      }
    >
      <td className="text-center" style={{ width: "85px" }}>
        {index + 1}
      </td>
      <td className="cursor-pointer">{tender?.title}</td>
      <td>
        {tender?.orders.map((o) => (
          <div className="tableLinePoWo" key={`PoWocreated_at${o.id}`}>
            {transformationDate(o.created_at)}
          </div>
        ))}
      </td>
      <td>
        {tender?.orders.map((o) => (
          <div className="tableLinePoWo" key={`PoWocreated_at${o.id}`}>
            {transformationDate(o.deadline)}
          </div>
        ))}
      </td>
      <td>
        {tender?.orders.map((o) => (
          <div className="tableLinePoWo" key={`PoWocreated_at${o.id}`}>
            <div
              onClick={(e) => {
                e.stopPropagation();
                handleShow(o);
              }}
              className="cursor-pointer"
              title={o.number}
            >
              {o.number ? `${o.number} (Open)` : "Open"}
            </div>
            {o.file && (
              <a
                href={o.file}
                className="d-block text-reset text-decoration-none cursor-pointer"
                download
                target="_blank"
                rel="noreferrer noopener"
                onClick={(e) => e.stopPropagation()}
              >
                PDF
              </a>
            )}
          </div>
        ))}
      </td>
      <td>
        {tender?.orders.map((o) => (
          <div className="tableLinePoWo" key={`PoWocreated_at${o.id}`}>
            {o.issued_to?.full_name}
          </div>
        ))}
      </td>
    </tr>
  );
}
