import IconItem from "./IconItem";

export default function Share() {
  return (
    <div className="share">
      <ul className="list-unstyled m-0">
        <li>
          <a
            href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`}
            className="share-link"
            target="_blank"
            rel="noreferrer noopener"
            aria-label="Social link"
          >
            <IconItem icon="f" />
          </a>
        </li>
        <li>
          <a
            href={`https://twitter.com/intent/tweet?url=${window.location.href}`}
            className="share-link"
            target="_blank"
            rel="noreferrer noopener"
            aria-label="Social link"
          >
            <IconItem icon="tw" />
          </a>
        </li>
        <li>
          <a
            href={`https://www.instagram.com/?url=${window.location.href}`}
            className="share-link"
            target="_blank"
            rel="noreferrer noopener"
            aria-label="Social link"
          >
            <IconItem icon="insta" />
          </a>
        </li>
        <li>
          <a
            href={`${window.location.href}`}
            className="share-link"
            target="_blank"
            rel="noreferrer noopener"
            aria-label="Social link"
          >
            <IconItem icon="google" />
          </a>
        </li>
      </ul>
    </div>
  );
}
