import { useState } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import { useSelector } from "react-redux";

import { Loader } from "../../components";
import { useContactUsMutation } from "../../redux/services/authApi";
import { messageVerification } from "../../utils/yupHelper";
import { IconError, IconSuccess } from "../../utils/svgHelper";

export default function ContactUs() {
  const [success, setSuccess] = useState(false);
  const [contactUs, { isLoading }] = useContactUsMutation();
  const {
    userStore: { user }
  } = useSelector((state) => ({ userStore: state.userStore }));
  // const navigate = useNavigate();

  const schema = yup.object().shape({
    message: messageVerification
  });

  const handleSubmit = async (values, { setStatus, setErrors }) => {
    try {
      const { error } = await contactUs({
        message: values.message,
        email: user.email,
        full_name: user.name,
        user: user.id
      });
      if (error && error.data.errors.length !== 0) {
        setErrors({
          message: error.data.errors[0].message,
          email: error.data.errors[0].email,
          full_name: error.data.errors[0].full_name,
          user: error.data.errors[0].user
        });
        return;
      }
      // navigate(-1);
      setSuccess(true);
    } catch (err) {
      console.error(err);
      setStatus(err.message);
    }
  };

  return (
    <>
      <h1 className="h1 fw-bolder mb-14">Contact Us</h1>
      {!success && (
        <>
          <small className="d-block mb-10">
            Please type your message here, and we will get back to you as soon
            as possible
          </small>

          <Formik
            validateOnChange
            initialValues={{
              message: ""
            }}
            validationSchema={schema}
            onSubmit={handleSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              isValid,
              handleSubmit,
              dirty
            }) => (
              <Form className="form" onSubmit={handleSubmit}>
                <Row>
                  <Col lg={8}>
                    <div className="position-relative">
                      <div className="position-relative">
                        <Form.Control
                          as="textarea"
                          rows={4}
                          name="message"
                          className={`${touched.message ? "is-touch " : ""} ${
                            errors.message && touched.message
                              ? " is-invalid"
                              : ""
                          } ${
                            !errors.message && touched.message
                              ? " is-valid"
                              : ""
                          }`}
                          value={values.message}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          placeholder="Your message"
                        />

                        {errors.message && touched.message && <IconError />}

                        {!errors.message && touched.message && <IconSuccess />}
                      </div>
                      {errors.message && (
                        <Form.Control.Feedback type="invalid">
                          {errors.message}
                        </Form.Control.Feedback>
                      )}
                    </div>
                  </Col>
                </Row>
                <div className="text-lg-end pt-30">
                  <Button
                    variant="primary"
                    type="submit"
                    disabled={!isValid || isLoading}
                  >
                    Send Message{isLoading && <Loader />}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </>
      )}

      {success && (
        <div className="fw-600 fsz-18 mb-30">
          Thank you for your message. <br /> We will get back in touch with you
          soon!
        </div>
      )}
    </>
  );
}
