import { useCallback, useEffect, useState } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { useGetEmployerSearchContractorsMutation } from "../../redux/services/tenderApi";
import { Loader, Pagination } from "../../components";
import { APP_ROUTE, CLIENT_TYPE } from "../../utils/constants";
import IconItem from "../../components/IconItem";

export default function ProviderSearch() {
  const navigate = useNavigate();
  const [type, setType] = useState([]);
  const [
    getEmployerSearchContractors,
    { isLoading: isLoadingEmployerSearchContractors }
  ] = useGetEmployerSearchContractorsMutation();

  const [searchList, setSearchList] = useState([]);
  const registrationTypeList = ["Provider Individual", "Provider Company"];

  const [countPage, setCountPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  const searchResults = useCallback(async () => {
    const provider =
      type.length > 0 ? type.map((pv) => `&role=${pv}`).join("") : "";
    const { data } = await getEmployerSearchContractors({
      provider,
      page: currentPage
    });
    setCountPage(data.data.count);
    setSearchList(data.data.results);
  }, [type, currentPage]);

  useEffect(() => {
    async function fetchData() {
      await searchResults();
    }
    fetchData();
  }, [currentPage]);

  const submitHandler = async (e) => {
    e.preventDefault();
    await searchResults();
  };

  const typeChangeHandler = (value) => {
    const index = type.findIndex((pv) => pv === value);

    index < 0
      ? setType([...type, value])
      : setType(type.filter((pv) => pv !== value));

    setCurrentPage(1);
  };

  return (
    <>
      <h1 className="h1 fw-bolder mb-14">Search for Contractors</h1>

      <div className="row justify-content-between">
        <div className="col-lg-6 col-xl-5 mb-3 mb-lg-0">
          <h6 className="fsz-18 mb-30">Filters</h6>

          <Form className="form">
            <div className="mb-30">
              <Row className="justify-content-center align-items-center g-2">
                <Col sm={4} lg={4} className="text-start mb-2 mb-sm-0">
                  <Form.Label className="fs-6 lh-base col-form-label py-0">
                    Type
                  </Form.Label>
                </Col>
                <Col sm={8} lg={7}>
                  <div className="d-block">
                    {registrationTypeList.map((rType) => (
                      <Form.Check
                        key={rType}
                        id={rType}
                        inline
                        className="mb-2"
                        type="checkbox"
                        label={rType}
                        value={rType}
                        onChange={() =>
                          typeChangeHandler(
                            rType.replace("Provider", CLIENT_TYPE.contractor)
                          )
                        }
                      />
                    ))}
                  </div>
                </Col>
              </Row>
            </div>
            <div className="text-lg-center pt-2">
              <Button
                type="submit"
                variant="primary"
                className="px-36"
                onClick={submitHandler}
              >
                Search
              </Button>
            </div>
          </Form>
        </div>

        <div className="col-lg-6 col-xl-6 ps-xl-6">
          <h6 className="fsz-18 mb-4">Search Results</h6>
          {isLoadingEmployerSearchContractors ? (
            <Loader />
          ) : searchList.length === 0 ? (
            <h6 className="fsz-18 mb-10">No results found</h6>
          ) : (
            searchList.map((contractor) => (
              <div
                className="ms-xl-n3"
                key={contractor.id}
                onClick={() =>
                  navigate(APP_ROUTE.CONTRACTOR_PROFILE, { state: contractor })
                }
              >
                <div className="card mb-10 cursor-pointer">
                  <div className="card-body py-14 px-4">
                    <div className="d-block text-reset text-decoration-none">
                      <span className="d-flex align-items-center justify-content-between">
                        <span className="card-text">
                          {contractor.full_name}
                        </span>
                        <span
                          className="text-light"
                          onClick={(e) => {
                            e.stopPropagation();
                            navigate(APP_ROUTE.NEW_MESSAGE, {
                              state: contractor
                            });
                          }}
                        >
                          <IconItem icon="email" />
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ))
          )}
          {(searchList.length > 9 || currentPage !== 1) && (
            <Pagination
              itemsCount={countPage}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          )}
        </div>
      </div>
    </>
  );
}
