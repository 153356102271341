import { Table } from "react-bootstrap";

import { toFloat } from "../utils/helpersFunctions";

export default function TableComparison({
  tehBids,
  items,
  allowTaxes,
  isCompact
}) {
  return (
    <div className="table-rounded mb-0 shadow bg-white rounded-1 v-top">
      <Table striped responsive>
        <thead>
          <tr>
            <th rowSpan="2" style={{ width: "68px" }}>
              S No
            </th>
            <th rowSpan="2" style={{ width: "308px" }}>
              Item Name
            </th>
            <th rowSpan="2" style={{ width: "118px" }}>
              Description
            </th>
            <th rowSpan="2" style={{ width: "98px" }}>
              Quantity
            </th>
            <th rowSpan="2" style={{ width: "98px" }}>
              Unit
            </th>
            {tehBids.map((bid) => (
              <th
                key={`ContractorName${bid.id}`}
                colSpan={
                  allowTaxes && !isCompact
                    ? "6"
                    : !allowTaxes && !isCompact
                    ? "3"
                    : "1"
                }
                style={{
                  width:
                    allowTaxes && !isCompact
                      ? "627px"
                      : !allowTaxes && !isCompact
                      ? "333px"
                      : "115px"
                }}
              >
                {bid.owner.full_name}
              </th>
            ))}
          </tr>
          <tr>
            {tehBids.map((_) => (
              <>
                <th
                  style={{ width: "120px" }}
                  className="border-start"
                  hidden={isCompact}
                >
                  Item Detail
                </th>
                <th style={{ width: "115px" }}>Unit Price</th>
                <th style={{ width: "98px" }} hidden={!allowTaxes || isCompact}>
                  Sub Total
                </th>
                <th style={{ width: "98px" }} hidden={!allowTaxes || isCompact}>
                  Tax Type
                </th>
                <th style={{ width: "98px" }} hidden={!allowTaxes || isCompact}>
                  Total Tax
                </th>
                <th style={{ width: "98px" }} hidden={isCompact}>
                  Total
                </th>
              </>
            ))}
          </tr>
        </thead>
        <tbody>
          {items.map((item, index) => (
            <tr key={`item_row${item.id}`}>
              <td style={{ width: "68px" }}>{index + 1}</td>
              <td style={{ width: "308px" }}>{item.name}</td>
              <td style={{ width: "118px" }}>{item.description}</td>
              <td style={{ width: "98px" }}>{item.quantity}</td>
              <td style={{ width: "98px" }}>{item.unit}</td>
              {tehBids.map((bid) => {
                const bidDataByItem = bid.items_bids.find(
                  (ib) => ib.item === item.id
                );
                return (
                  <>
                    <td
                      style={{ width: "120px" }}
                      className="border-start"
                      hidden={isCompact}
                    >
                      {bidDataByItem?.details || ""}
                    </td>
                    <td style={{ width: "115px" }} className="border-start">
                      {bidDataByItem?.price || ""}
                    </td>
                    <td
                      style={{ width: "98px" }}
                      className="border-start"
                      hidden={!allowTaxes || isCompact}
                    >
                      {bidDataByItem?.price
                        ? toFloat(item.quantity * bidDataByItem.price)
                        : ""}
                    </td>
                    <td
                      style={{ width: "98px" }}
                      className="border-start"
                      hidden={!allowTaxes || isCompact}
                    >
                      {bidDataByItem?.taxes.map((tax) => (
                        <div
                          className="mb-1"
                          key={`tax_rate${tax.id}`}
                        >{`${tax.tax.name} (${tax.tax_rate}%)`}</div>
                      ))}
                    </td>
                    <td
                      style={{ width: "98px" }}
                      className="border-start"
                      hidden={!allowTaxes || isCompact}
                    >
                      {bidDataByItem?.taxes.map((tax) => (
                        <div className="mb-1" key={`tax_amount${tax.id}`}>
                          {tax.tax_amount}
                        </div>
                      ))}
                    </td>
                    <td
                      style={{ width: "98px" }}
                      className="border-start"
                      hidden={isCompact}
                    >
                      {bidDataByItem?.total_price || ""}
                    </td>
                  </>
                );
              })}
            </tr>
          ))}
        </tbody>
        <tfoot className="border-bottom border-top">
          <tr>
            <td colSpan="5">
              <div className="fw-600">Total</div>
            </td>
            {tehBids.map((bid) => {
              const taxAmountList = bid.items_bids.map((ib) =>
                ib?.taxes.length
                  ? ib.taxes
                      .map((tax) => tax.tax_amount)
                      .reduce((prevValue, curValue) => prevValue + curValue, 0)
                  : 0
              );
              const taxTotal = toFloat(
                taxAmountList.reduce(
                  (prevValue, curValue) => prevValue + curValue,
                  0
                )
              );
              return (
                <>
                  <td
                    style={{ width: "120px" }}
                    className="border-start"
                    hidden={isCompact}
                  />
                  <td style={{ width: "115px" }} />
                  <td
                    style={{ width: "98px" }}
                    className="border-start"
                    hidden={!allowTaxes || isCompact}
                  >
                    {(bid.grand_total - taxTotal).toFixed(2)}
                  </td>
                  <td
                    style={{ width: "98px" }}
                    className="border-start"
                    hidden={!allowTaxes || isCompact}
                  >
                    &nbsp;
                  </td>
                  <td
                    style={{ width: "98px" }}
                    className="border-start"
                    hidden={!allowTaxes || isCompact}
                  >
                    {taxTotal}
                  </td>
                  <td
                    style={{ width: "98px" }}
                    className="border-start"
                    hidden={isCompact}
                  >
                    {bid.grand_total}
                  </td>
                </>
              );
            })}
          </tr>
        </tfoot>
      </Table>
    </div>
  );
}
