import { Modal, Button } from "react-bootstrap";

import imgSuccess from "../assets/images/po_wo_success.svg";
import { usePostTenderOrderSendEmailMutation } from "../redux/services/tenderApi";
import { Loader } from "../components";

export default function PoWoCreateSuccessModal({
  show,
  onHide,
  tenderId,
  orderId,
  onSend
}) {
  const [postTenderOrderSendEmail, { isLoading }] =
    usePostTenderOrderSendEmailMutation();

  const sendEmail = async () => {
    const { error } = await postTenderOrderSendEmail({
      tenderId,
      orderId
    });
    if (error) {
      return console.log("error", error);
    }

    onSend(orderId);
    onHide();
  };

  return (
    <Modal show={show} onHide={onHide} className="outside">
      <Modal.Header className="d-block d-md-flex flex-md-row-reverse pt-30 pb-0"></Modal.Header>

      <Modal.Body className="text-center">
        <img src={imgSuccess} alt="" className="mx-auto mb-4" />

        <p className="h4 fw-600 mb-3">Your PO/WO has been saved!</p>
        <p className="h4 fw-600 mb-3">
          Would you like to email it to the contractor?
        </p>
      </Modal.Body>
      <Modal.Footer className="justify-content-center pt-0">
        <Button variant="light" onClick={onHide} className="px-30">
          Skip
        </Button>
        <Button variant="primary" onClick={sendEmail} className="px-30 ms-3">
          Send Email {isLoading && <Loader />}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
