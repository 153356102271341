import { useState, useRef } from "react";
import { Button, Overlay, Tooltip } from "react-bootstrap";

import { Loader } from "../components";
import { useUpdateTenderMutation } from "../redux/services/tenderApi";

export default function CreateDynamicLink({ tender, onUpdate }) {
  const [updateTender, { isLoading }] = useUpdateTenderMutation();

  const target = useRef(null);

  const [showTooltip, setShowTooltip] = useState(false);

  const createDynamicLinkHandler = async () => {
    const { data } = await updateTender({
      id: tender.id,
      body: { is_public_link: true }
    });
    onUpdate(data.data);
  };

  const link = `${window.location.origin}/tender/${tender.public_guid}`;

  const copyHandler = async () => {
    if (navigator?.clipboard) {
      await navigator.clipboard.writeText(decodeURIComponent(link));
      setShowTooltip(true);
      setTimeout(() => {
        setShowTooltip(false);
      }, 1000);
    }
  };

  return (
    <>
      <div className="mb-4">
        {tender.is_public_link ? (
          <>
            <p>
              A unique link to this tender that can send to your vendors for
              bidding on this tender.
            </p>
            <p className="d-flex align-items-center">
              <span className="me-2">{link}</span>
              <Button
                size="sm"
                variant="primary"
                onClick={copyHandler}
                ref={target}
              >
                Copy
              </Button>
              <Overlay
                target={target.current}
                show={showTooltip}
                placement="bottom"
                style={{ color: "red", opacity: 1 }}
              >
                {(props) => (
                  <Tooltip id="overlay-example" {...props}>
                    Copied!
                  </Tooltip>
                )}
              </Overlay>
            </p>
          </>
        ) : (
          <>
            <Button
              variant="outline-primary"
              className="px-4 w-100"
              onClick={createDynamicLinkHandler}
            >
              Create Dynamic Link {isLoading && <Loader />}
            </Button>
            <p className="mt-3">
              A dynamic link allows you to create a unique link to this tender
              that can send to your vendors for bidding on this tender.
            </p>
          </>
        )}
      </div>
    </>
  );
}
