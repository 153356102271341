import { useEffect, useState, useMemo } from "react";
import { Button, Row, Col, Form } from "react-bootstrap";

import {
  TableVendor,
  TableComparison,
  ModalTableComparison
} from "../components";
import { useComparePricesMutation } from "../redux/services/tenderApi";
import { CLIENT_TYPE } from "../utils/constants";

export default function TableBidsComparisonUpdate({
  items,
  bids,
  role,
  tenderId,
  allowTaxes
}) {
  const [comparePrices] = useComparePricesMutation();
  const [tehBids, setTehBids] = useState([]);
  const [viewType, setViewType] = useState("regular");
  const [latsViewType, setLastViewType] = useState("");
  const viewTypeList = ["Regular", "Compact", "Full"];

  const [show, setShow] = useState(false);
  const handleClose = () => {
    setViewType(latsViewType);
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const minBidsList = useMemo(() => {
    const allItemBids = bids.map((b) =>
      b.items_bids.map((ib) => ({ bid: ib, owner: b.owner }))
    );
    const mergedAllItemBids = [].concat.apply([], allItemBids);

    return (
      items.map((i) => {
        const bidsByItemList = mergedAllItemBids.filter(
          (ib) => ib.bid.item === i.id
        );
        const bidsWithPrice = bidsByItemList.filter(
          (i) => i?.bid?.total_price > 0
        );

        const lowestBid =
          bidsWithPrice.length > 1
            ? bidsWithPrice.reduce(function (prev, curr) {
                return prev.bid.total_price < curr.bid.total_price
                  ? prev
                  : curr;
              })
            : bidsWithPrice.length === 1
            ? bidsWithPrice[0]
            : null;

        if (!lowestBid) {
          return {
            item: i.id,
            owner: "",
            price: ""
          };
        }

        return {
          item: i.id,
          owner: lowestBid.owner,
          price: lowestBid.bid.total_price
        };
      }) || []
    );
  }, [items, bids]);

  useEffect(() => {
    bids &&
      setTehBids(
        bids.map((b, index) => ({
          ...b,
          owner: {
            id: b.owner.id,
            full_name:
              b.is_bid_owner || role === CLIENT_TYPE.employer
                ? b.owner.full_name
                : `Contractor ${index + 1}`
          },
          selected: true
        }))
      );
  }, [bids]);

  const isCompact = useMemo(() => viewType === "compact", [viewType]);

  useEffect(() => {
    if (viewType === "full") {
      handleShow(true);
    }
  }, [viewType]);

  return (
    <>
      <ModalTableComparison
        show={show}
        onHide={handleClose}
        tehBids={tehBids}
        items={items}
        allowTaxes={allowTaxes}
        isCompact={isCompact}
      />
      <div className="d-flex mb-2 align-items-center justify-content-between">
        <div className="fs-6 lh-base form-label mb-0">Bids Comparison</div>

        {role === CLIENT_TYPE.employer && (
          <Button
            variant="link"
            onClick={async () => {
              const response = await comparePrices(tenderId);
              const downloadLink = document.createElement("a");
              const universalBOM = "\uFEFF";
              downloadLink.href = `data:text/csv;charset=utf-8,${encodeURIComponent(
                universalBOM + response.error.data
              )}`;
              downloadLink.download = "comparative-price.csv";
              document.body.appendChild(downloadLink);
              downloadLink.click();
              document.body.removeChild(downloadLink);
            }}
          >
            Export to Excel
          </Button>
        )}
      </div>
      <div className="d-flex mb-2">
        {viewTypeList.map((view) => {
          const viewText = view.toLowerCase();
          return (
            <div key={`${viewText}-radio`}>
              <Form.Check
                key={viewText}
                id={`${viewText}-radio`}
                inline
                name={viewText}
                type="checkbox"
                label={`${view} View`}
                className="flex-grow-0 flex-shrink-0"
                checked={viewType === viewText}
                onChange={() => {
                  setViewType(viewText);
                  if (viewText !== "full") {
                    setLastViewType(viewText);
                  }
                }}
              />
            </div>
          );
        })}
      </div>
      <div className="table-rounded mb-30 shadow bg-white rounded-1 v-top ">
        <TableComparison
          tehBids={tehBids}
          items={items}
          allowTaxes={allowTaxes}
          isCompact={isCompact}
        />
      </div>

      <div className="mb-36 mb-lg-5">
        <div className="fs-6 lh-base form-label mb-2">
          Vendor with lowest prices
        </div>

        <Row>
          <Col lg={6}>
            <TableVendor
              vendorList={bids.map((b) => b.owner)}
              bidsList={minBidsList}
            />
          </Col>
        </Row>
      </div>
    </>
  );
}
