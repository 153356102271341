import { useEffect, useState } from "react";
import { Table, Button } from "react-bootstrap";

import {
  useMailDeleteTrashMessageMutation,
  useMailTrashBoxMutation,
  useRecoverMessageMutation
} from "../../redux/services/authApi";
import { Loader, Pagination } from "../../components";
import { transformationTime } from "../../utils/helpersFunctions";
import IconItem from "../../components/IconItem";

export default function MailTrash() {
  const [mailTrashBox, { isLoading: isLoadingMailTrashBox }] =
    useMailTrashBoxMutation();
  const [mailRecoverMessage] = useRecoverMessageMutation();
  const [mailDeleteTrashMessage] = useMailDeleteTrashMessageMutation();

  const [mailList, setMailList] = useState([]);
  const [countPage, setCountPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    async function fetchData() {
      const tehMailTrashBox = await mailTrashBox(currentPage);
      setMailList(tehMailTrashBox.data.data.results);
      setCountPage(tehMailTrashBox.data.data.count);
    }
    fetchData();
  }, [currentPage]);

  const deleteMailHandler = async (id) => {
    setMailList(mailList.filter((item) => item.id !== id));
    await mailDeleteTrashMessage(id);
  };

  const recoverMailHandler = async (id) => {
    setMailList(mailList.filter((item) => item.id !== id));
    await mailRecoverMessage(id);
  };

  return (
    <>
      <h1 className="h1 fw-bolder mb-14">Deleted Messages</h1>

      <div className="mb-3 mx-n2 mx-lg-n4">
        <Table striped responsive>
          <thead>
            <tr>
              <th className="ps-2 ps-lg-4">
                <div>Sender</div>
              </th>
              <th>
                <div>Subject</div>
              </th>
              <th>
                <div>Received</div>
              </th>
              <th className="pe-2 pe-lg-4">&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {isLoadingMailTrashBox ? (
              <tr>
                <td colSpan="4">
                  <Loader />
                </td>
              </tr>
            ) : mailList.length === 0 ? (
              <tr>
                <td colSpan="4" className="ps-2 ps-lg-4">
                  <h6 className="fsz-18 mb-0">No messages</h6>
                </td>
              </tr>
            ) : (
              mailList?.map((item) => (
                <TrashMailRow
                  key={item.id}
                  item={item}
                  onRecover={recoverMailHandler}
                  onDelete={deleteMailHandler}
                />
              ))
            )}
          </tbody>
        </Table>
      </div>
      {(mailList.length > 9 || currentPage !== 1) && (
        <Pagination
          itemsCount={countPage}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      )}
    </>
  );
}

function TrashMailRow({ item, onRecover, onDelete }) {
  return (
    <tr>
      <td className="ps-2 ps-lg-4">
        <div>{item.sender.full_name}</div>
      </td>
      <td>
        <div>{item.subject}</div>
      </td>
      <td>
        <div>{transformationTime(item.sent_at)}</div>
      </td>
      <td className="pe-2 pe-lg-4">
        <div>
          <Button
            variant="link"
            className="me-lg-3"
            onClick={() => onRecover(item.id)}
          >
            <IconItem icon="undelete" />
          </Button>
          <Button variant="link" onClick={() => onDelete(item.id)}>
            <IconItem icon="delete" />
          </Button>
        </div>
      </td>
    </tr>
  );
}
