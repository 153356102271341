import { Form, Button } from "react-bootstrap";
import { useState } from "react";

export default function AddAttachments({
  attachmentsList,
  onAddAttachment,
  isEditMode,
  onDeleteAttachment
}) {
  const [isFileTooBigWarn, setFileTooBigWarn] = useState(false);

  return (
    <>
      <div className="col-lg-12  mb-2">
        <div className="fs-6 lh-base form-label">Attachments</div>
      </div>
      <div className="col-lg-12">
        <div className="position-relative">
          <p className="fs-6">the maximum size is 5Mb</p>
          <div className="d-flex align-items-start flex-column">
            {attachmentsList?.length < 5 ? (
              <>
                <Form.Control
                  type="file"
                  name="file"
                  className="position-absolute opacity-0 z-999 btn btn-light px-36 px-lg-5 btn-attach ms-0"
                  onChange={async (e) => {
                    if (e.target.files[0].size > 5000000) {
                      setFileTooBigWarn(true);
                      setTimeout(() => setFileTooBigWarn(false), 3000);
                      return;
                    }
                    onAddAttachment(e.target.files[0]);
                  }}
                />
                <label
                  htmlFor="file"
                  className="btn btn-light px-36 px-lg-5"
                  tabIndex="11"
                >
                  + Add More
                </label>
              </>
            ) : (
              <></>
            )}

            <span className="form-label mt-3" id="file">
              {attachmentsList?.map((attachment, index) => (
                <Attachment
                  key={index + 1}
                  isEditMode={isEditMode}
                  attachment={attachment}
                  onDeleteAttachment={onDeleteAttachment}
                />
              ))}
              {attachmentsList.length === 0 && (
                <p className="fw-normal">No file chosen</p>
              )}
              {isFileTooBigWarn && (
                <p className="text-danger">Sorry, this file more 5Mb</p>
              )}
            </span>
          </div>
        </div>
      </div>
    </>
  );
}

function Attachment({ isEditMode, attachment, onDeleteAttachment }) {
  return (
    <div className="d-flex align-items-center justify-content-between">
      <p className="mb-1 fw-normal">
        {isEditMode
          ? attachment?.name || attachment?.file.split("/").pop()
          : attachment.name}
      </p>
      <Button
        variant=""
        className="btn-close mx-2"
        title="delete file"
        onClick={() => onDeleteAttachment(attachment)}
      >
        <span className="text-write"></span>
      </Button>
    </div>
  );
}
