import { Form, Row, Col } from "react-bootstrap";

import {
  VISIBILITY,
  VISIBILITY_DESCRIPTION
} from "../../../../utils/constants";

export default function TenderTenderVisibility({
  errors,
  values,
  showOptionListTender,
  handleListDisplayTender,
  handleOptionClickTender
}) {
  return (
    <Row className="justify-content-center align-items-center g-2 ">
      <Col sm={4} lg={5} className="text-start mb-2 mb-sm-0">
        <Form.Label className="fs-6 lh-base col-form-label py-0">
          Tender Visibility:
          {/* <span className="text-warning ms-1">
                          <svg className="icon icon-info">
                            <use xlinkHref="/sprite.svg#icon-info"></use>
                          </svg>
                        </span> */}
        </Form.Label>
      </Col>
      <Col sm={8} lg={7}>
        <div className="custom-select position-relative" tabIndex="2">
          <div className="position-relative"></div>

          <input
            type="hidden"
            className="form-control"
            value={values.visibility}
            readOnly=""
          />
          <div
            className={`select-selected form-control position-relative cursor-pointer pe-5 ${
              showOptionListTender ? "select-arrow-active" : ""
            }`}
            onClick={handleListDisplayTender}
          >
            <span className="select-placeholder text-truncate d-block text-body">
              {VISIBILITY_DESCRIPTION[values.visibility]}
            </span>
          </div>

          <div
            className={`select-items ${
              showOptionListTender ? "" : "select-hide"
            }`}
          >
            <div
              onClick={handleOptionClickTender}
              data-value={VISIBILITY.private}
              data-option="Private (a Private tender is only visible to
                              Contractors invited by the employer)"
            >
              {VISIBILITY_DESCRIPTION[VISIBILITY.private]}
            </div>
            <div
              onClick={handleOptionClickTender}
              data-value={VISIBILITY.public}
              data-option="Public (a Public tender is visible to everyone.
                              Any Contractor can view and bid)"
            >
              {VISIBILITY_DESCRIPTION[VISIBILITY.public]}
            </div>
          </div>

          {showOptionListTender && (
            <div className="select-mask select-mask-active"></div>
          )}

          {errors.visibility && (
            <Form.Control.Feedback type="invalid">
              {errors.visibility}
            </Form.Control.Feedback>
          )}

          {/* {!errors.visibility && (
                          <IconSuccess />
                        )} */}
        </div>
      </Col>
    </Row>
  );
}
