import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Button,
  Navbar,
  Nav,
  Form
} from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";
import { Formik } from "formik";
import * as yup from "yup";

import { APP_ROUTE } from "../utils/constants";
import {
  emailVerification,
  messageVerification,
  nameVerification
} from "../utils/yupHelper";
import Logo from "../assets/images/home/logo.svg";
import { useContactUsMutation } from "../redux/services/authApi";
import { Loader, ModalLogin } from "../components";
import { IconError, IconSuccess } from "../utils/svgHelper";

export default function LayoutHome({ children }) {
  const [contactUs, { isLoading }] = useContactUsMutation();
  const [showLogin, setShowLogin] = useState(false);
  const [isPassed, setPassed] = useState(false);
  const [infoAfterSenMessage, setInfoAfterSenMessage] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState("");

  const handleCloseLogin = () => setShowLogin(false);
  const handleShowLogin = () => setShowLogin(true);

  const navigate = useNavigate();

  const schema = yup.object().shape({
    email: emailVerification,
    name: nameVerification,
    phone: yup.string().required("Phone is a required field"),
    message: messageVerification
  });

  useEffect(() => {
    document.documentElement.classList.add("home");

    return () => {
      document.documentElement.classList.remove("home");
    };
  }, []);

  const onChangeRecaptcha = (value) => {
    setRecaptchaToken(value);
    setPassed(true);
  };
  const handleSubmit = useCallback(
    async (values, { setStatus, resetForm, setErrors }) => {
      try {
        if (recaptchaToken.length > 0) {
          const { error } = await contactUs({
            message: values.message,
            email: values.email,
            full_name: values.name,
            phone_number: values.phone
          });
          if (error && error.data.errors.length !== 0) {
            setErrors({
              message: error.data.errors[0].message,
              email: error.data.errors[0].email,
              full_name: error.data.errors[0].full_name,
              phone_number: error.data.errors[0].phone_number
            });
          } else {
            resetForm();
            window.grecaptcha.reset();
          }
          setInfoAfterSenMessage(true);
        }
      } catch (err) {
        console.error(err);
        setStatus(err.message);
      }
    },
    [recaptchaToken]
  );

  const isBidSubmitWrapped = window.location.href.includes("tender");

  return (
    <>
      <ModalLogin show={showLogin} onHide={handleCloseLogin} />
      <div className="project bg-light pt-0">
        <Navbar bg="light" expand="lg" className="position-sticky top-0">
          <Container className="d-block h-100">
            <div className="px-0 d-flex align-items-center justify-content-between h-100">
              <Navbar.Brand href={APP_ROUTE.HOME} className="logo logo-lg p-0">
                <img
                  src={Logo}
                  alt="ZoomTender"
                  className="img-fluid d-block mx-auto"
                />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <div className="offcanvas-header w-100 d-lg-none">
                  <h5 className="offcanvas-title" id="offcanvasExampleLabel">
                    Menu
                  </h5>
                  <Navbar.Toggle aria-controls="basic-navbar-nav" />
                </div>
                {!isBidSubmitWrapped ? (
                  <Nav className="ms-lg-auto pe-lg-2">
                    <Nav.Link href="#steps">How it Works</Nav.Link>
                    <Nav.Link href="#contact">Contact Us</Nav.Link>
                  </Nav>
                ) : null}
                <div className={`d-lg-inline-flex align-items-center justify-content-end flex-1 mt-auto mt-lg-0 ${isBidSubmitWrapped ? "ms-lg-auto " : ""}`}>
                  <Button
                    variant="outline-primary"
                    className="bw-2"
                    onClick={handleShowLogin}
                  >
                    Login
                  </Button>
                  <Button
                    variant="primary"
                    className="bw-2 mt-10 mt-lg-0 ms-lg-14"
                    onClick={() => navigate(APP_ROUTE.SIGNUP)}
                  >
                    Sign Up
                  </Button>
                </div>
              </Navbar.Collapse>
            </div>
          </Container>
        </Navbar>

        <main className="page-content">
          <div className="container">
            <div className="lg-xs bg-white shadow-lg rounded-3 mx-auto position-relative overflow-hidden">
              {children}

              {!isBidSubmitWrapped ? (
                <section
                  id="contact"
                  className="px-3 px-lg-7 section-5 bg-contact">
                  <div className="row">
                    <div className="col-lg-12" id="contact-us-block">
                      <h2 className="display-3 fw-bolder text-center">
                        Contact Us
                      </h2>
                      <h4 className="text-center d-none" id="contact_success">
                        Your request has been submitted
                      </h4>

                      <Formik
                        validateOnChange
                        initialValues={{
                          email: "",
                          name: "",
                          phone: "",
                          message: ""
                        }}
                        validationSchema={schema}
                        onSubmit={handleSubmit}
                      >
                        {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          isValid,
                          handleSubmit,
                          dirty
                        }) => (
                          <Form
                            className="form mx-auto bg-white shadow rounded-3"
                            onSubmit={handleSubmit}
                          >
                            {infoAfterSenMessage ? (
                              <div
                                className="fw-600 fsz-18 mb-30 d-flex align-items-center justify-content-center text-lg-center"
                                style={{ height: "330px" }}
                              >
                                Thank you for your message. <br /> We will get
                                back in touch with you soon!
                              </div>
                            ) : (
                              <>
                                <Row>
                                  <Col md={12}>
                                    <Form.Group className="position-relative mb-10">
                                      <div className="position-relative">
                                        <Form.Control
                                          type="text"
                                          name="name"
                                          className={`${
                                            touched.name ? "is-touch " : ""
                                          } ${
                                            errors.name && touched.name
                                              ? " is-invalid"
                                              : ""
                                          } ${
                                            !errors.name && touched.name
                                              ? " is-valid"
                                              : ""
                                          }`}
                                          value={values.name}
                                          onBlur={handleBlur}
                                          onChange={handleChange}
                                          placeholder="Name"
                                        />

                                        {errors.name && touched.name && (
                                          <IconError />
                                        )}

                                        {!errors.name && touched.name && (
                                          <IconSuccess />
                                        )}
                                      </div>
                                      {errors.name && touched.name && (
                                        <Form.Control.Feedback type="invalid">
                                          {errors.name}
                                        </Form.Control.Feedback>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col md={6}>
                                    <Form.Group className="position-relative mb-10">
                                      <div className="position-relative">
                                        <Form.Control
                                          type="text"
                                          name="email"
                                          className={`${
                                            touched.email ? "is-touch " : ""
                                          } ${
                                            errors.email && touched.email
                                              ? " is-invalid"
                                              : ""
                                          } ${
                                            !errors.email && touched.email
                                              ? " is-valid"
                                              : ""
                                          }`}
                                          value={values.email}
                                          onBlur={handleBlur}
                                          onChange={handleChange}
                                          placeholder="Email"
                                        />

                                        {errors.email && touched.email && (
                                          <IconError />
                                        )}

                                        {!errors.email && touched.email && (
                                          <IconSuccess />
                                        )}
                                      </div>
                                      {errors.email && touched.email && (
                                        <Form.Control.Feedback type="invalid">
                                          {errors.email}
                                        </Form.Control.Feedback>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col md={6}>
                                    <Form.Group className="position-relative mb-10">
                                      <div className="position-relative">
                                        <Form.Control
                                          type="text"
                                          name="phone"
                                          className={`${
                                            touched.phone ? "is-touch " : ""
                                          } ${
                                            errors.phone && touched.phone
                                              ? " is-invalid"
                                              : ""
                                          } ${
                                            !errors.phone && touched.phone
                                              ? " is-valid"
                                              : ""
                                          }`}
                                          value={values.phone}
                                          onBlur={handleBlur}
                                          onChange={handleChange}
                                          placeholder="Phone"
                                        />

                                        {errors.phone && touched.phone && (
                                          <IconError />
                                        )}

                                        {!errors.phone && touched.phone && (
                                          <IconSuccess />
                                        )}
                                      </div>
                                      {errors.phone && touched.phone && (
                                        <Form.Control.Feedback type="invalid">
                                          {errors.phone}
                                        </Form.Control.Feedback>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col md={12}>
                                    <Form.Group className="position-relative mb-10">
                                      <div className="position-relative">
                                        <Form.Control
                                          type="text"
                                          as="textarea"
                                          rows={4}
                                          name="message"
                                          className={`${
                                            touched.message ? "is-touch " : ""
                                          } ${
                                            errors.message && touched.message
                                              ? " is-invalid"
                                              : ""
                                          } ${
                                            !errors.message && touched.message
                                              ? " is-valid"
                                              : ""
                                          }`}
                                          value={values.message}
                                          onBlur={handleBlur}
                                          onChange={handleChange}
                                          placeholder=""
                                        />

                                        {errors.message && touched.message && (
                                          <IconError />
                                        )}

                                        {!errors.message && touched.message && (
                                          <IconSuccess />
                                        )}
                                      </div>
                                      {errors.message && touched.message && (
                                        <Form.Control.Feedback type="invalid">
                                          {errors.message}
                                        </Form.Control.Feedback>
                                      )}
                                    </Form.Group>
                                  </Col>
                                </Row>

                                <ReCAPTCHA
                                  className="g-recaptcha w-100"
                                  data-callback="enableBtn"
                                  sitekey="6Ld6VE4gAAAAAJ_rbC0ghD5bfF1kEHXAMupxfyiV"
                                  size={"normal"}
                                  theme={"light"}
                                  onChange={onChangeRecaptcha}
                                />
                                <Button
                                  variant="primary"
                                  className="mt-10"
                                  type="submit"
                                  disabled={
                                    !(isValid && dirty) ||
                                    isLoading ||
                                    !isPassed ||
                                    recaptchaToken.length === 0
                                  }
                                >
                                  Send{isLoading && <Loader />}
                                </Button>
                              </>
                            )}
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </div>
                </section>
              ) : null}
            </div>
          </div>
        </main>

        <footer className="page-footer d-flex align-items-center text-light">
          <div className="container w-100">&copy; {new Date().getFullYear()}</div>
        </footer>
      </div>
    </>
  );
}
