import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Row, Col, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";

import ReactInputVerificationCode from "react-input-verification-code";

import LayoutRegistration from "../../layout/LayoutRegistration";
import {
  useVerifyCodeMutation,
  useResendCodeMutation,
  useGetMeMutation
} from "../../redux/services/authApi";
import { setUser } from "../../redux/slices/userSlice";
import { Loader } from "../../components";
import { APP_ROUTE, CLIENT_TYPE } from "../../utils/constants";

export default function CompleteRegistration() {
  const [value, setValue] = useState("");
  const [clickHereMessage, setClickHereMessage] = useState(false);
  const [verifyCode] = useVerifyCodeMutation();
  const [resendCode] = useResendCodeMutation();
  const [getMe, { isLoading: isMeLoading }] = useGetMeMutation();
  const [loading, setLoading] = useState(false);
  const isLoading = isMeLoading || loading;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const [email, setEmail] = useState(state.email);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    setEmail(state.email);
  }, [state]);

  const handleSubmit = async () => {
    setClickHereMessage(false);
    setLoading(true);
    setShowError(false);
    const tehData = {
      email: email.trim().toLowerCase(),
      activation_key: value.trim()
    };
    try {
      const { data } = await verifyCode(tehData);
      setClickHereMessage(false);
      if (!data) {
        // resetForm();
        setShowError(true);
        setLoading(false);
        return;
      } else {
        const { data } = await getMe();
        const distr = {
          name: data.data.full_name,
          email: data.data.email,
          id: data.data.id,
          role: data.data.role,
          phone_number: data.data.phone_number,
          description: data.data.description,
          gst: data.data.gst,
          image: data.data.image,
          pan: data.data.pan
        };

        dispatch(setUser(distr));
        if (distr?.role === CLIENT_TYPE.employer) {
          navigate(APP_ROUTE.EMPLOYER_TENDERS);
        } else {
          navigate(APP_ROUTE.CONTRACTOR_BIDS);
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  const clickHereResentPassword = async (email) => {
    setClickHereMessage(true);
    setShowError(false);
    await resendCode({ email });
  };

  return (
    <LayoutRegistration>
      <h1 className="h2 fw-bolder mb-30">Complete your registration</h1>
      <div className="form-label lh-base">
        Please enter the 6 digit verification code <br />
        that was just emailed to you
      </div>
      <div className="custom-styles pt-4 pb-30">
        <ReactInputVerificationCode
          className="w-25"
          onChange={setValue}
          length={6}
          placeholder={false}
          type="text"
          autoFocus={true}
          value={value}
        />
      </div>
      <Row className="justify-content-center mb-20">
        <Col sm={8} lg={7} className="mb-10">
          <Button
            variant="primary"
            type="submit"
            className="w-100"
            disabled={value.length !== 6}
            onClick={handleSubmit}
          >
            Verify {isLoading && <Loader />}
          </Button>
        </Col>
        <Col sm={12}>
          <span
            className={
              (showError && "text-danger-dark") ||
              (clickHereMessage && "text-success")
            }
          >
            {(showError && "Wrong code! Please try again") ||
              (clickHereMessage && "Your activation code has been resent!")}
          </span>
        </Col>
      </Row>
      <div className="form-label fw-normal lh-base">
        <Button
          variant="link"
          className="text-primary fw-bold text-decoration-none lh-base"
          onClick={() => clickHereResentPassword(email)}
        >
          Click here
        </Button>{" "}
        to resend the verification code. Please make sure to <br />
        check your spam folders in case you haven&apos;t received the code.
      </div>
    </LayoutRegistration>
  );
}
