import { useMemo } from "react";
import { Button, Form } from "react-bootstrap";

const TenderItem = ({ index, item, onUpdate, onDelete, itemsCount }) => {
  const handleUpdate = (e) => {
    onUpdate({
      id: item.id,
      value: { [e.target.name]: e.target.value }
    });
  };

  const nameError = useMemo(() => {
    return !item.name ? "Required" : "";
  }, [item.name]);

  const quantityError = useMemo(() => {
    if (!item.quantity) {
      return "Required";
      // } else if (!Number.isInteger(Number(item.quantity))) {
      //   return "A valid integer is required.";
    } else if (item.quantity <= 0) {
      return "Should be greater than 0";
    } else if (item.quantity > 2147483647) {
      return "Ensure this value is less than or equal to 2147483647.";
    }
    return "";
  }, [item.quantity]);

  return (
    <tr>
      <td className="text-center">
        <div>{index + 1}</div>
      </td>
      <td>
        <div className="position-relative">
          <div className="text-write">
            <Form.Control
              tabIndex="6"
              type="text"
              name="name"
              value={item.name}
              placeholder="item name"
              onChange={handleUpdate}
            />
          </div>
          {nameError && (
            <Form.Control.Feedback type="invalid">
              {nameError}
            </Form.Control.Feedback>
          )}
        </div>
      </td>
      <td>
        <div className="position-relative">
          <div className="text-write">
            <Form.Control
              tabIndex="7"
              type="text"
              name="description"
              value={item.description}
              placeholder="description"
              onChange={handleUpdate}
            />
          </div>
        </div>
      </td>
      <td>
        <div className="position-relative">
          <div className="text-write">
            <Form.Control
              tabIndex="8"
              type="number"
              name="quantity"
              value={item?.quantity}
              placeholder="quantity"
              onChange={(e) => {
                const quantity = e.target.value ? Number(e.target.value) : null;
                onUpdate({
                  id: item.id,
                  value: { quantity }
                });
              }}
            />
          </div>
          {quantityError && (
            <Form.Control.Feedback type="invalid">
              {quantityError}
            </Form.Control.Feedback>
          )}
        </div>
      </td>
      <td>
        <div className="position-relative">
          <div className="text-write">
            <Form.Control
              tabIndex="9"
              type="text"
              name="unit"
              value={item.unit}
              placeholder="unit"
              onChange={handleUpdate}
            />
          </div>
        </div>
      </td>
      <td>
        <div className="position-relative">
          {itemsCount > 1 && (
            <Button
              variant=""
              className="btn-close"
              onClick={() => onDelete(item.id)}
            >
              <span className="text-write"></span>
            </Button>
          )}
        </div>
      </td>
    </tr>
  );
};

export default TenderItem;
