import { Modal, Button } from "react-bootstrap";

import { VidyardPlayer } from "../components";

export default function ModalVideo({ show, onHide }) {
  return (
    <>
      <Modal show={show} onHide={onHide} size="video">
        <Modal.Body className="p-0">
          <Button variant="" className="btn-close" onClick={onHide}></Button>
          <VidyardPlayer uuid="vKEwnoReewzWtmCSng1C3y" />
        </Modal.Body>
      </Modal>
    </>
  );
}
