import { useEffect, useState, useRef, createRef } from "react";
import { Table } from "react-bootstrap";

export default function TableBidsComparisonRanks({ items, bids, totalRank }) {
  const [tehItems, setTehItems] = useState([]);
  const [tehBids, setTehBids] = useState([]);

  const tableRefs = useRef(
    [...Array(bids.length).keys()].map((_, i) => createRef())
  );

  useEffect(() => {
    items && setTehItems(items);
    bids && setTehBids(bids.filter((b) => b.is_bid_owner));
  }, [bids, items]);

  return (
    <>
      <div className="d-flex mb-2 align-items-center justify-content-between">
        <div className="fs-6 lh-base form-label mb-0">Bids Comparison</div>
      </div>
      <div className="table-rounded mb-36 shadow bg-white rounded-1 table-nofixed">
        <Table striped responsive>
          <thead>
            <tr>
              <th className="text-center col-lg-2">
                <div className="p-0">Item</div>
              </th>
              <th className="col-lg-2">
                <div className="p-0">Quantity</div>
              </th>
              {tehBids.map((bid, i) => (
                <th key={`head${bid.id}`} ref={tableRefs.current[i]}>
                  <div className="p-0">{bid.owner.full_name}</div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tehItems?.map((item) => (
              <tr key={item.id}>
                <td className="text-center col-lg-2">
                  <div>{item.name}</div>
                </td>
                <td className="col-lg-2">
                  <div>{item.quantity}</div>
                </td>
                {tehBids
                  .map((bid) => ({
                    bid:
                      bid.items_bids.find(
                        (itemsBid) => itemsBid.item === item.id
                      ) || {}
                  }))
                  .map((obj) => (
                    <td key={`td${obj.bid.id}`}>
                      <div>
                        {obj?.bid?.rank ? `L${obj.bid.rank}` : "No bid"}
                      </div>
                    </td>
                  ))}
              </tr>
            ))}
            <tr key={"totalrank"}>
              <td>
                <div>Total Bid Rank</div>
              </td>
              <td></td>
              <td>
                {totalRank ? `L${totalRank}` : "You should bid at all items."}
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    </>
  );
}
