import { Form, Row, Col } from "react-bootstrap";

import {
  PRICING_VISIBILITY_DESCRIPTION,
  PRICING_VISIBILITY
} from "../../../../utils/constants";

export default function TenderPricingVisibility({
  errors,
  values,
  showOptionListPricing,
  handleListDisplayPricing,
  handleOptionClickPricing
}) {
  return (
    <Row className="justify-content-center align-items-center g-2 ">
      <Col sm={4} lg={5} className="text-start mb-2 mb-sm-0">
        <Form.Label className="fs-6 lh-base col-form-label py-0">
          Pricing Visibility:
          {/* <span className="text-warning ms-1">
                <IconItem icon="info" />
              </span> */}
        </Form.Label>
      </Col>
      <Col sm={8} lg={7}>
        <div className="custom-select position-relative" tabIndex="3">
          <input
            type="hidden"
            className="form-control"
            value={values.pricing_visibility}
            readOnly=""
          />
          <div
            className={`select-selected form-control position-relative cursor-pointer pe-5 ${
              showOptionListPricing ? "select-arrow-active" : ""
            }`}
            onClick={handleListDisplayPricing}
          >
            <span className="select-placeholder text-truncate d-block text-body">
              {PRICING_VISIBILITY_DESCRIPTION[values.pricing_visibility]}
            </span>
          </div>

          <div
            className={`select-items ${
              showOptionListPricing ? "" : "select-hide"
            }`}
          >
            <div
              onClick={handleOptionClickPricing}
              data-value={PRICING_VISIBILITY.openView}
            >
              {PRICING_VISIBILITY_DESCRIPTION[PRICING_VISIBILITY.openView]}
            </div>
            <div
              onClick={handleOptionClickPricing}
              data-value={PRICING_VISIBILITY.employerView}
            >
              {PRICING_VISIBILITY_DESCRIPTION[PRICING_VISIBILITY.employerView]}
            </div>
            <div
              onClick={handleOptionClickPricing}
              data-value={PRICING_VISIBILITY.closedView}
            >
              {PRICING_VISIBILITY_DESCRIPTION[PRICING_VISIBILITY.closedView]}
            </div>
          </div>

          {showOptionListPricing && (
            <div className="select-mask select-mask-active"></div>
          )}

          {errors.pricing_visibility && (
            <Form.Control.Feedback type="invalid">
              {errors.pricing_visibility}
            </Form.Control.Feedback>
          )}

          {/* {!errors.pricing_visibility && (
                          <IconSuccess />
                        )} */}
        </div>
      </Col>
    </Row>
  );
}
