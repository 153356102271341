import * as React from "react";

export default class VidyardEmbed extends React.Component {
  constructor() {
    super(...arguments);
    this.state = { hasError: false };
    this.handleContainerRef = (ref) => {
      if (ref) {
        this.container = ref;
      }
    };
  }

  componentDidMount() {
    if (!this.container) {
      return;
    }
    window.vidyardEmbed?.api
      .renderPlayer(
        Object.assign(
          {
            aspect: this.props.aspect,
            container: this.container,
            height: this.props.maxHeight,
            type: this.props.type,
            uuid: this.props.uuid,
            width: this.props.maxWidth
          },
          this.props.params
        )
      )
      .then((player) => {
        this.player = player;
        if (typeof this.props.api === "function") {
          this.props.api(player, window.vidyardEmbed.api);
        }
      })
      .catch((e) => {
        this.setState({ hasError: true });
        console.warn(e.message);
      });
  }

  componentWillUnmount() {
    if (this.player) {
      window.vidyardEmbed.api.destroyPlayer(this.player);
    }
  }

  render() {
    return <div ref={this.handleContainerRef} />;
  }
}
