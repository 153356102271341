import { Row, Col } from "react-bootstrap";

import image from "../assets/images/img-1.svg";

export default function About() {
  return (
    <>
      <div className="shadow rounded-3 mt-36 p-3 p-md-4 py-lg-5 px-lg-6">
        <Row className="align-items-center g-0">
          <Col md={4} lg={5} className="mb-3 mb-lg-0">
            <img src={image} alt="" className="img-fluid" />
          </Col>

          <Col md={8} lg={8} className="ms-lg-n5 me-lg-n5">
            <h1 className="h2 fw-bolder mb-3">
              What is a Tender?
            </h1>

            <div className="mb-0 me-lg-n2 text-start">
              <ul>
                <li>ZoomTender is a platform to conduct your own e-tenders</li>
                <li>Use ZoomTender to invite e-tenders and bid to e-tenders</li>
                <li>ZoomTender makes the process of conducting tenders paperless, fast and eliminates fraud from it</li>
              </ul>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}
