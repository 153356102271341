// import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  transformationTime,
  transformationDate
} from "../utils/helpersFunctions";
import {
  TENDER_STATUSES,
  PRICING_VISIBILITY,
  VISIBILITY_DESCRIPTION,
  PRICING_VISIBILITY_DESCRIPTION,
  APP_ROUTE,
  CLIENT_TYPE
} from "../utils/constants";

export default function PersonalInfo({ value }) {
  // const [timeLeft, setTimeLeft] = useState("");
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.userStore);

  const {
    created_at = "0/0/0 00:00:00",
    deadline = "0/0/0 00:00:00",
    pricing_visibility,
    pricing_type,
    status,
    title,
    visibility
  } = value;

  const decodingTime = (time) => {
    const futureDate = new Date(time);
    const now = new Date();

    let seconds = Math.floor((futureDate - now) / 1000);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    hours = hours - days * 24;
    minutes = minutes - days * 24 * 60 - hours * 60;
    seconds = seconds - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60;

    const daysStr =
      days > 1 ? `${days} days ` : days === 1 ? `${days} day ` : "";

    return `${daysStr}${hours}:${("0" + minutes).slice(-2)}:${(
      "0" + seconds
    ).slice(-2)}`;
  };

  // useEffect(() => {
  //   const timer = setInterval(() => setTimeLeft(decodingTime(deadline)), 1000);
  //   return () => clearInterval(timer);
  // }, []);

  return (
    <ul className="list-unstyled mb-30 lh-lg">
      <InfoRow title="Name" value={title} />
      <InfoRow title="Status" value={status} />
      {user.role !== CLIENT_TYPE.employer && (
        <li className="mb-1 d-flex align-items-center">
          <span className="fs-6 lh-base col-form-label py-0 text-nowrap">
            Employer:
          </span>
          <small
            className="form-control readonly"
            onClick={() =>
              navigate(APP_ROUTE.EMPLOYER_PROFILE, {
                state: { id: value?.owner?.id }
              })
            }
          >
            {value?.owner?.full_name}
          </small>
        </li>
      )}
      <InfoRow title="Visibility" value={VISIBILITY_DESCRIPTION[visibility]} />
      <InfoRow
        title="Pricing View"
        value={PRICING_VISIBILITY_DESCRIPTION[pricing_visibility]}
      />

      {user.role === CLIENT_TYPE.employer &&
        pricing_visibility === PRICING_VISIBILITY.openView && (
          <InfoRow title="Show Pricing" value={pricing_type} />
        )}
      <InfoRow title="Date Posted" value={transformationDate(created_at)} />
      <InfoRow title="Deadline" value={transformationTime(deadline)} />
      <InfoRow
        title="Time Left"
        value={
          status === TENDER_STATUSES.active
            ? decodingTime(deadline)
            : "Tender Ended"
        }
      />
    </ul>
  );
}

function InfoRow({ title, value }) {
  return (
    <li className="mb-1 d-flex align-items-center">
      <span className="fs-6 lh-base col-form-label py-0 text-nowrap">
        {title}:
      </span>
      <small className="form-control readonly">{value}</small>
    </li>
  );
}
