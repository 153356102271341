import "./App.scss";
import { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";

import { AuthContext } from "./context/auth.context";
import { useLoginMutation } from "./redux/services/authApi";
import { logout as logoutAction } from "./redux/slices/userSlice";

import { RegistrationRoutes, ContractorRoutes, EmployerRoutes } from "./routes";
import { Loader } from "./components";

import Layout from "./layout/Layout";
import { CLIENT_TYPE } from "./utils/constants";

function App() {
  const {
    userStore: { user, token, isLogin }
  } = useSelector((state) => ({ userStore: state.userStore }));
  const dispatch = useDispatch();
  const logout = () => dispatch(logoutAction());
  const [login] = useLoginMutation();
  const isAuthenticated = Boolean(token.access);

  useEffect(() => {
    isLogin && <Loader />;
  }, [isLogin]);

  const toastStyles = {
    minWidth: "fit-content",
    padding: "10px 6px",
    height: "115px",
    overflowY: "auto",
    right: "1rem",
    width: "auto",
    maxWidth: "calc(100% - 2rem)"
  };

  return (
    <div className="App">
      <AuthContext.Provider
        value={{ token, login, logout, user, isAuthenticated }}
      >
        <ToastContainer style={toastStyles} />

        <BrowserRouter>
          {isAuthenticated && user?.id && user.role.includes(CLIENT_TYPE.employer) && (
            <Layout>
              <EmployerRoutes />
            </Layout>
          )}
          {isAuthenticated && user?.id && user.role.includes(CLIENT_TYPE.contractor) && (
            <Layout>
              <ContractorRoutes />
            </Layout>
          )}
          {!isAuthenticated && <RegistrationRoutes />}
        </BrowserRouter>
      </AuthContext.Provider>
    </div>
  );
}

export default App;
