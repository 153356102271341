import { Form, Row, Col } from "react-bootstrap";

import { PRICING_TYPE } from "../../../../utils/constants";

export default function TenderShowPricing({ form, onFormUpdateHandler }) {
  return (
    <Row className="justify-content-center align-items-center g-2 ">
      <Col sm={4} lg={5} className="text-start mb-2 mb-sm-0">
        <Form.Label className="fs-6 lh-base col-form-label py-0">
          Show Pricing:
        </Form.Label>
      </Col>
      <Col sm={8} lg={7}>
        <div className="fw-normal">
          <div key="ranks-radio">
            <Form.Check
              inline
              type="radio"
              id="ranks-radio"
              label={PRICING_TYPE.showRanks}
              checked={form.pricing_type === PRICING_TYPE.showRanks}
              onChange={() => {
                onFormUpdateHandler({
                  pricing_type: PRICING_TYPE.showRanks
                });
              }}
            />
          </div>

          <div key="quotes-radio">
            <Form.Check
              inline
              type="radio"
              id="quotes-radio"
              label={PRICING_TYPE.showQuotes}
              checked={form.pricing_type === PRICING_TYPE.showQuotes}
              onChange={() => {
                onFormUpdateHandler({
                  pricing_type: PRICING_TYPE.showQuotes
                });
              }}
            />
          </div>
        </div>
      </Col>
    </Row>
  );
}
