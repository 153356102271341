/* eslint-disable no-unused-vars */
import { Table, Form, Row, Col, Button } from "react-bootstrap";
import { useState, useMemo, useEffect } from "react";
import { Formik } from "formik";
import Resizer from "react-image-file-resizer";

import { Calendar, PoWoCreateTable, PoWoCreateModal } from "../components";
import { ORDER_TYPE } from "../utils/constants";
import { usePostTenderOrderMutation } from "../redux/services/tenderApi";
import {
  reducePrice,
  reduceTotalPrice,
  toFloat
} from "../utils/helpersFunctions";

export default function PoWoCreate({
  setCreateOrder,
  contractorList,
  items,
  bids,
  taxes,
  tenderId,
  onCreate
}) {
  const [postTenderOrder, { isLoading: isLoadingPostTenderOrder }] =
    usePostTenderOrderMutation();

  const initialValues = {
    type: ORDER_TYPE["Purchase Order"],
    issued_to: contractorList[0].id,
    logo_file: "",
    signature_file: "",
    number: "",
    deadline: "",
    order_by: "",
    order_to: "",
    items,
    terms: "",
    notes: "",
    priceType: "L1 Price"
  };

  const [form, setForm] = useState(initialValues);
  const [showOptionListTender, setShowOptionListTender] = useState(false);
  const [bidsCandidate, setBidsCandidate] = useState([]);
  const [itemsCandidate, setItemsCandidate] = useState([]);
  const [deadlineError, setShowError] = useState(false);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleOptionClickTender = (issued_to) => {
    setForm({ ...form, issued_to });
    setShowOptionListTender(false);
  };

  const handleListDisplayTender = () => {
    setShowOptionListTender(!showOptionListTender);
  };

  const handleSubmit = async (pdf) => {
    const orderObj = {
      type: form.type,
      issued_to: form.issued_to,
      number: form.number,
      deadline: form.deadline,
      file: pdf,
      logo_file: form.logo_file,
      signature_file: form.signature_file,
      order_by: form.order_by,
      order_to: form.order_to,
      items: selectedItems.map((i) => ({
        ...i,
        taxes: i?.taxes
          ? i.taxes.map((t) => ({
              tax: t.tax.id,
              tax_amount: t.tax_amount,
              tax_rate: t.tax_rate
            }))
          : []
      })),
      terms: form.terms,
      notes: form.notes
    };

    const { data, error } = await postTenderOrder({ tenderId, body: orderObj });
    if (error) {
      return console.log("error", error);
    }
    if (data) {
      onCreate(data.data);
    }
  };

  const dateUpdateHandler = (date) => setForm({ ...form, ...date });

  const minBidsList = useMemo(() => {
    const allItemBids = bids.map((b) => b.items_bids);
    const mergedAllItemBids = [].concat.apply([], allItemBids);

    return (
      items.map((i) => {
        const bidsByItemList = mergedAllItemBids.filter(
          (ib) => ib.item === i.id
        );
        const bidsWithPrice = bidsByItemList.filter((i) => i?.total_price > 0);

        const lowestBid =
          bidsWithPrice.length > 1
            ? bidsWithPrice.reduce(function (prev, curr) {
                return prev.total_price < curr.total_price ? prev : curr;
              })
            : bidsWithPrice.length === 1
            ? bidsWithPrice[0]
            : {
                details: "",

                item: i.id,
                price: 0,
                taxes: [],
                total_price: 0
              };

        return lowestBid;
      }) || []
    );
  }, [items, bids]);

  const resetBidsList = useMemo(() => {
    return items.map((i) => ({
      details: "",
      item: i.id,
      price: 0,
      taxes: [],
      total_price: 0
    }));
  }, [items]);

  const bidsBySelectedContractor = useMemo(() => {
    const itemsBids = bids.find(
      (b) => b.owner.id === form.issued_to
    ).items_bids;
    return itemsBids;
  }, [form.issued_to, bids]);

  useEffect(() => setBidsCandidate(minBidsList), [minBidsList]);
  useEffect(
    () =>
      form.priceType === "Contractor Price" &&
      setBidsCandidate(bidsBySelectedContractor),
    [form]
  );
  useEffect(
    () =>
      setItemsCandidate(
        items.map((item) => {
          const bid = bidsCandidate.find((b) => b.item === item.id);

          return {
            ...item,
            ...bid,
            selected: true
          };
        })
      ),
    [bidsCandidate, items]
  );

  const selectedItems = useMemo(
    () => itemsCandidate.filter((i) => i.selected),
    [itemsCandidate]
  );

  const totalPrice = useMemo(() => reducePrice(selectedItems), [selectedItems]);
  const grandTotal = useMemo(
    () => reduceTotalPrice(selectedItems),
    [selectedItems]
  );
  const totalTaxPrice = useMemo(
    () => toFloat(grandTotal - totalPrice),
    [totalPrice, grandTotal]
  );

  const resizeFile = (file, name) => {
    Resizer.imageFileResizer(
      file,
      600,
      480,
      "JPEG",
      100,
      0,
      (uri) => setForm({ ...form, [name]: uri }),
      "base64",
      350,
      300
    );
  };

  return (
    <>
      <PoWoCreateModal
        show={show}
        onHide={handleClose}
        form={form}
        items={selectedItems}
        totalPrice={totalPrice}
        grandTotal={grandTotal}
        totalTaxPrice={totalTaxPrice}
        onSave={handleSubmit}
        isOrderCreating={isLoadingPostTenderOrder}
      />

      <div className="mb-36">
        <div className="h1 fw-bolder mb-14">Create PO/WO</div>

        <Formik
          validateOnChange
          enableReinitialize
          initialValues={{
            type: form.type,
            issued_to: form.issued_to,
            number: form.number,
            logo_file: form.logo_file,
            signature_file: form.signature_file,
            deadline: form.deadline,
            order_by: form.order_by,
            order_to: form.order_to,
            items: itemsCandidate,
            terms: form.terms,
            notes: form.notes,
            priceType: form.priceType
          }}
          onSubmit={handleSubmit}
        >
          {({ values, handleChange }) => (
            <Form>
              <div className="d-flex align-items-center mb-2">
                <Form.Check
                  inline
                  name="po_wo"
                  type="radio"
                  id="po"
                  label="PO"
                  checked={values.type === ORDER_TYPE["Purchase Order"]}
                  onChange={() =>
                    setForm({ ...form, type: ORDER_TYPE["Purchase Order"] })
                  }
                  className="fw-normal me-40"
                />
                <Form.Check
                  inline
                  name="po_wo"
                  type="radio"
                  id="wo"
                  label="WO"
                  checked={values.type === ORDER_TYPE["Work Order"]}
                  onChange={() =>
                    setForm({ ...form, type: ORDER_TYPE["Work Order"] })
                  }
                  className="fw-normal me-40"
                />
              </div>

              <div className="mb-2">
                <Row className="align-items-center g-2 ">
                  <Col sm={4} lg={3} className="text-start mb-2 mb-sm-0">
                    <Form.Label className="fs-6 lh-base col-form-label py-0">
                      Select Contractor
                    </Form.Label>
                  </Col>
                  <Col sm={5} lg={4}>
                    <div className="custom-select position-relative">
                      <div className="position-relative"></div>

                      <input
                        type="hidden"
                        className="form-control"
                        value=""
                        readOnly=""
                      />
                      <div
                        className={`select-selected form-control position-relative cursor-pointer pe-5 ${
                          showOptionListTender ? "select-arrow-active" : ""
                        }`}
                        onClick={handleListDisplayTender}
                      >
                        <span className="select-placeholder text-truncate d-block text-body">
                          {
                            contractorList.find(
                              (c) => c.id === values.issued_to
                            ).full_name
                          }
                        </span>
                      </div>

                      <div
                        className={`select-items ${
                          showOptionListTender ? "" : "select-hide"
                        }`}
                      >
                        {contractorList.map((c) => (
                          <div
                            key={`contractor${c.id}`}
                            onClick={() => handleOptionClickTender(c.id)}
                            data-value="Contractor1"
                          >
                            {c.full_name}
                          </div>
                        ))}
                      </div>

                      {showOptionListTender && (
                        <div className="select-mask select-mask-active"></div>
                      )}
                    </div>
                  </Col>
                </Row>
              </div>

              <div className="mb-2">
                <Row className="align-items-center g-2 ">
                  <Col sm={4} lg={3} className="text-start mb-2 mb-sm-0">
                    {/* <Form.Check
                      inline
                      name="po_wo_logo"
                      type="radio"
                      id="po"
                      label="Use Logo"
                      defaultChecked="checked"
                      hidden
                    /> */}
                    <Form.Label className="fs-6 lh-base col-form-label py-0">
                      Use Logo
                    </Form.Label>
                  </Col>
                  <Col sm={5} lg={4}>
                    <Form.Group className="position-relative">
                      <div className="file d-flex align-items-center justify-content-between flex-row">
                        <Form.Label className="btn btn-light px-lg-4 ms-0 position-relative d-inline-flex align-items-center justify-content-center">
                          <span>Upload</span>
                          <Form.Control
                            type="file"
                            className="position-absolute z--1 start-0 top-0 h-100"
                            accept="image/png, image/jpeg"
                            value=""
                            onChange={(e) => {
                              resizeFile(e.target.files[0], "logo_file");
                            }}
                          />
                        </Form.Label>
                        <span
                          className="form-label d-flex align-items-center justify-content-center ms-1"
                          id="file"
                        >
                          {values?.logo_file ? (
                            <>
                              <p className="p-0 m-0 text-truncate">
                                {values?.logo_file}
                              </p>
                              <span
                                className="text-reset btn-close ms-2 cursor-pointer"
                                title="delete file"
                                onClick={() =>
                                  setForm({ ...form, logo_file: "" })
                                }
                              ></span>
                            </>
                          ) : (
                            "No file chosen"
                          )}
                        </span>
                      </div>
                    </Form.Group>
                  </Col>
                </Row>
              </div>

              <div className="mb-2">
                <Row className="align-items-center g-2 ">
                  <Col sm={4} lg={3} className="text-start mb-2 mb-sm-0">
                    {/* <Form.Check
                      inline
                      name="po_wo_logo"
                      type="radio"
                      id="po"
                      label="Use Signature"
                      defaultChecked="checked"
                      hidden
                    /> */}
                    <Form.Label className="fs-6 lh-base col-form-label py-0">
                      Use Signature
                    </Form.Label>
                  </Col>
                  <Col sm={5} lg={4}>
                    <Form.Group className="position-relative">
                      <div className="file d-flex align-items-center justify-content-between flex-row">
                        <Form.Label className="btn btn-light px-lg-4 ms-0 position-relative d-inline-flex align-items-center justify-content-center">
                          <span>Upload</span>
                          <Form.Control
                            type="file"
                            className="position-absolute z--1 start-0 top-0 h-100"
                            accept="image/png, image/jpeg"
                            value=""
                            onChange={(e) => {
                              resizeFile(e.target.files[0], "signature_file");
                            }}
                          />
                        </Form.Label>
                        <span
                          className="form-label d-flex align-items-center justify-content-center ms-1"
                          id="file"
                        >
                          {values?.signature_file ? (
                            <>
                              <p className="p-0 m-0 text-truncate">
                                {values?.signature_file}
                              </p>
                              <span
                                className="text-reset btn-close ms-2 cursor-pointer"
                                title="delete file"
                                onClick={() =>
                                  setForm({ ...form, signature_file: "" })
                                }
                              ></span>
                            </>
                          ) : (
                            "No file chosen"
                          )}
                        </span>
                      </div>
                    </Form.Group>
                  </Col>
                </Row>
              </div>

              <div className="mb-2">
                <Row className="align-items-center g-2 ">
                  <Col sm={4} lg={3} className="text-start mb-2 mb-sm-0">
                    <Form.Label className="fs-6 lh-base col-form-label py-0">
                      PO Number
                    </Form.Label>
                  </Col>
                  <Col sm={5} lg={4}>
                    <Form.Group className="position-relative">
                      <Form.Control
                        type="text"
                        name="number"
                        tabIndex="1"
                        className=""
                        value={values.number}
                        onChange={(e) => {
                          handleChange(e);
                          setForm({ ...form, [e.target.name]: e.target.value });
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </div>

              <div className="mb-2">
                <Row className="align-items-center g-2 ">
                  <Col sm={4} lg={3} className="text-start mb-2 mb-sm-0">
                    <Form.Label className="fs-6 lh-base col-form-label py-0">
                      Date
                    </Form.Label>
                  </Col>
                  <Col sm={5} lg={4}>
                    <Calendar
                      onUpdate={dateUpdateHandler}
                      date={values.deadline}
                    />
                    {deadlineError && !values.deadline && (
                      <Form.Control.Feedback type="invalid">
                        Date is a required field
                      </Form.Control.Feedback>
                    )}
                  </Col>
                </Row>
              </div>

              <div className="mb-2">
                <Row className="align-items-start g-2 ">
                  <Col sm={4} lg={3} className="text-start mb-2 mb-sm-0">
                    <Form.Label className="fs-6 lh-base col-form-label py-0">
                      Order By
                    </Form.Label>
                  </Col>
                  <Col sm={5} lg={4}>
                    <Form.Group className="position-relative">
                      <Form.Control
                        type="text"
                        name="order_by"
                        tabIndex="2"
                        className=""
                        value={values.order_by}
                        onChange={(e) => {
                          handleChange(e);
                          setForm({ ...form, [e.target.name]: e.target.value });
                        }}
                        as="textarea"
                        rows={2}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </div>

              <div className="mb-2">
                <Row className="align-items-start g-2 ">
                  <Col sm={4} lg={3} className="text-start mb-2 mb-sm-0">
                    <Form.Label className="fs-6 lh-base col-form-label py-0">
                      Order To
                    </Form.Label>
                  </Col>
                  <Col sm={5} lg={4}>
                    <Form.Group className="position-relative">
                      <Form.Control
                        type="text"
                        name="order_to"
                        tabIndex="3"
                        className=""
                        value={values.order_to}
                        onChange={(e) => {
                          handleChange(e);
                          setForm({ ...form, [e.target.name]: e.target.value });
                        }}
                        as="textarea"
                        rows={2}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </div>

              <div className="mb-2">
                <Row className="align-items-start g-2 ">
                  <Col sm={4} lg={3} className="text-start mb-2 mb-sm-0">
                    <Form.Label className="fs-6 lh-base col-form-label py-0 mb-2">
                      Select Price
                    </Form.Label>

                    <ul className="list-unstyled mb-0">
                      <li className="mb-2" key="wo-l1-price-radio">
                        <Form.Check
                          inline
                          type="radio"
                          id="wo-l1-price-radio"
                          label="L1 Price"
                          checked={values.priceType === "L1 Price"}
                          onChange={() => {
                            setForm({ ...form, priceType: "L1 Price" });
                            setBidsCandidate(minBidsList);
                          }}
                          className="fw-normal me-40"
                        />
                      </li>
                      <li className="mb-2" key="wo-contractor-price-radio">
                        <Form.Check
                          inline
                          type="radio"
                          id="wo-contractor-price-radio"
                          label="Contractor Price"
                          checked={values.priceType === "Contractor Price"}
                          onChange={() => {
                            setForm({
                              ...form,
                              priceType: "Contractor Price"
                            });
                            setBidsCandidate(bidsBySelectedContractor);
                          }}
                          className="fw-normal me-60"
                        />
                      </li>
                      <li className="mb-2" key="wo-reset-radio">
                        <Form.Check
                          inline
                          type="radio"
                          id="wo-reset-radio"
                          label="Reset"
                          checked={values.priceType === "Reset"}
                          onChange={() => {
                            setForm({
                              ...form,
                              priceType: "Reset"
                            });
                            setBidsCandidate(resetBidsList);
                          }}
                          className="fw-normal me-40"
                        />
                      </li>
                    </ul>
                  </Col>
                </Row>
              </div>

              <div className="mb-2 py-2">
                <Form.Label className="fs-6 lh-base col-form-label py-0 mb-2">
                  List of Items
                </Form.Label>

                <PoWoCreateTable
                  items={values.items}
                  onItemsUpdate={setItemsCandidate}
                  taxes={taxes}
                  totalPrice={totalPrice}
                  grandTotal={grandTotal}
                  totalTaxPrice={totalTaxPrice}
                />
              </div>

              <div className="mb-2">
                <Row className="align-items-start g-2 ">
                  <Col sm={4} lg={3} className="text-start mb-2 mb-sm-0">
                    <Form.Label className="fs-6 lh-base col-form-label py-0">
                      Enter Terms and Conditions
                    </Form.Label>
                  </Col>
                  <Col sm={5} lg={4}>
                    <Form.Group className="position-relative">
                      <Form.Control
                        type="text"
                        name="terms"
                        tabIndex="4"
                        className=""
                        value={values.terms}
                        onChange={(e) => {
                          handleChange(e);
                          setForm({ ...form, [e.target.name]: e.target.value });
                        }}
                        as="textarea"
                        rows={2}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </div>

              <div className="mb-2">
                <Row className="align-items-start g-2 ">
                  <Col sm={4} lg={3} className="text-start mb-2 mb-sm-0">
                    <Form.Label className="fs-6 lh-base col-form-label py-0">
                      Enter Additional Notes
                    </Form.Label>
                  </Col>
                  <Col sm={5} lg={4}>
                    <Form.Group className="position-relative">
                      <Form.Control
                        type="text"
                        name="notes"
                        tabIndex="5"
                        className=""
                        value={values.notes}
                        onChange={(e) => {
                          handleChange(e);
                          setForm({ ...form, [e.target.name]: e.target.value });
                        }}
                        as="textarea"
                        rows={2}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </div>

              <div className="text-end">
                <Button
                  variant="light"
                  onClick={() => setCreateOrder(false)}
                  className="px-30 ms-3"
                >
                  Back
                </Button>
                <Button
                  variant="primary"
                  onClick={() => {
                    if (!values.deadline) {
                      return setShowError(true);
                    }
                    handleShow();
                  }}
                  className="px-30 ms-3"
                >
                  Preview
                </Button>
              </div>
            </Form>
          )}
        </Formik>

        {/* <div className="py-4 dotted mx-n4 px-4 mt-36">
          <div className="table-rounded mb-0 shadow bg-white rounded-1">
            <Table striped responsive className="mb-0">
              <thead>
                <tr>
                  <th className="text-center">
                    <div>PO/WO Number</div>
                  </th>
                  <th>
                    <div>Issued To </div>
                  </th>
                  <th>
                    <div>Date</div>
                  </th>
                  <th>
                    <div>Emailed </div>
                  </th>
                  <th>
                    <div>Links</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="text-center">001 </td>
                  <td>ABC Contractor</td>
                  <td>12/11/2021</td>
                  <td>Yes</td>
                  <td>
                    <a
                      href="/"
                      className="d-block text-reset text-decoration-none"
                    >
                      File1.html
                    </a>
                    <a
                      href="/"
                      className="d-block text-reset text-decoration-none"
                    >
                      file2.pdf
                    </a>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div> */}
      </div>
    </>
  );
}
