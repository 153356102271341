import { Modal } from "react-bootstrap";

import LoginForm from "../views/Registration/LoginForm";

export default function ModalLogin({ show, onHide }) {
  return (
    <>
      <Modal show={show} onHide={onHide} size="xs">
        <Modal.Header closeButton className="pb-0">
          <Modal.Title>&nbsp;</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <LoginForm />
        </Modal.Body>
      </Modal>
    </>
  );
}
