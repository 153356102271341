import { createSlice } from "@reduxjs/toolkit";
import { blogApi } from "../services/blogApi";

/* REFRESH TOKEN ON CLIENT */
// const minutesToMs = min => min * 60 * 1000;
// const notExpireUntilSet = Date.now() + minutesToMs(60*24)

const initialState = {
  posts: [],
  postsFeatured: []
};

const blogSlice = createSlice({
  name: "blog",
  initialState,
  reducers: {
    resetData: () => initialState
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        blogApi.endpoints.fetchPosts.matchFulfilled,
        (state, action) => {
          state.posts = action.payload.data;
        }
      );
  }
});

const { actions, reducer } = blogSlice;
export const {
  resetData
} = actions;
export default reducer;
