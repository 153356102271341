import { Form, Dropdown } from "react-bootstrap";
import { useSelector } from "react-redux";

import { TENDER_STATUSES, CLIENT_TYPE } from "../../utils/constants";

export default function StatusDropdown({ status, onChange }) {
  const { user } = useSelector((state) => state.userStore);

  const clearText = "Clear filter";
  const draftList = user.role === CLIENT_TYPE.employer ? [TENDER_STATUSES.draft] : [];
  const statusesList = [
    ...draftList,
    TENDER_STATUSES.active,
    TENDER_STATUSES.biddingComplete,
    TENDER_STATUSES.finalized,
    clearText
  ];

  const clickHandler = (value) => {
    const newValue = value === clearText ? "" : value;
    onChange(newValue);
  };
  return (
    <Dropdown>
      <Dropdown.Toggle variant="" id="dropdown-status">
        Status
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {statusesList.map((s, index) => (
          <Dropdown.Item key={index} as="li" onClick={() => clickHandler(s)}>
            <Form.Check
              inline
              name="Status"
              type="radio"
              checked={status === s}
              label={s}
              onChange={() => clickHandler(s)}
            />
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}
