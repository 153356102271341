import { Formik } from "formik";
import { Row, Col, Form, Button } from "react-bootstrap";
import { useCallback, useEffect, useState, useMemo } from "react";
import * as yup from "yup";
import { useNavigate, useLocation } from "react-router-dom";

import { EMAIL_REGEXP, APP_ROUTE } from "../../utils/constants";
import {
  useAlreadyInvitedMutation,
  useDeleteEmailInvitedContractorMutation,
  useSendInvitationEmailMutation
} from "../../redux/services/tenderApi";
import { Loader, CreateDynamicLink } from "../../components";
import { IconError, IconSuccess } from "../../utils/svgHelper";

export default function TenderLive() {
  const navigate = useNavigate();

  const [invitedContractorList, setInvitedContractorList] = useState([]);
  const [tenderDetails, setTenderDetails] = useState({});

  const [sendInvitationEmail, { isLoading: isLoadingSendInvitationEmail }] =
    useSendInvitationEmailMutation();
  const [alreadyInvited, { isLoading: isLoadingAlreadyInvited }] =
    useAlreadyInvitedMutation();
  const [deleteEmailInvitedContractor, { isLoading: isLoadingDeleteEmail }] =
    useDeleteEmailInvitedContractorMutation();
  const isLoading = isLoadingAlreadyInvited || isLoadingDeleteEmail;
  const { state } = useLocation();
  const schema = yup.object().shape({
    email: yup
      .string()
      .trim()
      .required("Email is a required field")
      .matches(EMAIL_REGEXP, "Email must be a valid email")
      .test("user-email", "This user has already been added", function (email) {
        const result = invitedContractorList.some(
          (item) => item.email === email
        );
        return !result;
      })
  });

  useEffect(() => {
    setTenderDetails(state.tenderDetail);
  }, [state]);

  const getInvitedContractors = useCallback(async () => {
    const { data } = await alreadyInvited(tenderDetails.id);
    setInvitedContractorList(data.data);
  }, [tenderDetails]);

  useEffect(() => {
    async function fetchData() {
      await getInvitedContractors();
    }
    tenderDetails?.id && fetchData();
  }, [tenderDetails]);

  const newContractors = useMemo(
    () => invitedContractorList.filter((i) => i.is_new_user),
    [invitedContractorList]
  );

  const existedContractors = useMemo(
    () => invitedContractorList.filter((i) => !i.is_new_user),
    [invitedContractorList]
  );

  const handleSubmit = useCallback(
    async (values, { setErrors, resetForm }) => {
      try {
        const { data, error } = await sendInvitationEmail({
          tenderId: tenderDetails.id,
          body: {
            email: values.email.trim().toLowerCase(),
            tender: tenderDetails.id
          }
        });
        if (error && error.data.errors.length !== 0) {
          setErrors(error.data.errors[0]);
          return;
        }
        if (data) {
          setInvitedContractorList([...invitedContractorList, data.data]);
        }
      } catch (e) {
        console.log("Unhandled login error :", e);
      }
      resetForm({ values: "" });
    },
    [tenderDetails, invitedContractorList]
  );

  const deleteInvitedContractorHandler = async (contractorId) => {
    await deleteEmailInvitedContractor({
      tenderId: tenderDetails.id,
      id: contractorId
    });
    await getInvitedContractors();
  };
  return (
    <>
      <h1 className="h1 fw-bolder mb-14">{`Your Tender "${tenderDetails?.title}" is Now Live!`}</h1>
      <Formik
        validateOnChange
        initialValues={{ email: "" }}
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          isValid,
          handleSubmit
        }) => (
          <Form className="form" onSubmit={handleSubmit}>
            <Row className="justify-content-between">
              <Col md={6} lg={6} className="mb-3 mb-lg-0">
                <h6 className="fsz-18 mb-10">Invite Contractors</h6>

                <div className="row g-2">
                  <div className="col-lg-10 col-xl-8 ">
                    <div className="position-relative mb-5 pb-sm-0 pe-4 me-md-n4">
                      <div className="position-relative">
                        <Form.Control
                          type="text"
                          name="email"
                          value={values.email}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          placeholder="Email"
                          className={`${touched.email ? "is-touch " : ""} ${
                            errors.email && touched.email ? " is-invalid" : ""
                          } ${
                            !errors.email && touched.email ? " is-valid" : ""
                          }`}
                        />
                        {errors.email && touched.email && <IconError />}

                        {!errors.email && touched.email && <IconSuccess />}
                      </div>
                      {(errors.email || errors.non_field_errors) && (
                        <Form.Control.Feedback
                          type="invalid"
                          className="position-absolute start-0 top-100"
                        >
                          {errors.email}
                          {errors.non_field_errors}
                        </Form.Control.Feedback>
                      )}
                    </div>
                  </div>

                  {/* <div className="col-sm-5 mb-3">
                    <Button
                        variant="light"
                        className="px-4 px-xl-36"
                      >
                        + Add More
                      </Button>
                  </div> */}
                </div>

                <div className="row g-2">
                  <div className="col-lg-10 col-xl-8 mb-4">
                    <Button
                      variant="primary"
                      type="submit"
                      className="px-4 w-100"
                      disabled={!isValid}
                    >
                      Send Invitation Email{" "}
                      {isLoadingSendInvitationEmail && <Loader />}
                    </Button>

                    <div className="mt-4 pt-3">
                      <CreateDynamicLink
                        tender={tenderDetails}
                        onUpdate={setTenderDetails}
                      />
                    </div>
                  </div>
                </div>
              </Col>

              <Col md={5} lg={4}>
                <InvitedContractorBlock
                  title="Already Invited"
                  isLoading={isLoading}
                  list={existedContractors}
                  onDelete={deleteInvitedContractorHandler}
                />

                <InvitedContractorBlock
                  title="New Users Invited"
                  isLoading={isLoading}
                  list={newContractors}
                  onDelete={deleteInvitedContractorHandler}
                />
              </Col>
            </Row>

            <div className="text-end pt-30 pt-xl-6 mt-xl-6">
              <Button
                type="submit"
                variant="light"
                className="px-36"
                onClick={() =>
                  navigate(
                    `${APP_ROUTE.EMPLOYER_TENDER_DETAIL}/${tenderDetails.id}`
                  )
                }
              >
                Skip For Now
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}

function InvitedContractorBlock({ title, isLoading, list, onDelete }) {
  return (
    <>
      <h6 className="fsz-18 mb-10">{title}</h6>

      <ul className="list-unstyled">
        {isLoading ? (
          <Loader />
        ) : list.length === 0 ? (
          <p className="form-label fw-normal m-0">No results found</p>
        ) : (
          list.map((contractor) => (
            <InvitedContractor
              key={contractor.id}
              contractor={contractor}
              onDelete={onDelete}
            />
          ))
        )}
      </ul>
    </>
  );
}

function InvitedContractor({ contractor, onDelete }) {
  return (
    <li className="mb-10">
      <span className="form-label fw-normal">{contractor.email}</span>
      <Button
        variant=""
        className="text-reset btn-close"
        onClick={() => onDelete(contractor.id)}
      />
    </li>
  );
}
