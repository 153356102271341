export default function SortableCell({ name, sortBy, title, className }) {
  const sortName = name === "Tender" ? name.toLowerCase() : name;
  return (
    <th className={className}>
      <div
        data-bs-toggle="tooltip"
        data-bs-placement="top"
        title={title}
        className="cursor-pointer"
        onClick={() => sortBy(sortName)}
      >
        {name}
      </div>
    </th>
  );
}
