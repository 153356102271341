import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ButtonGroup, Button, Collapse } from "react-bootstrap";
import { NavLink, useLocation } from "react-router-dom";

import { useGetStatsMutation } from "../redux/services/authApi";

import { APP_ROUTE, CLIENT_TYPE } from "../utils/constants";
import IconItem from "../components/IconItem";

export default function Sidebar() {
  const location = useLocation();
  const [openMail, setOpenMail] = useState(false);
  const [openSettings, setOpenSettings] = useState(false);
  const { state } = useLocation();

  const [view, setView] = useState(CLIENT_TYPE.employer);
  const { user, statistics } = useSelector((state) => state.userStore);

  useEffect(() => {
    user.role === CLIENT_TYPE.employer
      ? setView(CLIENT_TYPE.employer)
      : setView(CLIENT_TYPE.contractor);
  }, []);

  const [getStats] = useGetStatsMutation();

  useEffect(() => {
    async function fetchData() {
      if (location.pathname !== APP_ROUTE.NOTIFICATIONS) {
        await getStats();
      }
    }
    fetchData();
  }, [location]);

  useEffect(() => {
    setOpenMail(state?.status);
  }, [state]);

  const collapse = () => {
    setOpenMail(false);
    setOpenSettings(false);
  };

  return (
    <>
      <ButtonGroup vertical className="bg-white h-100 justify-content-start pt-0 pt-lg-40">
        {view === CLIENT_TYPE.contractor && (
          <>
            <NavLink
              to={APP_ROUTE.CONTRACTOR_BIDS}
              className="btn-group-item text-reset text-decoration-none"
            >
              <Button variant="link" className="text-start" onClick={collapse}>
                <span className="position-relative me-3">
                  <IconItem icon="cart" />
                </span>
                <span>My bids</span>
              </Button>
            </NavLink>

            <NavLink
              to={APP_ROUTE.PROFILE}
              className="btn-group-item text-reset text-decoration-none"
            >
              <Button variant="link" className="text-start" onClick={collapse}>
                <span className="position-relative me-3">
                  <IconItem icon="profile" />
                </span>
                <span>My Profile</span>
              </Button>
            </NavLink>

            <NavLink
              to={APP_ROUTE.CONTRACTOR_TENDER_SEARCH}
              className="btn-group-item text-reset text-decoration-none"
            >
              <Button variant="link" className="text-start" onClick={collapse}>
                <span className="position-relative me-3">
                  <IconItem icon="search" />
                </span>
                <span>Search for Tenders</span>
              </Button>
            </NavLink>
          </>
        )}

        {view === CLIENT_TYPE.employer && (
          <>
            <NavLink
              to={APP_ROUTE.EMPLOYER_TENDERS}
              className="btn-group-item text-reset text-decoration-none"
            >
              <Button variant="link" className="text-start" onClick={collapse}>
                <span className="position-relative me-3">
                  <IconItem icon="mallet" />
                </span>
                <span>My Tenders</span>
              </Button>
            </NavLink>

            <NavLink
              to={APP_ROUTE.PROFILE}
              className="btn-group-item text-reset text-decoration-none"
            >
              <Button variant="link" className="text-start" onClick={collapse}>
                <span className="position-relative me-3">
                  <IconItem icon="profile" />
                </span>
                <span>My Profile</span>
              </Button>
            </NavLink>

            <NavLink
              to={APP_ROUTE.EMPLOYER_TENDER_NEW}
              className="btn-group-item text-reset text-decoration-none"
            >
              <Button variant="link" className="text-start" onClick={collapse}>
                <span className="position-relative me-3">
                  <IconItem icon="create" />
                </span>
                <span>Create New Tender</span>
              </Button>
            </NavLink>

            <NavLink
              to={APP_ROUTE.EMPLOYER_PROVIDER_SEARCH}
              className="btn-group-item text-reset text-decoration-none"
            >
              <Button variant="link" className="text-start" onClick={collapse}>
                <span className="position-relative me-3">
                  <IconItem icon="search" />
                </span>
                <span>Search for Contractors</span>
              </Button>
            </NavLink>
          </>
        )}

        <div className="btn-group-item">
          <Button
            variant="link"
            data-bs-toggle="collapse"
            className="text-start"
            onClick={() => {
              setOpenSettings(false);
              setOpenMail(!openMail);
            }}
            aria-controls="example-collapse-text"
            aria-expanded={openMail}
          >
            <span className="position-relative me-3">
              <IconItem icon="mail" />
              {statistics.inbox_messages_count > 0 && (
                <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-warning">
                  {statistics.inbox_messages_count}
                  <span className="visually-hidden">unread messages</span>
                </span>
              )}
            </span>
            <span>Mail</span>
          </Button>
          <Collapse in={openMail}>
            <ul className="list-unstyled mb-0 bg-primary text-white">
              <li>
                <NavLink
                  to={APP_ROUTE.INBOX}
                  className="text-reset text-decoration-none"
                >
                  Inbox
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={APP_ROUTE.SENT_MESSAGES}
                  className="text-reset text-decoration-none"
                >
                  Sent Messages
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={APP_ROUTE.NEW_MESSAGE}
                  className="text-reset text-decoration-none"
                >
                  New Messages
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={APP_ROUTE.MAIL_TRASH}
                  className="text-reset text-decoration-none"
                >
                  Trash
                </NavLink>
              </li>
            </ul>
          </Collapse>
        </div>

        {view === CLIENT_TYPE.employer && (
          <NavLink
            to={APP_ROUTE.POWO_EMPLOYER}
            className="btn-group-item text-reset text-decoration-none"
          >
            <Button variant="link" className="text-start" onClick={collapse}>
              <span className="position-relative me-3">
                <IconItem icon="po_wo" />
              </span>
              <span>PO/WO</span>
            </Button>
          </NavLink>
        )}

        {/* {view === CLIENT_TYPE.contractor && (
          <NavLink
            to={APP_ROUTE.POWO_CONTRACTOR}
            className="btn-group-item text-reset text-decoration-none"
          >
            <Button variant="link" className="text-start" onClick={collapse}>
              <span className="position-relative me-3">
                <IconItem icon="po_wo" />
              </span>
              <span>PO/WO</span>
            </Button>
          </NavLink>
        )} */}

        <div className="btn-group-item">
          <Button
            variant="link"
            data-bs-toggle="collapse"
            className="text-start"
            onClick={() => {
              setOpenMail(false);
              setOpenSettings(!openSettings);
            }}
            aria-controls="example-collapse-text"
            aria-expanded={openSettings}
          >
            <span className="position-relative me-3">
              <IconItem icon="settings" />
            </span>
            <span>Settings</span>
          </Button>
          <Collapse in={openSettings}>
            <ul className="list-unstyled mb-0 bg-primary text-white">
              <li>
                <NavLink
                  to={APP_ROUTE.CHANGE_PASSWORD}
                  className="text-reset text-decoration-none"
                >
                  Change password
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={APP_ROUTE.SETTINGS_NOTIFICATIONS}
                  className="text-reset text-decoration-none"
                >
                  Notification settings
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={APP_ROUTE.SETTINGS_EMAIL}
                  className="text-reset text-decoration-none"
                >
                  Email settings
                </NavLink>
              </li>
              {/* <li>
                <NavLink
                  to={APP_ROUTE.SETTINGS_SMS}
                  className="text-reset text-decoration-none"
                >
                  SMS settings
                </NavLink>
              </li> */}
              <li>
                <NavLink
                  to={APP_ROUTE.SETTINGS_LOG}
                  className="text-reset text-decoration-none"
                >
                  Activity Log
                </NavLink>
              </li>
            </ul>
          </Collapse>
        </div>

        <NavLink
          to={APP_ROUTE.CONTACT_US}
          className="btn-group-item text-reset text-decoration-none"
        >
          <Button variant="link" className="text-start" onClick={collapse}>
            <span className="position-relative me-3">
              <IconItem icon="contact" />
            </span>
            <span>Contact Us</span>
          </Button>
        </NavLink>

        <NavLink
          to={APP_ROUTE.FAQ}
          className="btn-group-item text-reset text-decoration-none"
        >
          <Button variant="link" className="text-start" onClick={collapse}>
            <span className="position-relative me-3">
              <IconItem icon="faq" />
            </span>
            <span>FAQ</span>
          </Button>
        </NavLink>
      </ButtonGroup>
    </>
  );
}
