import { useState, useRef } from "react";
import { Row, Col, Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import Resizer from "react-image-file-resizer";

import { APP_ROUTE } from "../../utils/constants";
import {
  nameVerification,
  phoneNumberVerification
} from "../../utils/yupHelper";
import { IconError, IconSuccess } from "../../utils/svgHelper";
import {
  usePatchProfileMutation,
  useUpdateProfileImageMutation,
  useDeleteProfileImageMutation
} from "../../redux/services/authApi";
import { setUser } from "../../redux/slices/userSlice";
import { Loader } from "../../components";

export default function ProfileChange() {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.userStore);
  const [patchProfile, { isLoading: isLoadingPathProfile }] =
    usePatchProfileMutation();
  const [updateProfileImage, { isLoading: isLoadingUpdateProfileImage }] =
    useUpdateProfileImageMutation();
  const [deleteProfileImage, { isLoading: isLoadingDeleteProfileImage }] =
    useDeleteProfileImageMutation();
  const dispatch = useDispatch();

  const isLoading =
    isLoadingPathProfile ||
    isLoadingUpdateProfileImage ||
    isLoadingDeleteProfileImage;

  const inputFile = useRef();
  const [image, setImage] = useState(user.image);
  const [deleteImage, setDeleteImage] = useState(false);
  const [updateImage, setUpdateImage] = useState(false);

  const schema = yup.object().shape({
    name: nameVerification,
    phone_number: phoneNumberVerification,
    gst: yup.string().nullable().max(15),
    pan: yup.string().nullable().max(10)
  });

  const handleSubmit = async (values, { setErrors }) => {
    const tehData = {
      full_name: values.name,
      phone_number: values.phone_number,
      gst: values.gst,
      pan: values.pan,
      description: values.description
    };
    try {
      if (deleteImage && user.image) {
        await deleteProfileImage();
      }
      if (updateImage && image) {
        await updateProfileImage({ body: image });
      }
      const { data, error } = await patchProfile(tehData);
      if (error && error.data.errors.length !== 0) {
        setErrors({
          ...error.data.errors[0],
          name: error.data.errors[0].full_name
        });
        return;
      }
      dispatch(setUser({ ...data.data, name: values.name }));
      navigate(APP_ROUTE.PROFILE);
    } catch (err) {
      console.log("Unhandled login error :", err);
    }
  };

  const resizeFile = (file) => {
    Resizer.imageFileResizer(
      file,
      600,
      480,
      "JPEG",
      100,
      0,
      (uri) => setImage(uri),
      "file",
      350,
      300
    );
  };

  return (
    <>
      <h1 className="h1 fw-bolder mb-14">Change Profile</h1>

      <Row className="row justify-content-between">
        <Col md={3} lg={4} className="mb-4">
          {image && (
            <img
              src={
                typeof image === "string" ? image : URL.createObjectURL(image)
              }
              key="profile_img"
              alt="Profile img"
              className="img-fluid"
            />
          )}
          <input
            type="file"
            ref={inputFile}
            accept="image/png, image/jpeg"
            onChange={async (e) => {
              resizeFile(e.target.files[0]);
              setUpdateImage(true);
            }}
            style={{ display: "none" }}
          ></input>
          <div className="text-center">
            <Button
              variant="link"
              onClick={() => inputFile.current.click()}
              className="w-100"
            >
              Change the image
            </Button>
            {image && (
              <Button
                variant="link"
                onClick={() => {
                  if (typeof image === "string") {
                    setDeleteImage(true);
                  }
                  setImage(null);
                }}
                className="w-100"
              >
                Delete
              </Button>
            )}
          </div>
        </Col>

        <Col md={9} lg={8}>
          <Formik
            validateOnChange
            initialValues={{
              name: user.name,
              phone_number: user.phone_number,
              gst: user.gst,
              pan: user.pan,
              description: user.description
            }}
            validationSchema={schema}
            onSubmit={handleSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              isValid,
              handleSubmit,
              dirty
            }) => (
              <Form className="form" onSubmit={handleSubmit}>
                <div className="mb-4">
                  <Row className="align-items-center">
                    <Col sm={3}>
                      <Form.Label className="fs-6 lh-base col-form-label py-0">
                        Name:
                      </Form.Label>
                    </Col>
                    <Col sm={9}>
                      <div className="position-relative">
                        <div className="position-relative">
                          <Form.Control
                            type="text"
                            name="name"
                            className={`${touched.name ? "is-touch " : ""} ${
                              errors.name && touched.name ? " is-invalid" : ""
                            } ${
                              !errors.name && touched.name ? " is-valid" : ""
                            }`}
                            value={values.name}
                            onBlur={handleBlur}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            placeholder="Name"
                          />

                          {errors.name && touched.name && <IconError />}
                          {!errors.name && touched.name && <IconSuccess />}
                        </div>
                        {errors.name && (
                          <Form.Control.Feedback
                            type="invalid"
                            className="position-absolute start-0 top-100"
                          >
                            {errors.name}
                          </Form.Control.Feedback>
                        )}
                      </div>
                    </Col>
                  </Row>
                </div>

                <div className="mb-4">
                  <Row className="align-items-center">
                    <Col sm={3}>
                      <Form.Label className="fs-6 lh-base col-form-label py-0">
                        Email:
                      </Form.Label>
                    </Col>
                    <Col sm={9}>
                      <div className="position-relative">
                        <div className="position-relative">
                          <Form.Control
                            type="text"
                            name="email"
                            className={`${touched.email ? "is-touch " : ""} ${
                              errors.email && touched.email ? " is-invalid" : ""
                            } ${
                              !errors.email && touched.email ? " is-valid" : ""
                            }`}
                            value={user.email}
                            onBlur={handleBlur}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                          />

                          {errors.email && touched.email && <IconError />}
                          {!errors.email && touched.email && <IconSuccess />}
                        </div>
                        {errors.email && (
                          <Form.Control.Feedback
                            type="invalid"
                            className="position-absolute start-0 top-100"
                          >
                            {errors.email}
                          </Form.Control.Feedback>
                        )}
                      </div>
                    </Col>
                  </Row>
                </div>

                <div className="mb-4">
                  <Row className="align-items-center">
                    <Col sm={3}>
                      <Form.Label className="fs-6 lh-base col-form-label py-0">
                        Mobile phone number:
                      </Form.Label>
                    </Col>
                    <Col sm={9}>
                      <div className="position-relative">
                        <div className="position-relative">
                          <Form.Control
                            type="text"
                            name="phone_number"
                            className={`${
                              touched.phone_number ? "is-touch " : ""
                            } ${
                              errors.phone_number && touched.phone_number
                                ? " is-invalid"
                                : ""
                            } ${
                              !errors.phone_number && touched.phone_number
                                ? " is-valid"
                                : ""
                            }`}
                            value={values.phone_number}
                            onBlur={handleBlur}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                          />

                          {errors.phone_number && touched.phone_number && (
                            <IconError />
                          )}
                          {!errors.phone_number && touched.phone_number && (
                            <IconSuccess />
                          )}
                        </div>
                        {errors.phone_number && (
                          <Form.Control.Feedback
                            type="invalid"
                            className="position-absolute start-0 top-100"
                          >
                            {errors.phone_number}
                          </Form.Control.Feedback>
                        )}
                      </div>
                    </Col>
                  </Row>
                </div>

                <div className="mb-4">
                  <Row className="align-items-center">
                    <Col sm={3}>
                      <Form.Label className="fs-6 lh-base col-form-label py-0">
                        Type:
                      </Form.Label>
                    </Col>
                    <Col sm={9}>
                      <div className="position-relative">
                        <div className="position-relative">
                          <Form.Control
                            type="text"
                            name="role"
                            className={`${touched.role ? "is-touch " : ""} ${
                              errors.role && touched.role ? " is-invalid" : ""
                            } ${
                              !errors.role && touched.role ? " is-valid" : ""
                            }`}
                            value={user.role}
                            onBlur={handleBlur}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                          />

                          {errors.role && touched.role && <IconError />}
                          {!errors.role && touched.role && <IconSuccess />}
                        </div>
                        {errors.role && (
                          <Form.Control.Feedback
                            type="invalid"
                            className="position-absolute start-0 top-100"
                          >
                            {errors.role}
                          </Form.Control.Feedback>
                        )}
                      </div>
                    </Col>
                  </Row>
                </div>

                <div className="mb-4">
                  <Row className="align-items-center">
                    <Col sm={3}>
                      <Form.Label className="fs-6 lh-base col-form-label py-0">
                        GST:
                      </Form.Label>
                    </Col>
                    <Col sm={9}>
                      <div className="position-relative">
                        <div className="position-relative">
                          <Form.Control
                            type="text"
                            name="gst"
                            className={`${touched.gst ? "is-touch " : ""} ${
                              errors.gst && touched.gst ? " is-invalid" : ""
                            } ${!errors.gst && touched.gst ? " is-valid" : ""}`}
                            value={values.gst}
                            onBlur={handleBlur}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                          />

                          {errors.gst && touched.gst && <IconError />}
                          {!errors.gst && touched.gst && <IconSuccess />}
                        </div>
                        {errors.gst && (
                          <Form.Control.Feedback
                            type="invalid"
                            className="position-absolute start-0 top-100"
                          >
                            {errors.gst}
                          </Form.Control.Feedback>
                        )}
                      </div>
                    </Col>
                  </Row>
                </div>

                <div className="mb-4">
                  <Row className="align-items-center">
                    <Col sm={3}>
                      <Form.Label className="fs-6 lh-base col-form-label py-0">
                        PAN:
                      </Form.Label>
                    </Col>
                    <Col sm={9}>
                      <div className="position-relative">
                        <div className="position-relative">
                          <Form.Control
                            type="text"
                            name="pan"
                            className={`${touched.pan ? "is-touch " : ""} ${
                              errors.pan && touched.pan ? " is-invalid" : ""
                            } ${!errors.pan && touched.pan ? " is-valid" : ""}`}
                            value={values.pan}
                            onBlur={handleBlur}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                          />

                          {errors.pan && touched.pan && <IconError />}
                          {!errors.pan && touched.pan && <IconSuccess />}
                        </div>
                        {errors.pan && (
                          <Form.Control.Feedback
                            type="invalid"
                            className="position-absolute start-0 top-100"
                          >
                            {errors.pan}
                          </Form.Control.Feedback>
                        )}
                      </div>
                    </Col>
                  </Row>
                </div>

                <div className="mb-4">
                  <Row>
                    <Col sm={3}>
                      <Form.Label className="fs-6 lh-base col-form-label py-0">
                        Description:
                      </Form.Label>
                    </Col>
                    <Col sm={9}>
                      <div className="position-relative">
                        <div className="position-relative">
                          <Form.Control
                            as="textarea"
                            rows={4}
                            name="description"
                            className={`${
                              touched.description ? "is-touch " : ""
                            } ${
                              errors.description && touched.description
                                ? " is-invalid"
                                : ""
                            } ${
                              !errors.description && touched.description
                                ? " is-valid"
                                : ""
                            }`}
                            value={values.description}
                            onBlur={handleBlur}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                          />

                          {errors.description && touched.description && (
                            <IconError />
                          )}
                          {!errors.description && touched.description && (
                            <IconSuccess />
                          )}
                        </div>
                        {errors.description && (
                          <Form.Control.Feedback
                            type="invalid"
                            className="position-absolute start-0 top-100"
                          >
                            {errors.description}
                          </Form.Control.Feedback>
                        )}
                      </div>
                    </Col>
                  </Row>
                </div>

                <Row className="g-0">
                  <Col sm={6} lg={6} xl={5}>
                    <Button
                      variant="primary"
                      className="w-100"
                      onClick={() => handleSubmit(values)}
                      disabled={isLoading}
                    >
                      Save {isLoading && <Loader />}
                    </Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
    </>
  );
}
