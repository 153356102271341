import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { useNotificationsMutation } from "../../redux/services/authApi";
import { Loader, Pagination } from "../../components";
import { transformationTime } from "../../utils/helpersFunctions";
import { APP_ROUTE, CLIENT_TYPE } from "../../utils/constants";

export default function Notifications() {
  const { user } = useSelector((state) => state.userStore);
  const [notifications, { isLoading: isLoadingNotifications }] =
    useNotificationsMutation();
  const [notificationsList, setNotificationsList] = useState([]);
  const navigate = useNavigate();

  const [countPage, setCountPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    async function fetchData() {
      const tehGetNotifications = await notifications(currentPage);
      setNotificationsList(tehGetNotifications.data.data.results);
      setCountPage(tehGetNotifications.data.data.count);
    }
    fetchData();
  }, [currentPage]);

  return (
    <>
      <h1 className="h1 fw-bolder mb-14">Notifications</h1>

      <div className="pb-3">
        {isLoadingNotifications ? (
          <Loader />
        ) : (
          notificationsList?.map((item) => (
            <div
              className={`card mb-2 cursor-pointer ${
                !item.is_read ? "bg-light" : ""
              }`}
              key={item.id}
            >
              <div
                className="card-body pb-2"
                onClick={() => {
                  if (
                    item.target_content_type === "tender" &&
                    user.role === CLIENT_TYPE.employer
                  ) {
                    navigate(
                      `${APP_ROUTE.EMPLOYER_TENDER_DETAIL}/${item.target_object_id}`
                    );
                  } else {
                    navigate(
                      `${APP_ROUTE.CONTRACTOR_BID_SUBMIT}/${item.target_object_id}`
                    );
                  }
                  if (item.target_content_type === "message") {
                    navigate(APP_ROUTE.INBOX_MESSAGE, { state: item });
                  }
                }}
              >
                <h6 className="card-title">{item.title}</h6>
                <p className="card-text">
                  {transformationTime(item.created_at)}
                </p>
              </div>
            </div>
          ))
        )}
      </div>
      {(notificationsList.length > 9 || currentPage !== 1) && (
        <Pagination
          itemsCount={countPage}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      )}
    </>
  );
}
