export const AUTH_API = {
  SIGNUP: "/signup/",
  LOGIN: "/token/",
  ME: "/me/",
  PASSWORD_RESET: "/password/reset/",
  PASSWORD_CHANGE: "/password/change/",
  CONTACT_US: "/contact_us/",
  SEND_MESSAGE: "/send_message/",
  NOTIFICATION_SETTINGS: "/notification_settings/notifications/",
  EMAIL_SETTINGS: "/notification_settings/email/",
  SMS_SETTINGS: "/notification_settings/sms/"
};

export const TENDER_API = {
  CREATE_DRAFT_TENDER: "/employer/tender-draft/"
};

export const URLS_PROCESSED_IN_COMPONENTS = [
  AUTH_API.SIGNUP,
  AUTH_API.LOGIN,
  AUTH_API.ME,
  AUTH_API.PASSWORD_RESET,
  AUTH_API.PASSWORD_CHANGE,
  AUTH_API.CONTACT_US,
  AUTH_API.SEND_MESSAGE,
  AUTH_API.NOTIFICATION_SETTINGS,
  AUTH_API.EMAIL_SETTINGS,
  AUTH_API.SMS_SETTINGS,
  TENDER_API.CREATE_DRAFT_TENDER
];

export const URLS_PROCESSED_IN_COMPONENTS_PART = [
  "/invited_contractors/",
  "/finalize/",
  "/bid/",
  "/orders/",
  "/password/reset/confirm/",
  "/file-comparative-price/",
  "/contractor/tender-un/",
  "/contractor/tender/",
  "/employer/tenders/",
  "/employer/tenders-draft/"
];

export const EMAIL_REGEXP = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

export const STRONG_PASSWORD_REGEXP =
  /^(?=.*[0-9!@#$%^&*()\\[\]{}\-_+=~`|:;"'<>,./?])(?=.*[a-z])(?=(.*[A-Z]))(?=(.*)).{8,}$/;
export const PASSWORD_REGEXP =
  /^[a-zA-Z0-9!@#$%^&*()\\[\]{}\-_+=~`|:;"'<>,./?]{6,}$/;

export const TENDER_STATUSES = {
  draft: "Draft",
  active: "Active",
  biddingComplete: "Bidding Complete",
  finalized: "Finalized"
};

export const PRICING_VISIBILITY = {
  openView: "Open View",
  employerView: "Employer View",
  closedView: "Closed View"
};

export const PRICING_VISIBILITY_DESCRIPTION = {
  "Open View":
    "Open View (Both Employer and Contractor can see all bids at all times)",
  "Employer View":
    "Employer View (Only Employer can see all bids at all times)",
  "Closed View": "Closed View (No one can see bids till deadline)"
};

export const VISIBILITY = {
  public: "Public",
  private: "Private"
};

export const VISIBILITY_DESCRIPTION = {
  Public:
    "Public (a Public tender is visible to everyone. Any Contractor can view and bid)",
  Private:
    "Private (a Private tender is only visible to Contractors invited by the employer)"
};

export const ORDER_TYPE = {
  "Purchase Order": "Purchase Order",
  "Work Order": "Work Order"
};

export const CLIENT_TYPE = {
  employer: "Employer",
  contractor: "Contractor"
};

export const NOTIFICATION_LABELS = {
  change_tender: "Change in Tender",
  withdraw_tender: "Withdraw of Tender",
  end_tender: "End of Tender",
  private_message: "Private message",
  reminder_before_deadline: "Reminder to Invited Companies before deadline",
  bid_submission: "Bid Submission",
  change_bid: "Change bid",
  new_bid: "New bid",
  withdraw_bid: "Withdraw bid",
  cancel_invite: "Cancel invite to Tender",
  invite_send: "Invite to Tender",
  invite_canceled: "Cancel invite to Tender"
};

export const PRICING_TYPE = {
  showQuotes: "Show Quotes",
  showRanks: "Show Ranks"
};

export const BLOG_API = { BLOG: "/blog" };

export const APP_ROUTE = {
  CONTRACTOR_BID_SUBMIT: "/contractor/bid",
  CONTRACTOR_TENDER_SEARCH: "/contractor/tender-search",
  EMPLOYER_PROFILE: "/employer/profile",
  CONTRACTOR_BIDS: "/contractor/bids",
  CONTRACTOR_PROFILE: "/contractor/profile",
  EMPLOYER_TENDERS: "/employer/tenders",
  EMPLOYER_TENDER_NEW: "/employer/tender-new",
  EMPLOYER_TENDER_EDIT: "/employer/tender-edit",
  EMPLOYER_PROVIDER_SEARCH: "/employer/provider-search",
  EMPLOYER_TENDER_INVITE: "/employer/tender-invite",
  EMPLOYER_TENDER_DETAIL: "/employer/tender-detail",
  PROFILE: "/profile",
  PROFILE_CHANGE: "/profile-change",
  NOTIFICATIONS: "/notifications",
  INBOX: "/mail/inbox",
  INBOX_MESSAGE: "/mail/inbox-message",
  SENT_MESSAGES: "/mail/sent-messages",
  NEW_MESSAGE: "/mail/new-message",
  MAIL_TRASH: "/mail/trash",
  CHANGE_PASSWORD: "/settings/change-password",
  SETTINGS_NOTIFICATIONS: "/settings/notifications",
  SETTINGS_EMAIL: "/settings/email",
  SETTINGS_SMS: "/settings/sms",
  SETTINGS_LOG: "/settings/log",
  CONTACT_US: "/contact-us",
  FAQ: "/faq",
  BLOG: "/blog",
  POST: "/blog/:postSlug/",
  LOGIN: "/login",
  FORGOT: "/forgot",
  SIGNUP: "/signup",
  REGISTRATION: "/registration",
  COMPLETE_REGISTRATION: "/complete-registration",
  ENTRY: "*",
  HOME: "/",
  POWO_CONTRACTOR: "/contractor/po-wo",
  POWO_EMPLOYER: "/employer/po-wo",
  PASSWORD_RESET_CONFIRM: "/password/reset/confirm/",
  TENDER: "tender"
};

export const PAGE_SIZE = 6;

export const FIRST_PAGE = 1;

export const ITEMS_PER_PAGE = 10;

export const API_METHODS = {
  post: "POST",
  get: "GET",
  patch: "PATCH",
  delete: "DELETE",
  put: "PUT"
};
