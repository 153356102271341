import { Table } from "react-bootstrap";

import TenderItem from "./TenderItem";

export default function TenderItemsTable({
  itemList,
  onUpdateItemHandler,
  onDeleteItemHandler
}) {
  return (
    <Table striped responsive>
      <thead>
        <tr>
          <th className="text-center">
            <div>S No</div>
          </th>
          <th>
            <div>Item Name</div>
          </th>
          <th>
            <div>Description</div>
          </th>
          <th>
            <div>Quantity</div>
          </th>
          <th>
            <div>Unit</div>
          </th>
          <th>
            <div>&nbsp;</div>
          </th>
        </tr>
      </thead>
      <tbody>
        {itemList.map((item, index) => (
          <TenderItem
            key={item.id}
            index={index}
            item={item}
            onUpdate={onUpdateItemHandler}
            onDelete={onDeleteItemHandler}
            itemsCount={itemList.length}
          />
        ))}
      </tbody>
    </Table>
  );
}
