import { Accordion } from "react-bootstrap";

export default function FAQ() {
  return (
    <>
      <h1 className="h1 fw-bolder mb-14">FAQ and Help</h1>

      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header>How can I create a tender?</Accordion.Header>
          <Accordion.Body>
            <ul>
              <li>
                Click on <strong>Create New Tender</strong>.
              </li>
              <li>
                Enter the various details of your tender along with the items
                you wish to include the tender. Make sure you select{" "}
                <strong>Tender Visibility</strong> and{" "}
                <strong>Pricing Visibility</strong> carefully per your
                requirements.
              </li>
              <li>
                Add any tender related documents as attachments if needed.
              </li>
              <li>
                Scroll down and hit <strong>Next</strong> after which
                you&apos;ll be taken to the confirmation page. If everything
                looks fine then hit <strong>Next</strong>, otherwise hit{" "}
                <strong>Back</strong> to make edits. Upon hitting{" "}
                <strong>Next</strong> your tender will be live.
              </li>
              <li>
                You may invite any contractors of your choice to the tender.
              </li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="1">
          <Accordion.Header>
            What is&nbsp;<strong>Tender Visibility</strong>?
          </Accordion.Header>
          <Accordion.Body>
            <p>
              There are 2 types of views available in{" "}
              <strong>Tender Visibility</strong>:
            </p>
            <ul>
              <li>
                <strong>Public:</strong> A Public tender is available to any
                contractor who has signed up on ZoomTender.
              </li>
              <li>
                <strong>Private:</strong> A Private tender is only available to
                the contractors that have been invited by the employer.
              </li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="2">
          <Accordion.Header>
            What is&nbsp;<strong>Pricing Visibility</strong>?
          </Accordion.Header>
          <Accordion.Body>
            <p>
              There are 3 types of view available in{" "}
              <strong>Pricing Visibility</strong>:
            </p>
            <ul>
              <li>
                <strong>Open View:</strong> In Open View, both the employer anf
                the contractor can see all bids at all times (while the tender
                is going on, and after it has ended)
              </li>
              <li>
                <strong>Employer View:</strong> In Employer View, only the
                employer can see all bids at all times (while the tender iss
                going on, and after it has ended)
              </li>
              <li>
                <strong>Closed View:</strong> In Closed View, neither the
                employer nor the contractor can see any bids till the deadline
                has passed.
              </li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="3">
          <Accordion.Header>
            How can I compare the different bids?
          </Accordion.Header>
          <Accordion.Body>
            You can compare the different bids by going to the tender&apos;s
            page under <strong>My Tenders</strong> and scrolling down where you
            will see price comparisons.
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="4">
          <Accordion.Header>
            How can contractor bid on my tender?
          </Accordion.Header>
          <Accordion.Body>
            A contractor can bid on a tender by going to the tender&apos;s page
            and entering in their prices.
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="5">
          <Accordion.Header>
            Can any contractor bid on my tender?
          </Accordion.Header>
          <Accordion.Body>
            For <strong>Public</strong> tenders, any contractor on ZoomTender
            can bid on your tender. For <strong>Private</strong> tenders, only
            the contractors that have been invited to a particular tender can
            bid on a tender.
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="6">
          <Accordion.Header>
            How can I inform the contractor who has won the tender?
          </Accordion.Header>
          <Accordion.Body>
            You can communicate with any contractor through the Mail feature.
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="7">
          <Accordion.Header>
            How to edit any details in my tender?
          </Accordion.Header>
          <Accordion.Body>
            You can edit a tender simply by clicking by going to a particular
            tender&apos;s page and clicking <strong>Edit</strong>.
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="8">
          <Accordion.Header>
            What are the different&nbsp;<strong>bidding statuses</strong>?
          </Accordion.Header>
          <Accordion.Body>
            <p>
              There are 3 types of <strong>bidding statuses</strong>:
            </p>
            <ul>
              <li>
                <strong>Active:</strong> a tender is in Active status before the
                deadline has passed.
              </li>
              <li>
                <strong>Bidding Complete:</strong> a tender is in Bidding
                Complete status right after the deadline has passed, before the
                prices have been finalized.
              </li>
              <li>
                <strong>Finalized:</strong> a tender is in finalized status once
                the finalized prices have been entered.
              </li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
}
