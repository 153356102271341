import { createSlice } from "@reduxjs/toolkit";
import { tenderApi } from "../services/tenderApi";

const initialState = {
  tendersList: [],
  currentPage: 1,
  perPage: 10,
  totalCount: 0
};

const tenderSlice = createSlice({
  name: "tender",
  initialState,
  reducers: {
    setAllTenders: (state, action) => {
      state.tendersList = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    }
    // setToken: (state, action) => {
    //  state.token.access = action.payload;
    //  /* REFRESH TOKEN ON CLIENT default for an hour */
    //   // state.expiresAt = Date.now() + minutesToMs(60)
    // }
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      tenderApi.endpoints.getTenders.matchFulfilled,
      (state, action) => {
        state.items = action.payload.data;
      }
    );
  }
});

const { actions, reducer } = tenderSlice;
export const { setAllTenders, setCurrentPage } = actions;
export default reducer;
