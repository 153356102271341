import { useEffect, useMemo, useState, useRef } from "react";
import { Table, Form, Button, Row, Col } from "react-bootstrap";

import { TableVendor } from "../components";
import { useComparePricesMutation } from "../redux/services/tenderApi";
import { toFloat } from "../utils/helpersFunctions";
import { CLIENT_TYPE } from "../utils/constants";
import LowestPossibleTotal from "./LowestPossibleTotal";

export default function TableBidsComparison({ items, bids, role, tenderId }) {
  const [comparePrices] = useComparePricesMutation();
  const [tehItems, setTehItems] = useState([]);
  const [tehBids, setTehBids] = useState([]);

  // const [widthList, setWidthList] = useState([]);

  useEffect(() => {
    items && setTehItems(items.map((i) => ({ ...i, selected: true })));
    bids &&
      setTehBids(
        bids.map((b, index) => ({
          ...b,
          owner: {
            id: b.owner.id,
            full_name:
              b.is_bid_owner || role === CLIENT_TYPE.employer
                ? b.owner.full_name
                : `Contractor ${index + 1}`
          },
          selected: true
        }))
      );
  }, [bids, items]);

  const selectedItems = useMemo(
    () => tehItems.filter((i) => i.selected),
    [tehItems]
  );

  const selectedBids = useMemo(
    () => tehBids.filter((b) => b.selected),
    [tehBids]
  );

  const minBidsList = useMemo(() => {
    const allItemBids = selectedBids.map((b) =>
      b.items_bids.map((ib) => ({ bid: ib, owner: b.owner }))
    );
    const mergedAllItemBids = [].concat.apply([], allItemBids);

    return (
      selectedItems.map((i) => {
        const bidsByItemList = mergedAllItemBids.filter(
          (ib) => ib.bid.item === i.id
        );
        const bidsWithPrice = bidsByItemList.filter((i) => i?.bid?.price > 0);

        const lowestBid =
          bidsWithPrice.length > 1
            ? bidsWithPrice.reduce(function (prev, curr) {
                return prev.bid.price < curr.bid.price ? prev : curr;
              })
            : bidsWithPrice.length === 1
            ? bidsWithPrice[0]
            : null;

        if (!lowestBid) {
          return {
            item: i.id,
            owner: "",
            price: ""
          };
        }

        return {
          item: i.id,
          owner: lowestBid.owner,
          price: lowestBid.bid.price
        };
      }) || []
    );
  }, [selectedItems, selectedBids]);

  const sumByBidList = useMemo(() => {
    const itemBids = selectedBids.map((b) => ({
      id: b.id,
      bids: selectedItems.map((i) =>
        b.items_bids.find((ib) => ib.item === i.id)
      )
    }));

    return itemBids.map((ib) => ({
      id: ib.id,
      total: ib.bids
        .filter((i) => i?.total_price > 0)
        .map((i) => i?.total_price)
        .reduce((accumulator, a) => accumulator + a, 0)
    }));
  }, [selectedItems, selectedBids]);

  const bidsUpdate = (bid) => {
    const index = tehBids.findIndex((b) => b.id === bid.id);

    const newTechBids = [...tehBids];
    newTechBids[index].selected = !newTechBids[index].selected;
    setTehBids(newTechBids);
  };

  const itemsUpdate = (item) => {
    const index = tehItems.findIndex((i) => i.id === item.id);

    const newTechItems = [...tehItems];
    newTechItems[index].selected = !newTechItems[index].selected;
    setTehItems(newTechItems);
  };

  // const handleRect = useCallback((node) => {
  //   setWidthList([node?.getBoundingClientRect().width]);
  // }, []);

  const widthListRef = useRef([]);
  const [p, s] = useState(0);

  useEffect(() => {
   console.log(p);
    s((v) => v + 1);
    window.addEventListener("resize", () => s((v) => v + 1));

    return () =>
      window.removeEventListener(
        "resize",
        s((v) => v + 1)
      );
  }, []);

  return (
    <>
      <div className="d-flex mb-2 align-items-center justify-content-between">
        <div className="fs-6 lh-base form-label mb-0">Bids Comparison</div>

        {role === CLIENT_TYPE.employer && (
          <Button
            variant="link"
            onClick={async () => {
              const response = await comparePrices(tenderId);
              const downloadLink = document.createElement("a");
              const universalBOM = "\uFEFF";
              downloadLink.href = `data:text/csv;charset=utf-8,${encodeURIComponent(
                universalBOM + response.error.data
              )}`;
              downloadLink.download = "comparative-price.csv";
              document.body.appendChild(downloadLink);
              downloadLink.click();
              document.body.removeChild(downloadLink);
            }}
          >
            Export to Excel
          </Button>
        )}
      </div>
      {/* table-nofixed */}
      <div className="table-rounded mb-36 shadow bg-white rounded-1 ">
        <Table striped responsive>
          <thead>
            <tr>
              <th className="text-center col-lg-2" style={{ width: "150px" }}>
                <div className="p-0">Item</div>
              </th>
              <th className="col-lg-2" style={{ width: "150px" }}>
                <div className="p-0">Quantity</div>
              </th>
              {tehBids.map((bid, i) => (
                <th
                  key={`head${bid.id}`}
                  ref={(el) => (widthListRef.current[i] = el)}>
                  <div className="p-0">
                    <Form.Check
                      inline
                      checked={bid.selected}
                      type="checkbox"
                      label={bid.owner.full_name}
                      onChange={() => bidsUpdate(bid)}
                      className="d-flex"
                    />
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tehItems?.map((item) => (
              <tr key={`row${item.id}`}>
                <td className="text-center col-lg-2" style={{ width: "150px" }}>
                  <div className="text-start">
                    <Form.Check
                      inline
                      checked={item.selected}
                      type="checkbox"
                      label={item.name}
                      onChange={() => itemsUpdate(item)}
                    />
                  </div>
                </td>
                <td className="col-lg-2" style={{ width: "150px" }}>
                  <div>{item.quantity}</div>
                </td>
                {tehBids
                  .map((bid) => ({
                    selected: bid.selected,
                    bid: bid.items_bids.find(
                      (itemsBid) => itemsBid.item === item.id
                    )
                  }))
                  .map((obj, i) => (
                    <td
                      key={`td${obj?.bid?.id || i}`}
                      className={
                        item.selected &&
                        obj.selected &&
                        minBidsList.find((minBid) => minBid.item === item.id)
                          ?.price === obj.bid?.price
                          ? "check"
                          : ""
                      }
                      style={{ width: widthListRef.current[i]?.offsetWidth + "px" }}
                    >
                      <div>{obj?.bid?.price || "No bid"}</div>
                    </td>
                  ))}
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

      <div className="pb-10 border-bottom mb-30">
        <Row className="align-items-center g-0 flex-nowrap table-responsive">
          <Col className="col-auto text-lg-end" style={{ width: "300px" }}>
            <div className="fs-6 lh-base form-label pe-lg-4">Total</div>
          </Col>

          {tehBids.map((bid, i) => (
            <Col
              className="col col-auto"
              key={bid.id}
              style={{ width: widthListRef.current[i]?.offsetWidth + "px" }}
            >
              {bid.selected ? (
                <ul className="list-unstyled mb-0 small">
                  {selectedItems.map((i, index, { length }) => {
                    const price = bid.items_bids.find(
                      (ib) => ib.item === i.id
                    )?.price;

                    return (
                      <>
                        {!!price && price > 0 && (
                          <li key={`li${i.id}`}>
                            <small>{`${i.quantity}x${price}`}</small>
                          </li>
                        )}
                        {index + 1 === length && (
                          <li>
                            <small>{`= ${toFloat(
                              sumByBidList.find((b) => b.id === bid.id).total
                            )}`}</small>
                          </li>
                        )}
                      </>
                    );
                  })}
                </ul>
              ) : (
                <></>
              )}
            </Col>
          ))}
        </Row>
      </div>

      {role === CLIENT_TYPE.employer && (
        <>
          <LowestPossibleTotal
            selectedBids={selectedBids}
            selectedItems={selectedItems}
            minBidsList={minBidsList}
            tehItems={tehItems}
          />

          <div className="mb-36 mb-lg-5">
            <div className="fs-6 lh-base form-label mb-2">
              Vendor with lowest prices
            </div>

            <Row>
              <Col lg={6}>
                <TableVendor
                  vendorList={bids.map((b) => b.owner)}
                  bidsList={minBidsList}
                />
              </Col>
            </Row>
          </div>
        </>
      )}
    </>
  );
}
