import { Formik } from "formik";
import { useState, useMemo, useEffect } from "react";
import { Form, Row, Col, ProgressBar, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

import img3 from "../../../assets/images/img-3.svg";
import {
  AddAttachments,
  Calendar,
  Loader,
  ModalSaveForLater
} from "../../../components";
import { PRICING_VISIBILITY, TENDER_STATUSES } from "../../../utils/constants";
import TenderTitle from "./components/Title";
import TenderDescription from "./components/Description";
import TenderShowPricing from "./components/ShowPricing";
import TenderPricingVisibility from "./components/PricingVisibility";
import TenderTenderVisibility from "./components/TenderVisibility";
import TenderItemsTable from "./components/ItemsTable";

export default function TenderNew({
  form,
  itemList,
  onSubmitHandler,
  onFormUpdateHandler,
  onAddItemHandler,
  onUpdateItemHandler,
  stepPlus,
  onDeleteItemHandler,
  isEdit,
  onAddAttachment,
  attachmentsList,
  onDeleteAttachment,
  isLoading,
  isDraftLoading,
  createDraftTender,
  serverErrors
}) {
  const navigate = useNavigate();
  const [showOptionListTender, setShowOptionListTender] = useState(false);
  const [showOptionListPricing, setShowOptionListPricing] = useState(false);
  const [deadlineError, setShowError] = useState(false);

  const isNextDisabled = useMemo(
    () =>
      !itemList.every(
        (i) =>
          i.name &&
          i.quantity &&
          i.quantity > 0 &&
          i.quantity <= 2147483647 &&
          Number(i.quantity)
      ),
    [itemList]
  );

  const schema = yup.object().shape({
    title: yup.string().required("Title is a required field"),
    pricing_visibility: yup
      .string()
      .required("Pricing Visibility is a required field"),
    visibility: yup.string().required("Tender Visibility is a required field"),
    deadline: yup.date().required("Deadline is a required field")
  });

  const handleOptionClickTender = (e) => {
    onFormUpdateHandler({
      visibility: e.target.getAttribute("data-value")
    });
    setShowOptionListTender(false);
  };

  const handleListDisplayTender = () => {
    setShowOptionListTender(!showOptionListTender);
  };

  const handleClickOutsideTender = (e) => {
    if (
      !e.target.classList.contains("select-items>div") &&
      !e.target.classList.contains("select-selected")
    ) {
      setShowOptionListTender(false);
    }
  };

  const handleOptionClickPricing = (e) => {
    onFormUpdateHandler({
      pricing_visibility: e.target.getAttribute("data-value")
    });
    setShowOptionListPricing(false);
  };

  const handleListDisplayPricing = () => {
    setShowOptionListPricing(!showOptionListPricing);
  };

  const handleClickOutsidePricing = (e) => {
    if (
      !e.target.classList.contains("select-items>div") &&
      !e.target.classList.contains("select-selected")
    ) {
      setShowOptionListPricing(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutsideTender, true);
    return () => {
      document.removeEventListener("click", handleClickOutsideTender, true);
    };
  }, []);

  useEffect(() => {
    document.addEventListener("click", handleClickOutsidePricing, true);
    return () => {
      document.removeEventListener("click", handleClickOutsidePricing, true);
    };
  }, []);

  const [showSave, setShowSave] = useState(false);

  const handleCloseSave = () => setShowSave(false);
  const handleShowSave = () => setShowSave(true);

  const saveDraft = async (e) => {
    await createDraftTender(e, handleShowSave);
  };

  return (
    <>
      <ModalSaveForLater show={showSave} onHide={handleCloseSave} />

      <h1 className="h1 fw-bolder mb-14">
        {isEdit ? "Edit Tender" : "Create New Tender"}
      </h1>
      <Formik
        validateOnChange
        enableReinitialize
        initialValues={{
          title: form.title,
          visibility: form.visibility,
          pricing_visibility: form.pricing_visibility,
          pricing_type: form.pricing_type,
          description: form.description,
          deadline: form.deadline,
          allow_taxes: form.allow_taxes
        }}
        validationSchema={schema}
        onSubmit={onSubmitHandler}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          isValid,
          handleSubmit
        }) => (
          <Form className="form" onSubmit={handleSubmit}>
            <Row className="g-0">
              <Col lg={7} xl={6}>
                {isEdit ? (
                  <></>
                ) : (
                  <div className="mb-4 pb-2">
                    <small className="d-block mb-10">Step 1/2</small>
                    <ProgressBar now={50} />
                  </div>
                )}

                <div className="mb-2">
                  <TenderTitle
                    touched={touched}
                    errors={errors || serverErrors}
                    values={values}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    onFormUpdateHandler={onFormUpdateHandler}
                  />
                </div>

                <div className="mb-2">
                  <TenderTenderVisibility
                    errors={errors}
                    values={values}
                    showOptionListTender={showOptionListTender}
                    handleListDisplayTender={handleListDisplayTender}
                    handleOptionClickTender={handleOptionClickTender}
                  />
                </div>

                <div className="mb-2">
                  <TenderPricingVisibility
                    errors={errors}
                    values={values}
                    showOptionListPricing={showOptionListPricing}
                    handleListDisplayPricing={handleListDisplayPricing}
                    handleOptionClickPricing={handleOptionClickPricing}
                  />
                </div>

                {values.pricing_visibility === PRICING_VISIBILITY.openView && (
                  <div className="mb-2">
                    <TenderShowPricing
                      form={form}
                      onFormUpdateHandler={onFormUpdateHandler}
                    />
                  </div>
                )}

                <div className="mb-2">
                  <Row className="justify-content-center align-items-center g-2 ">
                    <Col sm={4} lg={5} className="text-start mb-2 mb-sm-0">
                      <Form.Label className="fs-6 lh-base col-form-label py-0">
                        Deadline:
                      </Form.Label>
                    </Col>
                    <Col sm={8} lg={7}>
                      <div className="position-relative" tabIndex="4">
                        <Calendar
                          onUpdate={onFormUpdateHandler}
                          date={new Date(values.deadline).toISOString()}
                        />

                        {deadlineError && !values.deadline && (
                          <Form.Control.Feedback type="invalid">
                            Deadline is a required field
                          </Form.Control.Feedback>
                        )}

                        {serverErrors && (
                          <Form.Control.Feedback type="invalid">
                            {serverErrors.deadline}
                          </Form.Control.Feedback>
                        )}

                        {/* {errors.deadline && touched.deadline && (
                        <IconError />
                      )}

                      {!errors.deadline && values.deadline && (
                        <IconSuccess />
                      )} */}
                      </div>
                    </Col>
                  </Row>
                </div>

                <div className="mb-2">
                  <TenderDescription
                    touched={touched}
                    errors={errors}
                    values={values}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    onFormUpdateHandler={onFormUpdateHandler}
                  />
                </div>
              </Col>

              <Col lg={5} xl={6}>
                <img
                  src={img3}
                  alt=""
                  className="img-fluid d-block mx-auto mt-lg-3 ms-lg-3"
                />
              </Col>
            </Row>

            <Row className="mb-5">
              <Col xs="12">
                <div key="taxes-checkbox">
                  <Form.Check
                    inline
                    type="checkbox"
                    id="taxes-checkbox"
                    label="Allow Adding Tax"
                    checked={form.allow_taxes}
                    onChange={() => {
                      onFormUpdateHandler({
                        allow_taxes: !form.allow_taxes
                      });
                    }}
                  />
                </div>
              </Col>
            </Row>

            <Row className="mb-10">
              <Col lg={12} className="mb-2">
                <div className="fs-6 lh-base form-label">Item(s)</div>
              </Col>
              <Col lg={12}>
                <div className="table-rounded mb-3 shadow bg-white rounded-1 v-top">
                  <TenderItemsTable
                    itemList={itemList}
                    onUpdateItemHandler={onUpdateItemHandler}
                    onDeleteItemHandler={onDeleteItemHandler}
                  />
                </div>

                <div className="text-center">
                  <Button
                    tabIndex="10"
                    variant="light"
                    className="px-36 px-lg-5"
                    onClick={onAddItemHandler}
                  >
                    + Add More
                  </Button>
                </div>
              </Col>
            </Row>

            <Row>
              <AddAttachments
                attachmentsList={attachmentsList}
                onAddAttachment={onAddAttachment}
                isEditMode={isEdit}
                onDeleteAttachment={onDeleteAttachment}
              />
            </Row>

            <div className="d-flex align-items-center justify-content-between pt-30">
              <Button
                variant="light"
                className="bw-2 px-4"
                onClick={saveDraft}
                hidden={form.status !== TENDER_STATUSES.draft}
                disabled={!values.title || isLoading}
              >
                <span>Save for Later {isDraftLoading && <Loader />}</span>
              </Button>

              <Button
                variant="light"
                className="px-36"
                onClick={() => navigate(-1)}
                hidden={!isEdit}
              >
                Back
              </Button>

              <Button
                type="submit"
                tabIndex="12"
                variant="primary"
                className="px-36 ms-3"
                onClick={(e) => {
                  if (!values.deadline) {
                    return setShowError(true);
                  }
                  isEdit ? onSubmitHandler(e) : stepPlus();
                }}
                disabled={
                  !isValid || !values.title || isNextDisabled || isDraftLoading
                }
              >
                Next {isLoading && <Loader />}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}
