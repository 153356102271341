import { Modal, Button } from "react-bootstrap";

export default function WithdrawModal({ show, onHide, entity, onConfirm }) {
  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton className="pb-0">
          <Modal.Title>&nbsp;</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <ul className="list-unstyled mb-30 lh-lg">
            <li className="mb-4 d-flex align-items-left flex-column">
              <span className="fs-6 lh-base form-label me-2">
                Withdraw confirmation
              </span>
              <small>{`Are you sure that you want to withdraw ${entity}?`}</small>
            </li>
          </ul>
        </Modal.Body>
        <Modal.Footer className="justify-content-start pb-36 pt-0">
          <Button
            variant="primary"
            onClick={() => {
              onConfirm();
              onHide();
            }}
            className="px-30 me-3"
          >
            Confirm
          </Button>
          <Button variant="danger" onClick={onHide} className="px-30 me-3">
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
