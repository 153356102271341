import React, { useState, useCallback } from "react";
// import { Link } from "react-router-dom";
import { Form, Button, Row, Col } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";

import { PASSWORD_REGEXP } from "../../utils/constants";
import { IconError, IconEye, IconSuccess } from "../../utils/svgHelper";
import { useChangePasswordMutation } from "../../redux/services/authApi";
import { Loader } from "../../components";

export default function Settings() {
  const [changePassword, { isLoading }] = useChangePasswordMutation();
  const [changeMessage, setChangeMessage] = useState("");
  const [oldPasswordType, setOldPasswordType] = useState("password");
  const [newPasswordType, setNewPasswordType] = useState("password");
  const [confirmNewPasswordType, setConfirmNewPasswordType] =
    useState("password");

  const reverseOldPasswordType = () =>
    oldPasswordType === "password"
      ? setOldPasswordType("text")
      : setOldPasswordType("password");
  const reverseNewPasswordType = () =>
    newPasswordType === "password"
      ? setNewPasswordType("text")
      : setNewPasswordType("password");
  const reverseConfirmNewPasswordType = () =>
    confirmNewPasswordType === "password"
      ? setConfirmNewPasswordType("text")
      : setConfirmNewPasswordType("password");

  const schema = yup.object().shape({
    oldPassword: yup
      .string()
      .required("Old password is a required field")
      .matches(PASSWORD_REGEXP, "Must contain at least 6 characters"),
    newPassword: yup
      .string()
      .required("New password is a required field")
      .matches(PASSWORD_REGEXP, "Must contain at least 6 characters")
      .test(
        "passwords-match",
        "Old password and New password should be different",
        function (value) {
          return this.parent.oldPassword !== value;
        }
      ),
    confirmNewPassword: yup
      .string()
      .required("Confirm new password is a required field")
      .matches(PASSWORD_REGEXP, "Must contain at least 6 characters")
      .oneOf(
        [yup.ref("newPassword"), null],
        "New password and Confirm new password must match"
      )
  });

  const handleSubmit = useCallback(async (values, { setStatus, setErrors }) => {
    try {
      const { data, error } = await changePassword({
        old_password: values.oldPassword,
        new_password1: values.newPassword,
        new_password2: values.confirmNewPassword
      });
      if (error && error.data.errors.length !== 0) {
        return setErrors({
          oldPassword: error.data.errors[0].old_password,
          newPassword: error.data.errors[0].new_password1,
          confirmNewPassword: error.data.errors[0].new_password2
        });
      }
      if (data) {
        setChangeMessage(data.data.detail);
      }
    } catch (err) {
      console.error(err);
      setStatus(err.message);
    }
  }, []);

  return (
    <>
      <h1 className="h1 fw-bolder mb-0">Password</h1>
      <h6 className="fsz-18 mb-4">Change Password</h6>

      <Formik
        validateOnChange
        initialValues={{
          oldPassword: "",
          newPassword: "",
          confirmNewPassword: ""
        }}
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          isValid,
          handleSubmit,
          dirty
        }) => (
          <Form className="form" onSubmit={handleSubmit}>
            <Row className="g-0 mb-3">
              <Col lg={6} xl={4}>
                <div className="mb-10">
                  <Form.Group className="position-relative mb-3">
                    <div className="position-relative">
                      {/* <Form.Label className="fs-6 lh-base col-form-label py-0">Old password</Form.Label> */}
                      <div className="position-relative">
                        <Button
                          variant=""
                          className={`position-absolute top-0 end-0 h-100 ${
                            oldPasswordType === "text" ? "text-secondary" : ""
                          }`}
                          onClick={reverseOldPasswordType}
                        >
                          {oldPasswordType === "password" && <IconEye />}
                          {oldPasswordType === "text" && <IconEye />}
                        </Button>
                        <Form.Control
                          className={`${
                            touched.oldPassword ? "is-touch " : ""
                          } ${
                            errors.oldPassword && touched.oldPassword
                              ? " is-invalid"
                              : ""
                          } ${
                            !errors.oldPassword && touched.oldPassword
                              ? " is-valid"
                              : ""
                          }`}
                          type={oldPasswordType}
                          name="oldPassword"
                          value={values.oldPassword}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          placeholder="Current password"
                        />

                        {errors.oldPassword && touched.oldPassword && (
                          <IconError />
                        )}

                        {!errors.oldPassword && touched.oldPassword && (
                          <IconSuccess />
                        )}
                      </div>

                      {errors.oldPassword && touched.oldPassword && (
                        <Form.Control.Feedback type="invalid">
                          {errors.oldPassword}
                        </Form.Control.Feedback>
                      )}
                    </div>

                    {/* <Link
                      to={APP_ROUTE.FORGOT}
                      className="btn btn-link text-decoration-none"
                    >
                      Forgot your password?
                    </Link> */}
                  </Form.Group>

                  <Form.Group className="position-relative mb-3">
                    {/* <Form.Label className="fs-6 lh-base col-form-label py-0">New password</Form.Label> */}
                    <div className="position-relative">
                      <Button
                        variant=""
                        className={`position-absolute top-0 end-0 h-100 ${
                          newPasswordType === "text" ? "text-secondary" : ""
                        }`}
                        onClick={reverseNewPasswordType}
                      >
                        {newPasswordType === "password" && <IconEye />}
                        {newPasswordType === "text" && <IconEye />}
                      </Button>
                      <Form.Control
                        className={`${touched.newPassword ? "is-touch " : ""} ${
                          errors.newPassword && touched.newPassword
                            ? " is-invalid"
                            : ""
                        } ${
                          !errors.newPassword && touched.newPassword
                            ? " is-valid"
                            : ""
                        }`}
                        type={newPasswordType}
                        name="newPassword"
                        value={values.newPassword}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="New password"
                      />

                      {errors.newPassword && touched.newPassword && (
                        <IconError />
                      )}

                      {!errors.newPassword && touched.newPassword && (
                        <IconSuccess />
                      )}
                    </div>

                    {errors.newPassword && touched.newPassword && (
                      <Form.Control.Feedback type="invalid">
                        {errors.newPassword}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>

                  <Form.Group className="position-relative mb-3">
                    {/* <Form.Label className="fs-6 lh-base col-form-label py-0">Confirm new password</Form.Label> */}
                    <div className="position-relative">
                      <Button
                        variant=""
                        className={`position-absolute top-0 end-0 h-100 ${
                          confirmNewPasswordType === "text"
                            ? "text-secondary"
                            : ""
                        }`}
                        onClick={reverseConfirmNewPasswordType}
                      >
                        {confirmNewPasswordType === "password" && <IconEye />}
                        {confirmNewPasswordType === "text" && <IconEye />}
                      </Button>
                      <Form.Control
                        className={`${
                          touched.confirmNewPassword ? "is-touch " : ""
                        } ${
                          errors.confirmNewPassword &&
                          touched.confirmNewPassword
                            ? " is-invalid"
                            : ""
                        } ${
                          !errors.confirmNewPassword &&
                          touched.confirmNewPassword
                            ? " is-valid"
                            : ""
                        }`}
                        type={confirmNewPasswordType}
                        name="confirmNewPassword"
                        value={values.confirmNewPassword}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="Confirm new password"
                      />

                      {errors.confirmNewPassword &&
                        touched.confirmNewPassword && <IconError />}

                      {!errors.confirmNewPassword &&
                        touched.confirmNewPassword && (
                          <IconSuccess />
                        )}
                    </div>

                    {errors.confirmNewPassword &&
                      touched.confirmNewPassword && (
                        <Form.Control.Feedback type="invalid">
                          {errors.confirmNewPassword}
                        </Form.Control.Feedback>
                      )}
                  </Form.Group>

                  {/* <Link to={APP_ROUTE.FORGOT} className="btn btn-link text-decoration-none">
                    Forgot your password?
                  </Link> */}
                </div>
              </Col>
            </Row>

            {changeMessage && (
              <Row>
                <Col sm={12}>
                  <span className="text-success">{changeMessage}</span>
                </Col>
              </Row>
            )}

            <Row className="g-2">
              <Col xs="auto">
                <Button
                  type="submit"
                  variant="primary"
                  className="px-4"
                  disabled={isLoading}
                >
                  Change Password {isLoading && <Loader />}
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </>
  );
}
