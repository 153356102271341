import { Table, Form, Row, Col, ProgressBar, Button } from "react-bootstrap";

import { Loader } from "../../../components";
import img3 from "../../../assets/images/img-3.svg";
import {
  PRICING_VISIBILITY_DESCRIPTION,
  VISIBILITY_DESCRIPTION,
  PRICING_VISIBILITY,
  CLIENT_TYPE
} from "../../../utils/constants";
import { transformationTime } from "../../../utils/helpersFunctions";

export default function TenderNew({
  form,
  itemList,
  stepMinus,
  onSubmitHandler,
  isLoadingPostTenders,
  attachmentsList,
  user
}) {
  return (
    <>
      <h1 className="h1 fw-bolder mb-14">Create New Tender</h1>
      <Form className="form">
        <Row className="g-0">
          <Col lg={7} xl={6}>
            <div className="mb-4 pb-2">
              <small className="d-block mb-10">Step 2/2</small>

              <ProgressBar now={100} />
            </div>

            <div className="mb-2">
              <Row className="justify-content-center align-items-center g-2 me-xl-n5">
                <Col
                  sm={4}
                  lg={5}
                  className="text-start mb-2 mb-sm-0 text-sm-end"
                >
                  <span className="fs-6 lh-base col-form-label py-0 text-nowrap">
                    Title:
                  </span>
                </Col>
                <Col sm={8} lg={7}>
                  <small className="form-control readonly">{form?.title}</small>
                </Col>
              </Row>
            </div>

            <div className="mb-2">
              <Row className="justify-content-center align-items-center g-2 me-xl-n5">
                <Col
                  sm={4}
                  lg={5}
                  className="text-start mb-2 mb-sm-0 text-sm-end"
                >
                  <span className="fs-6 lh-base col-form-label py-0 text-nowrap">
                    Tender Visibility:
                  </span>
                </Col>
                <Col sm={8} lg={7}>
                  <small className="form-control readonly">
                    {VISIBILITY_DESCRIPTION[form?.visibility]}
                  </small>
                </Col>
              </Row>
            </div>

            <div className="mb-2">
              <Row className="justify-content-center align-items-center g-2 me-xl-n5">
                <Col
                  sm={4}
                  lg={5}
                  className="text-start mb-2 mb-sm-0 text-sm-end"
                >
                  <span className="fs-6 lh-base col-form-label py-0 text-nowrap">
                    Pricing Visibility:
                  </span>
                </Col>
                <Col sm={8} lg={7}>
                  <small className="form-control readonly">
                    {PRICING_VISIBILITY_DESCRIPTION[form?.pricing_visibility]}
                  </small>
                </Col>
              </Row>
            </div>

            {user.role === CLIENT_TYPE.employer &&
              form?.pricing_visibility === PRICING_VISIBILITY.openView && (
                <div className="mb-2">
                  <Row className="justify-content-center align-items-center g-2 me-xl-n5">
                    <Col
                      sm={4}
                      lg={5}
                      className="text-start mb-2 mb-sm-0 text-sm-end"
                    >
                      <span className="fs-6 lh-base col-form-label py-0 text-nowrap">
                        Show Pricing:
                      </span>
                    </Col>
                    <Col sm={8} lg={7}>
                      <small className="form-control readonly">
                        {form?.pricing_type}
                      </small>
                    </Col>
                  </Row>
                </div>
              )}

            <div className="mb-2">
              <Row className="justify-content-center align-items-center g-2 me-xl-n5">
                <Col
                  sm={4}
                  lg={5}
                  className="text-start mb-2 mb-sm-0 text-sm-end"
                >
                  <span className="fs-6 lh-base col-form-label py-0 text-nowrap">
                    Deadline:
                  </span>
                </Col>
                <Col sm={8} lg={7}>
                  <small className="form-control readonly">
                    {transformationTime(form?.deadline)}
                  </small>
                </Col>
              </Row>
            </div>

            <div className="mb-2">
              <Row className="justify-content-center align-items-start g-2 me-xl-n5">
                <Col
                  sm={4}
                  lg={5}
                  className="text-start mb-2 mb-sm-0 text-sm-end"
                >
                  <span className="fs-6 lh-base col-form-label py-0 text-nowrap">
                    Description:
                  </span>
                </Col>
                <Col sm={8} lg={7}>
                  <small className="form-control readonly py-0">
                    {form?.description}
                  </small>
                </Col>
              </Row>
            </div>
          </Col>

          <Col lg={5} xl={6}>
            <img
              src={img3}
              alt=""
              className="img-fluid d-block mx-auto mt-lg-3 ms-lg-3"
            />
          </Col>
        </Row>
        <Row className="mb-10">
          <Col lg={12} className="mb-2">
            <div className="fs-6 lh-base form-label">Item(s)</div>
          </Col>
          <Col lg={12}>
            <div className="table-rounded mb-3 shadow bg-white rounded-1 v-top">
              <Table striped responsive>
                <thead>
                  <tr>
                    <th className="text-center">
                      <div>S No</div>
                    </th>
                    <th>
                      <div>Item Name</div>
                    </th>
                    <th>
                      <div>Description</div>
                    </th>
                    <th>
                      <div>Quantity</div>
                    </th>
                    <th>
                      <div>Unit</div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {itemList.map((item, index) => (
                    <tr key={Date.now() + index}>
                      <td className="text-center">
                        <div>{index + 1}</div>
                      </td>
                      <td>
                        <div>
                          <div className="text-write">{item.name}</div>
                        </div>
                      </td>
                      <td>
                        <div>
                          <div className="text-write">{item.description}</div>
                        </div>
                      </td>
                      <td>
                        <div>
                          <div className="text-write">{item.quantity}</div>
                        </div>
                      </td>
                      <td>
                        <div>
                          <div className="text-write">{item.unit}</div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </Col>
        </Row>

        <Row>
          <div className="col-lg-12  mb-2">
            <div className="fs-6 lh-base form-label">Attachments</div>
          </div>
          <div className="col-lg-12">
            <div className="position-relative">
              <div className="file d-flex align-items-center justify-content-start">
                <ul>
                  {attachmentsList.map((item, index) => (
                    <li key={index + 1}>{item.name}</li>
                  ))}
                </ul>
                <span className="form-label" id="file">
                  {attachmentsList.length === 0 && "None"}
                </span>
              </div>
            </div>
          </div>
        </Row>

        <div className="text-lg-end pt-30">
          <Button variant="light" className="px-36" onClick={stepMinus}>
            Back
          </Button>
          <Button
            variant="primary"
            className="px-36 ms-3"
            onClick={onSubmitHandler}
          >
            Next {isLoadingPostTenders && <Loader />}
          </Button>
        </div>
      </Form>
    </>
  );
}
