import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

import logo from "../assets/images/logo.svg";
import { APP_ROUTE } from "../utils/constants";

export default function LayoutRegistration({ children }) {
  return (
    <>
      <div className="project py-4 px-2 p-lg-6 justify-content-start">
        <main className="page-content">
          <Container>
            <Row className="justify-content-center align-items-center">
              <Col lg={11}>
                <div className="md bg-light shadow-lg rounded-3 mx-auto pt-6 pb-3 px-2 p-lg-6 position-relative">
                  <Link
                    to={APP_ROUTE.HOME}
                    className="position-absolute m-3 top-0 start-0 logo logo-xs"
                  >
                    <img
                      src={logo}
                      alt="ZoomTender"
                      className="img-fluid d-block mx-auto"
                    />
                  </Link>

                  <Row className="justify-content-center">
                    <Col lg={11} xl={9}>
                      <div className="sm mx-auto shadow rounded-3 text-center pt-4 pb-3 px-4">
                        {children}
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </main>
      </div>
    </>
  );
}
