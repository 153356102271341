import IconItem from "../components/IconItem";

export const IconError = () => (
  <IconItem icon="error" className="text-danger" />
);

export const IconEye = () => (
  <IconItem icon="eye" />
);

export const IconSuccess = () => (
  <IconItem icon="success" className="text-success" />
);
