import { Form, Row, Col } from "react-bootstrap";

import { IconError, IconSuccess } from "../../../../utils/svgHelper";

export default function TenderTitle({
  touched,
  errors,
  values,
  handleBlur,
  handleChange,
  onFormUpdateHandler
}) {
  return (
    <Row className="justify-content-center align-items-center g-2 ">
      <Col sm={4} lg={5} className="text-start mb-2 mb-sm-0">
        <Form.Label className="fs-6 lh-base col-form-label py-0">
          Title:
        </Form.Label>
      </Col>
      <Col sm={8} lg={7}>
        <div className="position-relative">
          <div className="position-relative">
            <Form.Control
              type="text"
              name="title"
              tabIndex="1"
              className={`${touched.title ? "is-touch " : ""} ${
                errors.title && touched.title ? " is-invalid" : ""
              } ${!errors.title && touched.title ? " is-valid" : ""}`}
              value={values.title}
              onBlur={handleBlur}
              onChange={(e) => {
                handleChange(e);
                onFormUpdateHandler({
                  [e.target.name]: e.target.value
                });
              }}
              placeholder="Tender Title"
            />

            {errors.title && touched.title && <IconError />}

            {!errors.title && touched.title && <IconSuccess />}
          </div>
          {errors.title && touched.title && (
            <Form.Control.Feedback type="invalid">
              {errors.title}
            </Form.Control.Feedback>
          )}
        </div>
      </Col>
    </Row>
  );
}
