export const getPages = (totalPage, currentPage, setPageArray) => {
  let pageArr = [];
  if (totalPage > 1) {
    if (totalPage <= 9) {
      let i = 1;
      while (i <= totalPage) {
        pageArr.push(i);
        i++;
      }
    } else {
      if (currentPage <= 5) {
        pageArr = [1, 2, 3, 4, 5, 6, 7, 8, "", totalPage];
      } else if (totalPage - currentPage <= 4) {
        pageArr = [
          1,
          "",
          totalPage - 7,
          totalPage - 6,
          totalPage - 5,
          totalPage - 4,
          totalPage - 3,
          totalPage - 2,
          totalPage - 1,
          totalPage
        ];
      } else {
        pageArr = [
          1,
          "",
          currentPage - 3,
          currentPage - 2,
          currentPage - 1,
          currentPage,
          currentPage + 1,
          currentPage + 2,
          currentPage + 3,
          "",
          totalPage
        ];
      }
    }
  }
   return pageArr;
 };
